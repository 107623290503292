.br-trusted-companies-section {
  position: relative;
  padding: 100px 0;
  margin-bottom: 100px;
  z-index: 20;
}

.br-trusted-companies-section-content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
}

.br-trusted-companies-section-title {
  text-align: center;
}

.br-trusted-companies-section-logos {
  display: flex;
  flex-direction: row;
  align-content: space-around;
  flex-wrap: wrap;
  width: 70%;
  justify-content: center;

  & .partner-logo {
    max-width: 145px;
    svg {
      max-width: 100%;
      max-height: 30px;
    }
    margin: 20px 50px 20px 0;
  }
}

@media only screen and (max-width: 991px) {
  .br-trusted-companies-section {
    padding: 100px 30px;
    margin-bottom: 0;
  }

  .br-trusted-companies-section-logos {
    width: 100%;
    flex-direction: column;
  }
}
