@import 'styles/variables.less';

.br-wallet-mobile-container {
  text-align: center;
}

.br-wallet-mobile-title {
  font-size: 18px;
  font-weight: 900;
  color: @gray-600;
  text-align: center;
}

.br-wallet-mobile-feature-title {
  font-size: 16px;
  font-weight: 400;
  color: @gray-600;
  text-align: center;
}

.br-wallet-mobile-feature-image {
  & > img {
    max-width: 100%;
  }
}
