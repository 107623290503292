.br-mobile-features-section {
  padding: 60px 110px;
  margin-top: 50px;
  position: relative;
  z-index: 1;
}

.br-mobile-features {
  padding: 80px 0;
  padding-bottom: 180px;

  &-right {
    display: flex;
    flex-direction: row-reverse;
  }
}

.br-mobile-features-content {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.br-mobile-features-vector {
  position: absolute;
  right: 0;
}

.br-mobile-features-vector-right {
  position: absolute;
  left: 143px;
}

@media only screen and (max-width: 991px) {
  .br-mobile-features-section {
    padding: 60px 30px;
  }

  .br-mobile-features-content {
    width: 100%;
    text-align: center;
  }

  .br-mobile-features-vector,
  .br-mobile-features-vector-right {
    position: relative;
    left: 0;

    & > svg {
      max-width: 350px;
      max-height: 350px;
    }
  }

  .br-mobile-features,
  .br-mobile-features-right {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 40px;
    align-items: center;
  }
}
