.br-merged-integrations__content__trusted-companies-bg {
  position: relative;
  z-index: 1;
  margin-top: 85px;
  svg {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
  }
}

.br-merged-integrations__connect-and-save__content .br-grid .br-grid-item {
  & svg {
    fill: white;
  }
}
