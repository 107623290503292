@import 'styles/variables.less';

.br-dashboard-feature-content {
  display: flex;

  &.right {
    flex-direction: row-reverse;
  }
}

.br-dashboard-feature-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 100px;
  width: 100%;
}

.br-dashboard-feature-title {
  font-size: 18px;
  font-weight: 900;
  max-width: 372px;
  color: @gray-600;
}

.br-dashbaord-feature-subtitle {
  font-size: 18px;
  font-weight: 400;
  max-width: 372px;
  color: @gray-600;
}

.br-dashbaord-feature-image {
  width: 100%;
  max-height: 600px;
}

@media only screen and (max-width: 991px) {
  .br-dashboard-feature-container {
    display: flex;
    justify-content: center;
  }

  .br-dashboard-feature-description {
    align-items: center;
  }
}
