@import 'styles/variables.less';

.br-form-count__count-label {
  color: @text-gray-light;
  margin-left: auto;
}

.br-form-item-label__title {
  flex-direction: row;
}

.br-forms-new-input
  .br-form-with-sub-title.ant-form-item
  > .ant-form-item-label {
  height: unset;
}

.br-forms-new-input .br-form-with-sub-title.ant-row.ant-form-item {
  min-height: unset;
}

.br-forms-new-input {
  display: flex;
  flex-direction: column;
  // min-height: 72px;
}

.br-forms-new-input .ant-form-item-explain,
.br-forms-new-input .ant-form-item-extra {
  margin-bottom: 4px;
  display: block;
  margin-top: 4px;
}

.br-forms-new-input > div.ant-row.ant-form-item {
  flex: unset;
}

.br-forms-new-input
  .br-form-with-sub-title.ant-form-item
  > .ant-form-item-label {
  height: 25px;
}

.br-form-count__count-label-exceed span {
  color: @red-500;
}

.br-forms-new-input .br-form-item-label__subtitle {
  color: @text-gray;
}

.br-form-count__count-label-exceed span {
  color: @red-500;
}
