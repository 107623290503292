@import 'styles/variables.less';
@import 'styles/fonts-variables.less';

.br-solutions-testimonials-section {
  position: relative;
  z-index: 20;
}

.br-solutions-testimonials-carousel {
  width: 100%;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 80px;
    height: 70%;
    top: 15%;
    background: linear-gradient(
      270deg,
      @white 10.68%,
      rgba(255, 255, 255, 0) 48.05%
    );
    z-index: 1;
  }

  &::before {
    left: 0;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  &::after {
    right: 0;
  }
}

.br-solutions-testimonials-title {
  font-size: 28px;
  font-weight: 600;
  font-family: @font-regular-1;
  text-align: center;
}

.br-solutions-testimonials-section .slick-track {
  padding: 60px 0;
}
