@import 'styles/variables.less';

.br-pricing-calculator-modal.ant-modal {
  width: 100%;
  max-width: 904px;
  & .ant-modal-header {
    & .ant-modal-title {
      & > div {
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }
  }
  & .ant-modal-content {
    width: 100%;

    & .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 24px;
    }
  }
}

.br-pricing-calculator__choose-plan__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid @gray-200;
  width: 100%;
  max-width: 842px;
  margin-bottom: 32px;
}

.br-pricing-calculator__plan-content {
  margin-top: 16px;
  background: @gray-100;
  border-radius: 8px;
  padding: 4px;
  display: flex;
  gap: 4px;
  & div {
    width: 100%;
    min-width: 240px;
    text-align: center;
    padding: 4px 12px;
    cursor: pointer;
    transition: background 0.3s;
    align-self: center;
    & span{
      white-space: nowrap;
    }
  }
  & div.selected-plan-card {
    background: white;
    box-shadow: @shadow-xs;
    border-radius: 4px;
  }
}

.br-pricing-calculator__selected-plan-info {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  background: @teal-50;
  border-radius: 8px;
  padding: 12px 8px;
  align-items: center;
  gap: 4px;
  width: 100%;
  max-width: 492px;
  text-align: center;
}

.br-pricing-calculator__main-part__container {
  display: flex;
  gap: 32px;
  width: 100%;
  max-width: 842px;
  & > div:last-child {
    width: 100%;
  }
}

@media only screen and (max-width: @md){
.br-pricing-calculator__main-part__container {
    display: flex;
    gap: 32px;
    width: 100%;
    max-width: 842px;
    flex-direction: column-reverse;
    overflow: auto;
    padding: 20px;
    
    &>div:last-child {
      width: 100%;
    }
  }
  .br-pricing-calculator__main-part__container::-webkit-scrollbar {
    display: none;
  }
   .br-pricing-calculator__choose-plan__container{
    padding: 0px;
    padding-bottom: 20px;
   }

    .br-pricing-calculator-modal.ant-modal {
        & .ant-modal-content {
            & .ant-modal-body {
              height: 500px;
             overflow:hidden;
             justify-content: flex-start;
            }
          }
    }
}
@media only screen and (max-width: @xs){
  .br-pricing-calculator__plan-content {
     
  
      & div {

        min-width: unset;
      }
  
    }

    .br-pricing-calculator-modal.ant-modal {
        & .ant-modal-content {
          height: 90vh;
          overflow: auto;
            & .ant-modal-body {
             height: 600px;
             overflow: auto;
            }
          }
    }
}