.br-dashboard-hero {
  & .svg-main {
    right: 0;
    top: 0;
  }

  & .svg-1 {
    top: 75px;
  }

  & .svg-2 {
    bottom: -55px;
    right: 642px;
  }

  .r-hex-inner-2:before {
    background: url(../../../../assets/images/Dashbaord2.png);
    background-position: 30%;
    background-repeat: no-repeat;
  }
}

@media only screen and (max-width: 991px) {
  .br-dashboard-hero {
    & .svg-main {
      right: -65px;
      margin-top: 40px;
      & > img {
        max-width: 100%;
      }
    }

    & .svg-1 {
      top: unset;
      right: unset;
      bottom: 270px;
      left: 60px;
    }

    & .svg-2 {
      top: unset;
      right: unset;
      bottom: 0;
      left: 0;

      & > svg {
        max-width: 160px;
      }
    }

    .r-hex {
      margin-top: 96px;
    }
  }
}
