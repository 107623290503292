@import 'styles/variables.less';

.br-integrations__plugins-and-apis {
  margin-top: 162px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.br-integrations__plugins-and-apis__bg {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -90px;
  z-index: -1;
}

.br-integrations__plugins-and-apis__title {
  color: @text-gray-dark;
}

.br-integrations__plugins-and-apis__subtitle {
  color: @gray-600;
  margin: 20px auto;
  width: 500px;
}

.br-integrations__plugins-and-apis_cards {
  margin-top: 55px;
  display: flex;
  gap: 45px;
  justify-content: center;
}

@media only screen and (max-width: 991px) {
  .br-integrations__smart-apis {
    padding: 0 30px;
    flex-direction: column-reverse;
    text-align: center;
  }

  .br-integrations__smart-apis__image > img {
    max-width: 350px;
  }
  .br-integrations__plugins-and-apis_cards {
    flex-direction: column;
    align-items: center;
  }

  .br-integrations__plugins-and-apis {
    padding: 0 30px;
  }

  .br-integrations__plugins-and-apis {
    & .br-integrations__plugins-and-apis__subtitle {
      width: 100%;
      text-align: center;
    }
  }
}


