@import 'styles/variables';

.br-article__banner-image {
  width: 100%;
  height: 100%;
  margin-bottom: 40px;
}

.br-article-container {
  padding: 62px 10%;
  font-size: 15px;
  .br-header__title {
    text-align: center;
    margin-bottom: 40px;
  }
}

.br-article__section {
  h3 {
    .font({.grid-title()});
    font-size: 18px;
  }
}

.br-article-information-section {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  strong {
    .font({.display-xs()});
    margin-left: 5px;
  }
}

.br-article__section-title,
.br-article__section-subtitle__with-no-title {
  margin-bottom: 10px;
  margin-top: 30px;
}

.br-article__section-subtitle.grid-title {
  margin-bottom: 15px;
  font-size: 18px;
}

.br-article__section-subtitle li {
  width: fit-content;
  text-align: flex-start;
}

.br-article__section-image__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  max-width: 100%;
  margin: auto;
}

.br-article__section-image {
  width: 100%;
}
