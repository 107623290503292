@import 'styles/variables.less';
@import 'styles/fonts-variables.less';

.br-careers__header {
  position: relative;
  padding: 200px 0 220px 110px;
  background: @header-gradient;
  //   z-index: 2;
}

.br-careers__header-container {
  width: 478px;
}

.br-careers__values {
  margin-top: 83px;
}

.br-careers__values-diamond-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 300px;
  min-height: 239px;
  div {
    position: absolute;
  }
  .svg-1 {
    z-index: 2;
  }
  .svg-2 {
    left: 46px;
    top: 74px;
  }
  .svg-3 {
    left: 80px;
    top: 33px;
    z-index: 1;
  }
  .svg-4 {
    left: 130px;
    top: 12px;
    z-index: 1;
  }
  .svg-5 {
    left: 160px;
    top: 43px;
    z-index: 1;
  }
  .svg-6 {
    left: 213px;
    top: 101px;
    z-index: 1;
  }
  .svg-7 {
    left: 50px;
    top: 13px;
  }
  .svg-8 {
    left: 110px;
    top: 4px;
  }
}

html[dir='rtl'] .br-careers__values-diamond-img div {
  transform: scaleX(-1);
}
.br-careers__values-our-values {
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 121px;
  padding: 0 87px;
  svg {
    z-index: -1;
    position: absolute;
    top: 0;
    right: calc(50% - 515px);
  }
}

.br-careers__values-our-values__value {
  color: @gray-600;
  margin: auto 28px;
}

.br-careers__values-our-values__circle {
  background-color: @gray-600;
  border-radius: 50%;
  height: 8px;
  width: 8px;
}

.br-careers__perks {
  position: relative;
  padding: 83px 212px 100px;
  z-index: 1;
}

.br-careers__perks-hexagon {
  position: absolute;
  right: calc(50% - 177px);
  z-index: -1;
}

.br-careers__perks-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.br-careers__perks-title {
  margin-top: 98px;
  color: @text-gray-dark;
}

.br-careers__perks-cards {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  height: 180px;
}

.br-careers__perks-perk {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 305px;
  color: @gray-600;
}

.br-careers__perks-perk-title {
  font-family: @font-bold-2;
  margin-top: 12px;
  font-size: 20px;
  line-height: 30px;
}

.br-careers__perks-perk-subtitle {
  margin-top: 4px;
}

.ant-pagination.br-careers__pagination {
  & li.ant-pagination-item {
    min-width: 6px;
    height: 6px;
    border-radius: 4px;
    background-color: #d4d8df;
  }

  & li.ant-pagination-item-active.ant-pagination-item {
    background-color: @red-600;
    border-color: @red-600;
  }

  & li.ant-pagination-prev,
  li.ant-pagination-next {
    & button {
      border-radius: 4px;
    }

    & span {
      color: @gray-500;
    }
  }
  & li.ant-pagination-prev.ant-pagination-disabled,
  li.ant-pagination-next.ant-pagination-disabled {
    & span {
      color: rgba(@gray-500, 0.25);
    }
  }
}

.br-careers__pagination-container {
  margin-top: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.br-careers__jobs-section {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0 112px;
  position: relative;
  // height: 750px;
  z-index: 1;
}

.br-careers__jobs-section-img {
  position: absolute;
  top: 0;
  right: 112px;
  z-index: -1;
}

.br-careers__jobs-section-bottom {
  padding-top: 90px;
  display: flex;
  justify-content: space-between;
}

.br-careers__jobs-section-bottom__search {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.br-careers__jobs-section-bottom__search-subtitle {
  color: @gray-600;
  margin: 14px 0 17px;
}

.br-careers__jobs-section-bottom__search-img {
  margin: 65px auto 0;
}

.br-careers__jobs-section-bottom__apply {
  margin-right: 103px;
  display: flex;
  flex-direction: column;
}

.br-careers__jobs-section-bottom__apply-attach-input-button {
  display: flex;
  margin: 20px 0;
}

.ant-input.br-careers__jobs-section-bottom__apply-attach-input {
  padding: 20px;
  width: calc(100% - 137px);
  border: 1px solid #e2e8f0;
  border-radius: 10px 0px 0px 10px;
  border-right: 0;
}

.ant-input.br-careers__jobs-section-bottom__apply-attach-input:hover,
.ant-input.br-careers__jobs-section-bottom__apply-attach-input:focus {
  border: 1px solid #e2e8f0;
  border-right: 0;
}

.ant-btn.br-careers__jobs-section-bottom__apply-attach-button {
  background-color: @gray-200;
  border: 1px solid #e2e8f0;
  border-radius: 0px 10px 10px 0px;
  height: 100%;
  width: 137px;
  color: @gray-500;
  font-family: @font-bold-1;
}

.ant-btn.br-careers__jobs-section-bottom__apply-attach-button:hover {
  background-color: @gray-200;
  border: 1px solid #e2e8f0;
  color: @gray-500;
}

.ant-input.br-careers__jobs-section-bottom__apply-message {
  height: 200px;
  width: 100%;
  padding: 20px;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  margin-bottom: 20px;
  resize: none;
}

.ant-input.br-careers__jobs-section-bottom__apply-message:hover,
.ant-input.br-careers__jobs-section-bottom__apply-message:focus {
  border: 1px solid #e2e8f0;
}

@media only screen and (max-width: 991px) {
  .br-careers__header {
    padding: 0 30px;
  }

  .br__hero__content-svg {
    &.svg-1 {
      right: unset;
      top: unset;
      left: 0;
      bottom: 150px;

      & > svg {
        max-width: 160px;
      }
    }

    &.svg-2 {
      left: 0;
      bottom: -50px;
    }
  }

  .br-careers__values-our-values {
    flex-direction: column;
    padding: 100px;

    & > svg {
      display: none;
    }

    & .br-careers__values-value {
      flex-direction: column;
    }
  }

  .br-careers__perks-section {
    margin: 100px 0;
  }

  .br-careers__perks {
    padding: 0 60px;
    text-align: center;
  }

  .br-careers__perks-perk {
    width: 100%;
  }

  .br-careers__jobs-section {
    padding: 0 30px;
    text-align: center;
    height: 100%;
    margin-bottom: 60px;
  }

  .br-careers__jobs-section-bottom {
    flex-direction: column;
    padding-top: 0;
    & > .ant-btn {
      width: 100%;
    }
  }

  .br-careers__jobs-section-bottom__apply {
    margin: 0;

    & > .ant-btn {
      width: 100%;
    }
  }

  .br-careers__jobs-section-img {
    right: 0;
  }

  .br-careers__jobs-section-bottom__apply-attach-button {
    padding: 20px;
  }
}

.br-careers {
  .br__hero__content-svg.svg-1 {
    right: 507px;
  }
  .r-hex-inner-2:before {
    background: url(../../assets/images/Careers.png);
    background-size: 56em 764px;
    background-repeat: no-repeat;
  }
  .br__hero__content-svg.svg-2 {
    right: 61%;
  }
}
