@keyframes slide-from-bottom {
  from {
    transform: translateY(var(--from, 30%));
    opacity: var(--opacityFrom, 0.25);
  }
  to {
    transform: translateY(var(--to, 0));
    opacity: var(--opacityTo, 1);
  }
}
.slide-from-bottom {
  animation: slide-from-bottom var(--animationDelay, 1.5s) ease
    var(--startDelay, 0s);
}

@keyframes slide-from-side {
  from {
    transform: translateX(var(--from, 50%));
    opacity: var(--opacityFrom, 0.25);
  }
  to {
    transform: translateX(var(--to, 0));
    opacity: var(--opacityTo, 1);
  }
}
.slide-from-side {
  animation: slide-from-side var(--animationDelay, 1.5s) ease
    var(--startDelay, 0s);
}

@keyframes r-hex-slide-from-bottom {
  from {
    top: var(--from, 50%);
    opacity: 0.25;
  }
  to {
    top: var(--to, 0);
    opacity: 1;
  }
}

.r-hex-slide-from-bottom {
  animation: r-hex-slide-from-bottom 1.5s ease;
}

@keyframes slide-diagonally {
  from {
    transform: translate(var(--fromX, 50%), var(--fromY, 50%));
    opacity: var(--opacityFrom, 0.25);
  }
  to {
    transform: translate(var(--to, 0), var(--to, 0));
    opacity: var(--opacityTo, 1);
  }
}
.slide-diagonally {
  animation: slide-diagonally var(--animationDelay, 1.5s) ease
    var(--startDelay, 0s);
}
