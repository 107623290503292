@import 'styles/variables';

.br-tracking-details-star-info__container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 8px;
  background-color: @bg-teal-light;
  border-radius: 4px;
  margin-top: 8px;
  > div {
    width: 100%;
  }
}

.br-tracking-details-star-info__title {
  color: @text-gray;
  text-transform: uppercase;
}

.br-tracking-details-star-info__content {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 174px;

  .br-tracking-details__profile-icon {
    svg path {
      fill: transparent;
      stroke: @icon-teal;
    }
  }
}

.br-tracking-details__star-phone {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 22px;
  color: @text-teal;
}

.br-tracking-details-star-phone__container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 22px;
}

.br-tracking-details__star-phone-placeholder {
  color: @text-teal;
}

.br-tracking-details__star-info-subtitle {
  color: @text-gray;
  margin: 8px 0;
}

@media only screen and(max-width: @sm) {
  .br-tracking-details-star-info__content {
    min-width: 100%;
  }

  .br-tracking-details-star-info__container {
    justify-content: space-between;
  }
}

.br-tracking__POS-request-btn.ant-btn {
  border: none;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  & .br-button-component__label {
    > div {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      & p {
        margin: 0;
        color: @text-teal;
      }
    }
  }
}
