@import 'styles/variables.less';

.br-about-hero .br__hero__content-svg {
  &.svg-main {
    img {
      width: 769px;
      height: 806px; /* width * 0.866 */
    }
  }

  &.svg-1 {
    top: 85px;
  }

  &.svg-2 {
    bottom: 80px;
    right: 579px;
  }
}

.br-about-hero .r-hex-inner-2:before {
  background: url(../../../../assets/images/About.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media only screen and (max-width: 991px) {
  .br-about-hero .br__hero__content-svg {
    &.svg-1 {
      top: 490px;
      left: 40px;
    }

    &.svg-2 {
      bottom: 80px;
      right: unset;
      left: 0px;
    }
  }
}
