@import 'styles/variables.less';

.br-solutions-innovative-bots-container {
  padding: 60px 110px;
  position: relative;
  z-index: 1;
}

.br-solutions-innovative-bots-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.br-solutions-innovative-bots-svg {
  position: absolute;
  top: -50px;
  z-index: -1;
}

.br-solutions-innovative-bots-title {
  font-size: 28px;
  font-weight: 600;
}

.br-solutions-innovative-bots-subtitle {
  text-align: center;
  max-width: 500px;
  font-size: 20px;
  color: @gray-600;
}

.br-solution-innovative-bots-features {
  display: flex;
  gap: 50px;
}

.br-solution-bot-feature {
  max-width: 305px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.br-solution-bot-feature-title {
  font-size: 20px;
  font-weight: 900;
  color: @gray-600;
}

.br-solution-bot-feature-subtitle {
  text-align: center;
  font-size: 20px;
  color: @gray-600;
}

@media only screen and (max-width: 991px) {
  .br-solutions-innovative-bots-container {
    padding: 0 30px;
    text-align: center;
  }

  .br-solution-innovative-bots-features {
    flex-direction: column;
  }
}
