@import 'styles/fonts-variables.less';

.br__sign-up-and-start__section {
  padding: 110px;
  position: relative;
  z-index: 20;

  &__container {
    display: flex;
    justify-content: center;
    position: relative;
  }
}

.br__sign-up-and-start__section__container__content-title {
  font-size: 28px;
  font-weight: 600;
  font-family: @font-regular-1;
  margin-top: 30px;
}

.br__sign-up-and-start__section__container__content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.br__sign-up-and-start__section__container__content-cta {
  margin-top: 30px;
}

.br__sign-up-and-start__section__container__content-svg {
  position: relative;
  width: 100%;

  .svg-2 {
    position: absolute;
    top: 93px;
    right: 18px;
    z-index: -1;
    overflow: hidden;
  }

  .svg-3 {
    position: absolute;
    top: 83px;
    right: 508px;
    z-index: -1;
    overflow: hidden;
  }

  &-line,
  &-vehicles {
    position: absolute;
    bottom: 0;
  }

  &-warehouse {
    position: absolute;
    bottom: 0;
    left: 60px;
  }
}

html[dir='rtl'] .br__sign-up-and-start__section__container__content-svg {
  transform: scaleX(-1);
  .svg-2 {
    right: 476px;
  }
  .svg-3 {
    right: 18px;
  }
}

.br__sign-up-and-start__section__container__content-cta > .button-primary {
  width: 250px;
}

@media only screen and (max-width: 991px) {
  .br__sign-up-and-start__section {
    padding: 0 30px;
    margin-bottom: 100px;
  }
  .br__sign-up-and-start__section__container__content-title {
    font-size: 24px;
    text-align: center;
  }

  .br__sign-up-and-start__section__container__content-svg {
    .svg-1 svg {
      width: 400px;
    }
    .svg-2 {
      top: 70px;
      svg {
        width: 150px;
      }
    }
    .svg-3 {
      top: 60px;
      right: 233px;
      svg {
        width: 150px;
      }
    }
  }
  html[dir='rtl'] .br__sign-up-and-start__section__container__content-svg {
    .svg-2 {
      right: 239px;
    }
  }
}
