@import 'styles/variables';

.br-verify-phone-modal {
  .ant-modal-content .ant-modal-body {
    padding: 0;
  }
}

.br-otp-validation__content {
  padding: 0 20px;
}

.br-verify-phone-modal .br-verify-phone-modal__header {
  padding: 16px 24px;
  box-shadow: @border-bottom;
}

.verify-phone__opt-container .br-otp-modal__form-items {
  margin: auto;
  display: flex;
  justify-content: center;

  .ant-row.ant-form-item {
    max-width: 52px;
    margin-right: 8px;
  }

  .ant-row.ant-form-item:last-of-type {
    margin-right: unset;
  }
}

.verify-phone__opt-container
  .br-otp-modal__form-items
  .ant-col.ant-form-item-control {
  width: fit-content;
  justify-content: center;
  input {
    width: 52px;
    height: 52px;
    text-align: center;
    .font({.display-md()});
    &::placeholder {
      .font({.heading()});
    }
    &:focus::placeholder {
      color: transparent;
    }
  }
}

.verify-phone__opt-container
  .br-otp-modal__form-items
  .ant-form-item-control-input-content {
  display: flex;
  justify-content: center;
}

.verify-phone-modal__wrong-number {
  display: flex;
  justify-content: center;
  span {
    color: @text-gray;
    display: inline-flex;
    align-items: center;
  }
  button {
    margin-left: 4px;
  }
}

.br-verify-phone-modal .ant-modal-footer {
  margin-top: 20px;
}
