@import 'styles/variables.less';

.br-use-cases__carousel-content {
  margin-top: 128px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.br-use-cases__carousel-text-content {
  width: 100%;
  max-width: 475px;
  margin-left: 35px;
  height: 446px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & h1 {
    color: @gray-900;
    margin-bottom: 5px;
  }
  & p {
    color: @gray-600;
    margin-bottom: 5px;
  }
  & span {
    color: @teal-500;
    cursor: pointer;
  }
}

.ant-pagination.br-use-cases__pagination {
  margin-bottom: 60px;
  & li.ant-pagination-item {
    min-width: 6px;
    height: 6px;
    border-radius: 4px;
    background-color: #d4d8df;
  }

  & li.ant-pagination-item-active.ant-pagination-item {
    background-color: @red-600;
    border-color: @red-600;
  }

  & li.ant-pagination-prev,
  li.ant-pagination-next {
    & button {
      border-radius: 4px;
    }

    & span {
      color: @gray-500;
    }
  }
  & li.ant-pagination-prev.ant-pagination-disabled,
  li.ant-pagination-next.ant-pagination-disabled {
    & span {
      color: rgba(@gray-500, 0.25);
    }
  }
}

html[dir='rtl'] .ant-pagination-prev,
html[dir='rtl'] .ant-pagination-next,
html[dir='rtl'] .br-use-cases__carousel-img {
  transform: scaleX(-1);
}

.br-use-cases__carousel-text-container {
  margin-top: 90px;

  .button-lg {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.br-use-cases__trusted-by-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  margin-bottom: 110px;
  & h1 {
    color: @gray-900;
    margin-bottom: 50px;
  }
}

.br-use-cases__trusted-by-companies-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 50px;
  justify-content: center;
  & svg {
    margin-right: 60px;
    margin-bottom: 50px;
  }
}

.br-use-cases__carousel-img {
  & svg {
    position: relative;
    animation: slide 1.5s ease;
  }
}

@keyframes slide {
  from {
    right: 100px;
    opacity: 0;
  }

  to {
    opacity: 1;
    right: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .br-use-cases__carousel-content {
    flex-direction: column;
    padding: 0 30px;
  }

  .br-use-cases__carousel-img {
    & > svg {
      max-width: 300px;
    }
  }

  .br-use-cases__carousel-text-content {
    max-width: 100%;
    margin: 0;
    text-align: center;
  }

  .br-use-cases__trusted-by-container {
    margin: 0;
  }

  .br-use-cases__trusted-by-companies-container {
    padding: 0;
  }

  .br-use-cases__trusted-by-companies-container svg,
  .br-use-cases__trusted-by-companies-container svg {
    margin: 0;
  }
}
