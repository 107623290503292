@import 'styles/variables.less';

.ant-pagination.br-mobile-partners__pagination {
  margin-bottom: 60px;
  margin-top: 20px;
  text-align: center;

  & li.ant-pagination-item {
    min-width: 6px;
    height: 6px;
    border-radius: 4px;
    background-color: #d4d8df;
  }

  & li.ant-pagination-item-active.ant-pagination-item {
    background-color: @red-600;
    border-color: @red-600;
  }

  & li.ant-pagination-prev,
  li.ant-pagination-next {
    & button {
      border-radius: 4px;
    }

    & span {
      color: @gray-500;
    }
  }
  & li.ant-pagination-prev.ant-pagination-disabled,
  li.ant-pagination-next.ant-pagination-disabled {
    & span {
      color: rgba(@gray-500, 0.25);
    }
  }
}

.br-partners-mobile-carousel-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.br-partners-mobile-carousel-item {
  flex: 0 1 calc(50% - 10px);
  text-align: center;

  & > svg {
    max-width: 115px;
  }
}
