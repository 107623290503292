@import 'styles/variables';

.br-shipment-tracking-details__timeline {
  .ant-steps {
    justify-content: center;
  }

  .ant-steps-label-vertical .ant-steps-item {
    max-width: 170px;
  }

  .ant-steps-dot .ant-steps-item-icon {
    margin-left: 62px;
  }

  .br-tracking-timeline-dot__container {
    margin-top: -4px;
    &.br-tracking-timeline-dot__finished {
      margin-top: -6px;
    }
  }

  .ant-steps-item-container > .ant-steps-item-content {
    margin-top: 24px;
    min-height: 36px;

    > div.ant-steps-item-title {
      color: @text-gray;
      .font({.body()});
    }
  }

  .ant-steps-dot .ant-steps-item-tail {
    z-index: 1;
  }

  .ant-steps-dot .ant-steps-item-tail::after {
    display: none;
    margin-left: 10px;
  }

  .ant-steps-item-tail::before {
    content: '';
    height: 2px;
    position: absolute;
    background: url('../../../../assets/icons/DashedLine.svg');
    z-index: 1;
    display: inline-block;
    width: calc(100% - 20px);
    margin-left: 10px;
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::after {
    display: inline-block;
    width: calc(100% - 95px);
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-tail::before {
    z-index: -1;
  }

  .ant-steps-horizontal .ant-steps-item-finish:has(+ .ant-steps-item-finish) {
    .ant-steps-item-container > .ant-steps-item-tail::after {
      width: calc(100% - 15px);
    }
  }

  .ant-steps-item-finish .ant-steps-item-content {
    div.ant-steps-item-title {
      color: @text-gray-dark;
      .font({.body-medium()});
    }
  }

  .ant-steps-item-subtitle {
    color: @text-gray-dark;
    .font({.body()});
  }
}

@media only screen and(max-width: @xs) {
  .br-shipment-tracking-details__timeline {
    .ant-steps-vertical
      > .ant-steps-item
      > .ant-steps-item-container
      > .ant-steps-item-tail::after {
      width: 2px;
      height: 70%;
      margin: 15px 0 0 17px;
    }

    .ant-steps-item-finish:has(+ .ant-steps-item-finish) {
      .ant-steps-item-container > .ant-steps-item-tail::after {
        height: 125%;
      }
    }

    .ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
      margin-top: 8px;
    }

    .ant-steps-item-tail::before {
      width: 2px;
      height: calc(100% - 20px);
      background: url('../../../../assets/icons/DashedLineVertical.svg');
      margin: 18px 0 0 17px;
    }

    .ant-steps-item-title {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }

    .ant-steps-item-subtitle {
      margin: 0;
    }
  }
}
