@import './variables.less';

@primary-color: @teal-500;

.ant-btn:not(.ant-btn-circle) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  border-radius: 4px;
  line-height: 14px;
  text-shadow: none;
}

button[ant-click-animating-without-extra-node]:after {
  border: 0 none;
  opacity: 0;
  animation: none 0 ease 0 1 normal;
}

.ant-btn-primary {
  background-color: @red-600;
  color: @white;
  border-radius: 10px;
  height: 60px;
  width: 212px;
  border: none;
  width: fit-content;
}

.ant-btn-primary:hover,
.ant-btn-primary:active,
.ant-btn-primary:focus {
  background-color: @red-600;
  color: @white;
}

html[dir='rtl'] .ant-dropdown {
  left: unset;
}

html[dir='rtl'] .ant-select-dropdown {
  left: unset !important;
  transform: translateX(0) !important;
}

.ant-dropdown-menu {
  border-radius: 5px;
  // width: 200px;
  padding: 8px;
}

// Spin
.ant-spin {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ant-spin-nested-loading {
  min-width: 0;
}

.ant-spin-nested-loading,
.ant-spin-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: unset;
}

// Modal
.ant-modal-header {
  padding: 16px 20px;
  display: flex;
  align-items: center;
  box-shadow: @border-bottom;
  border-bottom: 0;
  border-radius: 8px 8px 0px 0px;
}

.ant-modal-content {
  border-radius: 8px;
}

.ant-modal-body {
  padding: 16px 20px;
}

.ant-modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 16px;
  background: @bg-gray-light;
  border-radius: 0 0 8px 8px;
  gap: 4px;
}

.ant-modal-close {
  display: none;
}

// Form
.ant-row.ant-form-item-row {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ant-col.ant-form-item-control {
  width: 100%;
  min-height: 36px;
}

.ant-input-group.ant-input-group-compact {
  display: flex;
}

.ant-input-affix-wrapper,
.ant-select-selector,
.ant-form-item-control-input-content,
.ant-form-item-control-input {
  min-height: 36px;
}

.ant-form-item {
  width: 100%;
  margin-bottom: 0;
}

.ant-form-item {
  display: flex;
  flex-direction: column;
  min-height: 80px;
  margin-bottom: 0;
}

.ant-form-item-label,
label.ant-form-item-required,
.br-form-item-label {
  width: 100%;
}

.ant-form-item-label {
  font-weight: 600;
  height: 20px;
  //for : in form group
  > label::after {
    display: none;
  }
}

.ant-form-item-label > label {
  height: auto;
  .font({.body-medium()});
}

.ant-form-item-margin-offset {
  display: none;
}

.ant-form-item-row > .ant-form-item-label {
  height: 20px;
  margin-bottom: 4px;
  text-align: start;
}

.ant-input-affix-wrapper,
.ant-form-item-control-input
  .ant-input-group:not(.ant-input-group-compact)
  .ant-input-affix-wrapper {
  border-radius: 4px;
}

.ant-input-affix-wrapper {
  border-radius: 4px;
  border: 1px solid @gray-300;
  .font({.body()});
  .ant-input-prefix {
    color: @text-gray-dark;
    .font({.body()});
  }
}

.ant-form-item-explain,
 .ant-form-item-extra  {
  margin-top: 2px;
  min-height: unset;
}

.ant-form-item-explain-error {
  color: @red-500;
  margin-bottom: 8px;
  .font({.body()});
}

.ant-form-item-label
  > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-form-item-explain-error::first-letter {
  text-transform: capitalize;
}

.ant-form-item-has-error.ant-form-item-has-feedback .anticon-close-circle,
.ant-form-item-has-success.ant-form-item-has-feedback .anticon-check-circle {
  svg {
    color: transparent;
  }
}

// Input Password Select DropdownButton

.ant-input,
.ant-input-password,
.ant-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-picker,
.ant-btn.ant-dropdown-trigger:not(.ant-btn-primary) {
  border: 1px solid @gray-300;
  border-radius: 4px;
}

.ant-select:not(.ant-pagination-options-size-changer) .ant-select-arrow {
  background: transparent url('../assets/icons/Select-Arrow.svg') no-repeat 50%
    50%;
  color: @gray-500;
  height: 16px;
  width: 12px;
  margin-top: -7px;
  & svg {
    visibility: hidden;
  }
}

.ant-select.ant-select-disabled .ant-select-arrow {
  background: transparent url('../assets/icons/DisabledSelectArrow.svg')
    no-repeat 50% 50%;
}

.ant-pagination-options-size-changer .ant-select-arrow {
  background: transparent url('../assets/icons/Dropdown.svg') no-repeat 50% 50%;
  color: @gray-500;
  height: 14px;
  & svg {
    visibility: hidden;
  }
}

.ant-select-disabled .ant-select-arrow {
  svg path {
    fill: @icon-disabled;
  }
}

.ant-select-selection-item {
  display: flex;
  align-items: center;
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

// Radio
.ant-radio-wrapper .ant-radio:hover .ant-radio-inner,
.ant-radio-wrapper .ant-radio-input:focus + .ant-radio-inner {
  border-color: @teal-500;
}

.ant-radio .ant-radio-checked .ant-radio-inner {
  border-color: @teal-500;
}

.ant-radio .ant-radio-checked::after {
  border: 1px solid @teal-500;
}

.ant-radio.ant-radio-checked .ant-radio-inner {
  border: 5px solid @deep-teal;
}

.ant-radio .ant-radio-inner::after {
  background-color: @white;
  transform: scale(0.4);
}

.ant-radio.ant-radio-disabled:hover,
.ant-radio.ant-radio-disabled {
  .ant-radio-inner {
    border: 1px solid @gray-200;
  }
}

.ant-radio-button-wrapper:hover {
  box-shadow: @shadow-xs-green;
  z-index: 1;
}

.ant-radio-button-disabled {
  background-color: @bg-disabled;
  border: 1px solid @gray-200;
  span {
    color: @text-gray-light;
  }
}

// tooltip

.ant-tooltip {
  width: fit-content;
}

.ant-tooltip-inner {
  background: @white;
  color: initial;
  text-align: start;
  border-radius: 4px;
  padding: 8px;
  white-space: normal;
  width: fit-content;

  .font({.caption()});
}

.ant-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content {
  background: @white;
}

.ant-tooltip .ant-tooltip-arrow .ant-tooltip-arrow-content::before {
  display: none;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: @white;
}

//for cursor not be in top but in the same line with placeholder
.ant-select-selector {
  display: flex;
  align-items: center;
}

.ant-input::placeholder,
.ant-select-selection-placeholder,
.ant-picker-input input::placeholder {
  color: @text-gray-light;
  opacity: 1 !important;
  .font({.body()});
}
