@import 'styles/variables';

.br-tracking-timeline-dot__container {
  width: 16px;
  height: 16px;
  border: 1px solid @gray-300;
  border-radius: 50%;

  .ant-steps-icon-dot {
    background: transparent !important;
  }

  &.br-tracking-timeline-dot__finished {
    border: none;
  }
}

.br-tracking-timeline-dot__finished {
  svg path {
    fill: @icon-teal;
  }
}
