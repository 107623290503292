@import 'styles/variables.less';
@import 'styles/fonts-variables.less';

.br-pricing-table-mobile-data {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  border-bottom: 1px dashed @gray-500;
}

.br-pricing-table-mobile-title {
  width: 100%;
  margin-top: 20px;
  font-family: @font-bold-1;
  font-size: 18px;
  color: @gray-600;
}

.br-pricing-table-mobile-city {
  font-family: @font-bold-1;
  font-size: 16px;
  color: @gray-600;
}

.br-pricing-table-mobile-price {
  color: @gray-600;
  font-size: 16px;
}

.br-pricing-table-mobile-prices-list {
  width: 25%;
  padding-bottom: 10px;
}

.br-pricing-table-mobile-data {
  margin-bottom: 20px;
}

.br-pricing-table-mobile-data:last-child {
  border: 1px solid @teal-500;
  border-top: none;
  border-radius: 0 0 10px 10px;
}

.br-pricing-table-mobile-data:nth-last-child(2) {
  border: 1px solid @teal-500;
  border-bottom: none;
  border-radius: 10px 10px 0 0;
}

.br-pricing-table-mobile-data:nth-last-child(-n + 2) {
  margin-bottom: 0;
}
