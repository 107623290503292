@import 'styles/variables.less';

.br-form-confirmation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
}

.br-form-confirmation-title {
  color: @teal-400;
  height: 60px;
  margin-top: 8px;
  font-size: 30px;
  .font(.ads-heading());
}

.br-form-confirmation-subtitle {
  text-align: center;
  .font(.title-sm());
}

@media only screen and (max-width: @md) {
  .br-form-confirmation-container {
    width: 90%;
    > svg {
      width: 60px;
      height: 60px;
    }
  }

  .br-form-confirmation-title {
    height: 20px;
    .font(.caption-lg());
  }

  .br-form-confirmation-subtitle {
    .font(.contact-subtitle ());
  }
}
