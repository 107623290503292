@import 'styles/variables.less';
@import 'styles/fonts-variables.less';

.br-solutions-bosta-box-container {
  padding: 100px 110px;
  position: relative;
  z-index: 1;
}

.br-solutions-bosta-box-content {
  display: flex;
}

.br-solutions-bosta-box-subtitle {
  font-size: 20px;
  font-weight: 400;
  font-family: @font-regular-1;
  margin-top: 20px;
  margin-bottom: 0;
  color: @gray-600;
}

.br-solutions-bosta-box-content-grid {
  display: flex;
  width: 45%;
  flex-direction: column;

  & .br-grid {
    flex-wrap: nowrap;
    flex-direction: column;
  }
}

.br-solutions-bosta-box-svg {
  position: absolute;
  right: 130px;
  top: 100px;
}

@media only screen and (max-width: 991px) {
  .br-solutions-bosta-box-container {
    padding: 0 30px;
    text-align: center;
  }

  .br-solutions-bosta-box-content {
    flex-direction: column;
    align-items: center;
  }

  .br-solutions-bosta-box-content-grid {
    width: 100%;
  }

  .br-solutions-bosta-box-cta > .button-primary {
    width: 100%;
  }

  .br-solutions-bosta-box-svg {
    position: relative;
    right: unset;
    top: unset;

    & > svg {
      max-width: 300px;
    }
  }
}
