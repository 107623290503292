@import 'styles/variables.less';
@import 'styles/fonts-variables.less';

.br__carousel__card {
  padding: 34px;
  margin: 0 25px;
  background: @white;
  border: 1px solid @gray-200;
  border-radius: 20px;
  box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.1);
  width: 342px;
  height: 400px;
  overflow: hidden;
  cursor: pointer;
}

.br__carousel__card__content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.br__carousel__card-title {
  margin-bottom: 17px;
  display: flex;
  img {
    height: 62px;
  }
}

.br__carousel__card-image {
  margin-bottom: 17px;
  height: 129px;
  display: flex;
  img {
    height: 100%;
    width: 270px;
  }
}

.br__carousel__card-description {
  font-family: @font-regular-2;
  font-weight: 700;
  font-size: 20px;
  color: @gray-600;
  max-height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 262px;
}

.br__carousel__card-date {
  font-family: @font-regular-2;
  font-weight: 400;
  font-size: 16px;
  color: @gray-400;
}

@media only screen and (max-width: 991px) {
  .br__carousel__card {
    max-width: 236px;
  }

  & .br__carousel__card-image {
    width: 100%;

    & > img {
      width: 100%;
    }
  }

  .br__carousel__card-description {
    width: 100%;
  }
}
