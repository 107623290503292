.br-faq__header {
  padding-top: 90px;
  padding-bottom: 130px;

  .br__hero__content-svg {
    top: 0;
    right: 0;
  }
  .svg-1 {
    top: 60px;
    right: 375px;
  }
  .br-hero-header {
    max-width: unset;
    width: 716px;
  }
  .r-hex {
    width: 40em;
    height: 34em;
  }

  .r-hex-inner-2:before {
    background: url(../../assets/images/FAQ.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 60%;
    width: 40em;
    height: 40em;
  }
}

.br-faq__header__search-input.ant-input-affix-wrapper {
  height: 60px;
  border-radius: 10px;
  padding-right: 20px;
  padding-left: 20px;
}

.br-faq__content {
  padding: 0 110px;
}

.br-faq__content__trusted-companies-bg {
  position: relative;
  z-index: 1;
  svg {
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
  }
}

@media only screen and (max-width: 991px) {
  .br-faq__header {
    padding-bottom: 0;

    & .br__hero__content-svg.svg-main {
      right: -65px;
      & > img {
        max-width: 100%;
      }
    }

    & .svg-1 {
      bottom: 200px;
      left: 0;
      right: unset;
      top: unset;
    }
  }

  .br-faq__header .br-hero-header {
    width: 100%;
  }

  .br-faq__content {
    padding: 0 30px;
  }
}
