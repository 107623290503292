@import 'styles/variables.less';

.br-pricing-flags-section__container {
  background: white;
  box-shadow: @shadow-sm;
  border-radius: 8px;
  width: 100%;
  max-width: 301px;
  display: flex;
  flex-direction: column;
}

.br-pricing-flags-section__header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  text-align: center;
  & > span:first-child {
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: @gray-500;
    margin-bottom: 10px;
  }
  & > span.caption {
    color: @gray-500;
    margin-top: 10px;
  }
  & > span.button {
    color: @teal-500;
    margin-top: 12px;
    cursor: pointer;
  }
}

.br-pricing-flags-flags-container {
  background: @gray-50;
  padding: 16px;
  border-bottom: 1px solid @gray-200;
  margin-bottom: 16px;
  & .br-pricing-flags-flags-list {
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 12px;
      border-bottom: 1px dashed @gray-300;
      & > div {
        display: flex;
        align-items: center;
        gap: 6px;
        & svg path {
          fill: @gray-400;
        }
      }
    }
    & > div:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
    & > div:not(:first-child) {
      padding-top: 12px;
    }
  }
}

.br-pricing-flags-flags-container-drawer {
  padding: 26px 0;
  & .br-pricing-flags-flags-list {
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 12px;
      & > div {
        display: flex;
        align-items: center;
        gap: 6px;
        & svg path {
          fill: @gray-400;
        }
      }
    }
    & > div:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
    & > div:not(:first-child) {
      padding-top: 12px;
    }
  }
}

.br-pricing-flags-section__info-text-container {
  background: @gray-50;
  padding: 16px;
  padding-top: 0;
  text-align: center;
  & span {
    color: @gray-500;
  }
}

.ant-drawer.ant-drawer-bottom.ant-drawer-open.br-pricing-flags-section__drawer {
  z-index: 99999999999999;
}

.br-pricing-flags-section__info-text-container-modal {
  background: @gray-50;
  border-radius: 4px;
  padding: 8px;
  margin-top: 16px;

  & span {
    color: @gray-500;
  }
}

.br-pricing-flags-section__header-container-drawer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0;
  text-align: center;
  border-radius: 8px;
  box-shadow: @shadow-sm;
  & > span:first-child {
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: @gray-500;
    margin-bottom: 10px;
  }

  & > span.caption {
    color: @gray-500;
    margin-top: 10px;
  }

  & > span.button {
    color: @teal-500;
    margin-top: 12px;
    cursor: pointer;
  }
}

@media only screen and (max-width: @md) {
  .br-pricing-flags-section__container {
    max-width: 100%;
  }
  .br-pricing-flags-flags-container {
    display: none;
  }

  .br-pricing-flags-section__info-text-container {
    display: none;
  }
}
