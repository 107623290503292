@import 'styles/variables.less';

.br-pricing-calculator-banner__wrapper {
  padding: 0 20px;
}

.br-pricing-calculator-banner__wrapper.br-pricing-calculator-banner__pricing-plan-page {
  padding: 0;
  & .br-pricing-calculator-banner__container {
    background: @gray-50;
  }
}

.br-pricing-calculator-banner__container {
  background: white;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  & button.ant-btn {
    width: fit-content;
    display: flex;
    gap: 4px;
    align-items: center;
  }
}

html[dir='rtl'] .br-pricing-calculator-banner__container {
  & button.ant-btn {
    & svg {
      transform: scale(-1);
    }
  }
}

.br-pricing-calculator-banner__text-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
  & > span:first-child {
    color: @red-600;
    letter-spacing: 0.04em;
    text-transform: uppercase;
  }
}
