@import 'styles/variables.less';

.br-rich-text-editor.quill {
  .ql-toolbar {
    min-height: 60px;
    display: flex;
    align-items: center;
    background-color: @bg-gray-light;
    border: 1px solid @gray-300;
    border-radius: 4px 4px 0 0;
    font-family: unset;
    flex-wrap: wrap;
    gap: 8px;

    .ql-formats {
      display: flex;
      margin: 0;
    }

    .ql-header {
      width: 180px;
      height: 36px;
      .ql-picker-label {
        display: flex;
        align-items: center;
        border: 1px solid @gray-300;
        border-radius: 4px;

        svg {
          polygon {
            stroke: @icon-default;
          }
        }
      }
    }

    .ql-header .ql-picker-label:hover {
      &::before {
        color: @text-gray-dark;
      }
    }
  }

  .ql-container {
    border: 1px solid @gray-300;
    border-radius: 0 0 4px 4px;
    .ql-editor {
      &::before {
        font-style: normal;
        color: @text-gray-light;
      }
    }
  }
}

@media only screen and (max-width: @sm) {
  .br-rich-text-editor.quill {
    .ql-toolbar {
      .ql-header {
        width: 100px;
      }
    }
  }
}
