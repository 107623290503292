a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
a:not(.btn):hover {
  text-decoration: underline;
}
input,
select,
textarea,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}
main {
  overflow: hidden;
}
u > span {
  text-decoration: inherit;
}
ol,
ul {
  padding-left: 2.5rem;
  margin: 0.625rem 0;
}
p {
  word-wrap: break-word;
}
h1 > span,
h2 > span,
h3 > span,
h4 > span,
h5 > span,
h6 > span {
  display: block;
  word-wrap: break-word;
}
iframe {
  border: 0;
}
* {
  box-sizing: border-box;
}

.item-absolute {
  position: absolute;
}
.item-relative {
  position: relative;
}
.item-block {
  display: block;
  height: 100%;
  width: 100%;
}
.item-cover {
  z-index: 1000030;
}
.item-breakword {
  word-wrap: break-word;
}
.item-content-box {
  box-sizing: content-box;
}
.hidden {
  display: none;
}
.clearfix {
  clear: both;
}
sup {
  margin-left: 0.1rem;
  line-height: 0;
}
@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
}
@supports (-webkit-overflow-scrolling: touch) {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .image[src$='.svg'] {
      width: calc(100% + 1px);
    }
  }
}
.show-for-sr {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
.headline {
  font-family: Cairo;
  font-weight: 700;
}
.section-fit {
  max-width: 400px;
}

.section-relative {
  position: relative;
  margin: 0 auto;
}
.js-text-scaling .section-relative {
  margin: var(--section-relative-margin);
}
.section-inner {
  height: 100%;
}
#page-block-isw3p557aqn {
  height: 57.375rem;
  max-width: 100%;
}
#page-block-isw3p557aqn .section-holder-border {
  border: 0;
}
#page-block-isw3p557aqn .section-block {
  background: url(//v.fastcdn.co/u/4126587d/61394986-0-new-Landing-page-cle.png)
    no-repeat 44% 13% / auto;
  height: 57.375rem;
}
#page-block-isw3p557aqn .section-holder-overlay {
  display: none;
}
#element-1404 {
  top: 24.25rem;
  left: 2.0625rem;
  height: 15.0721rem;
  width: 22.5rem;
  z-index: 15;
}
#element-1192 {
  top: 5rem;
  left: 6.75rem;
  height: 3.5882rem;
  width: 11.4375rem;
  z-index: 9;
}
#element-1408 {
  top: 29.5625rem;
  left: 1.3125rem;
  height: 2.7688rem;
  width: 6.125rem;
  z-index: 17;
}
#element-747 {
  top: 11.25rem;
  left: 1.25rem;
  height: 3.375rem;
  width: 22.5rem;
  z-index: 4;
  color: #37465a;
  font-size: 3.3437rem;
  line-height: 3.375rem;
  text-align: center;
  font-weight: 700;
}
#element-747 .x_7457154a {
  text-align: center;
  line-height: 3.375rem;
  font-size: 3.3437rem;
}
#element-747 .x_74f0b483 {
  color: #057175;
}
#element-747 strong {
  font-weight: 700;
}
#element-747.headline {
  font-weight: 700;
}
#element-1402 {
  top: 30.625rem;
  left: 1.3125rem;
  height: 18.5294rem;
  width: 22.5rem;
  z-index: 16;
}
#element-1407 {
  top: 31rem;
  left: 6.625rem;
  height: 7.875rem;
  width: 17.125rem;
  z-index: 13;
}
#element-1182 {
  top: 16.3125rem;
  left: 1.25rem;
  height: 1.75rem;
  width: 22.5rem;
  z-index: 8;
  color: #37465a;
  font-size: 1.7337rem;
  line-height: 1.75rem;
  text-align: center;
  font-weight: 400;
}
#element-1182 .x_06e86a31 {
  text-align: center;
  line-height: 1.75rem;
  font-size: 1.7337rem;
}
#element-1182 .x_69c6632a {
  color: #e30613;
}
#element-1182 strong {
  font-weight: 700;
}
#element-1182.headline {
  font-weight: 400;
}
#element-758 {
  top: 19.1875rem;
  left: 1.9375rem;
  height: 3.9375rem;
  width: 21.125rem;
  z-index: 5;
  color: #37465a;
  font-size: 0.9288rem;
  line-height: 1.3125rem;
  text-align: center;
}
#element-758 .x_0d0d8539 {
  text-align: center;
  line-height: 1.3125rem;
  font-size: 0.9288rem;
}
#element-758 .x_74f0b483 {
  color: #057175;
}
#element-1653 {
  top: 50.3125rem;
  left: 1.25rem;
  height: 4.4375rem;
  width: 22.375rem;
  z-index: 126;
  font-size: 4.4375rem;
}
@font-face {
  font-family: BebasNeue;
  font-style: normal;
  font-weight: 400;
  src: url(https://v.fastcdn.co/a/font/bebasneue-webfont.eot);
  src: url(https://v.fastcdn.co/a/font/bebasneue-webfont.eot)
      format('embedded-opentype'),
    url(https://v.fastcdn.co/a/font/bebasneue-webfont.woff2) format('woff2'),
    url(https://v.fastcdn.co/a/font/bebasneue-webfont.woff) format('woff'),
    url(https://v.fastcdn.co/a/font/bebasneue-webfont.ttf) format('truetype');
}
.timer-column {
  width: 20%;
  float: left;
  text-align: center;
  margin-left: 5%;
}
.timer-column:first-child {
  width: 25%;
  margin-left: 0;
}
.timer-box {
  position: relative;
  font-size: 0.78em;
  margin-bottom: 0.12em;
  border-radius: 5px;
  font-family: BebasNeue, sans-serif;
  height: 100%;
  line-height: 1.28em;
}
.timer-box:after,
.timer-box:before {
  content: '';
  display: block;
  border-radius: 50%;
  background-color: inherit;
  position: absolute;
  left: -0.215em;
  width: 0.1em;
  height: 0.1em;
}
.timer-box:after {
  bottom: 35%;
}
.timer-box:before {
  top: 35%;
}
.timer-box:first-child:before,
.timer-box:first-child:after {
  display: none;
}
.timer-number-zero {
  visibility: hidden;
}
.timer-text-none .timer-box {
  font-size: 0.78em;
}
.timer-text-bottom .timer-labels-top,
.timer-text-top .timer-labels-bottom,
.timer-text-none .timer-labels {
  display: none;
}
.timer-labels {
  text-transform: uppercase;
  margin-bottom: 0.18em;
  font-size: 0.13333em;
  position: relative;
}
.timer-label {
  padding-bottom: 0.1875rem;
}
#element-1653 .timer-box {
  color: rgb(255, 255, 255);
  background-color: #37465a;
}
#element-1653 .timer-labels {
  color: #37465a;
}
#element-1414 {
  top: 38.625rem;
  left: 0;
  height: 0.9935rem;
  width: 22.5rem;
  z-index: 14;
}
#page-block-cfk8nv8rlco {
  height: 33.9375rem;
  max-width: 100%;
}
#page-block-cfk8nv8rlco .section-holder-border {
  border: 0;
}
#page-block-cfk8nv8rlco .section-block {
  background: url(//v.fastcdn.co/u/4126587d/61395214-0-new-Landing-page-cle.png)
    repeat rgb(60, 60, 59) 51% 23% / cover;
  height: 33.9375rem;
}
#page-block-cfk8nv8rlco .section-holder-overlay {
  display: none;
}
#element-1411 {
  top: 1.1875rem;
  left: 9.0625rem;
  height: 10.5101rem;
  width: 6.875rem;
  z-index: 21;
}
#element-918 {
  top: 13.625rem;
  left: 1.25rem;
  height: 2.75rem;
  width: 22.5rem;
  z-index: 19;
  color: #37465a;
  font-size: 2.7245rem;
  line-height: 2.75rem;
  text-align: center;
  font-weight: 400;
}
#element-918 .x_07204851 {
  text-align: center;
  line-height: 2.75rem;
  font-size: 2.7245rem;
}
#element-918 .x_f2074b6c {
  color: #ffffff;
}
#element-918 strong {
  font-weight: 700;
}
#element-918.headline {
  font-weight: 400;
}
#element-1410 {
  top: 17.875rem;
  left: 1.25rem;
  height: 2.75rem;
  width: 22.5rem;
  z-index: 20;
  color: #37465a;
  font-size: 2.7245rem;
  line-height: 2.75rem;
  text-align: center;
  font-weight: 700;
}
#element-1410 .x_07204851 {
  text-align: center;
  line-height: 2.75rem;
  font-size: 2.7245rem;
}
#element-1410 .x_f2074b6c {
  color: #ffffff;
}
#element-1410 strong {
  font-weight: 700;
}
#element-1410.headline {
  font-weight: 700;
}
#element-1413 {
  top: 6.0625rem;
  left: 5.8125rem;
  height: 6.8054rem;
  width: 4.625rem;
  z-index: 23;
}
#element-1412 {
  top: 8.6875rem;
  left: 15rem;
  height: 4.2207rem;
  width: 2.875rem;
  z-index: 22;
}
#element-917 {
  top: 21.75rem;
  left: 1.25rem;
  height: 10.5rem;
  width: 22.5rem;
  z-index: 18;
  color: #37465a;
  font-size: 1.0526rem;
  line-height: 1.4875rem;
  text-align: center;
  font-weight: 300;
}
#element-917 .x_8642b94b {
  text-align: center;
  line-height: 1.5rem;
  font-size: 1.0526rem;
}
#element-917 .x_f2074b6c {
  color: #ffffff;
}
#element-917 strong {
  font-weight: 700;
}
#element-917.paragraph {
  font-weight: 300;
}
#page-block-qkt1x4jq60i {
  height: 182.25rem;
  max-width: 100%;
}
#page-block-qkt1x4jq60i .section-holder-border {
  border: 0;
}
#page-block-qkt1x4jq60i .section-block {
  background: url(//v.fastcdn.co/u/4126587d/61406299-0-Artboard-1.png) repeat
    rgb(245, 255, 255) 50% 50% / cover;
  height: 182.25rem;
}
#page-block-qkt1x4jq60i .section-holder-overlay {
  display: none;
}
#element-988 {
  top: 5rem;
  left: 1.1875rem;
  height: 1.875rem;
  width: 22.5rem;
  z-index: 10;
  color: #37465a;
  font-size: 1.8576rem;
  line-height: 1.875rem;
  text-align: center;
}
#element-988 .x_a76aec5f {
  text-align: center;
  line-height: 1.875rem;
  font-size: 1.8576rem;
}
#element-988 .x_69c6632a {
  color: #e30613;
}
#element-1560 {
  top: 11.375rem;
  left: 8.0625rem;
  height: 8.875rem;
  width: 8.875rem;
  z-index: 108;
}
#element-1592 {
  top: 31.875rem;
  left: 8.125rem;
  height: 8.875rem;
  width: 8.875rem;
  z-index: 104;
}
#element-991 {
  top: 41.5rem;
  left: 3.75rem;
  height: 1.375rem;
  width: 17.5rem;
  z-index: 103;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.35rem;
  text-align: center;
}
#element-991 .x_efd3fa39 {
  text-align: center;
  line-height: 1.375rem;
  font-size: 1.1146rem;
}
#element-989 {
  top: 42.875rem;
  left: 3.8125rem;
  height: 1.625rem;
  width: 17.5rem;
  z-index: 102;
  color: #37465a;
  font-size: 0.9907rem;
  line-height: 1.6rem;
  text-align: center;
}
#element-989 .x_dc68832a {
  text-align: center;
  line-height: 1.625rem;
  font-size: 0.9907rem;
}
#element-1623 {
  top: 45.0625rem;
  left: 9.5rem;
  height: 1.8884rem;
  width: 5.875rem;
  z-index: 105;
}
#element-1593 {
  top: 52.1875rem;
  left: 8.0625rem;
  height: 8.875rem;
  width: 8.875rem;
  z-index: 100;
}
#element-1594 {
  top: 72.5rem;
  left: 8rem;
  height: 8.875rem;
  width: 8.875rem;
  z-index: 96;
}
#element-986 {
  top: 21.125rem;
  left: 3.8125rem;
  height: 1.375rem;
  width: 17.375rem;
  z-index: 106;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.35rem;
  text-align: center;
}
#element-986 .x_efd3fa39 {
  text-align: center;
  line-height: 1.375rem;
  font-size: 1.1146rem;
}
#element-986 .x_a269ab78 {
  color: #37465a;
}
#element-1572 {
  top: 82.0625rem;
  left: 3.75rem;
  height: 1.375rem;
  width: 17.5rem;
  z-index: 95;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.35rem;
  text-align: center;
}
#element-1572 .x_efd3fa39 {
  text-align: center;
  line-height: 1.375rem;
  font-size: 1.1146rem;
}
#element-1576 {
  top: 62rem;
  left: 3.8125rem;
  height: 1.375rem;
  width: 17.375rem;
  z-index: 98;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.35rem;
  text-align: center;
}
#element-1576 .x_efd3fa39 {
  text-align: center;
  line-height: 1.375rem;
  font-size: 1.1146rem;
}
#element-995 {
  top: 22.5rem;
  left: 3.75rem;
  height: 1.625rem;
  width: 17.375rem;
  z-index: 107;
  color: #37465a;
  font-size: 0.9907rem;
  line-height: 1.6rem;
  text-align: center;
}
#element-995 .x_dc68832a {
  text-align: center;
  line-height: 1.625rem;
  font-size: 0.9907rem;
}
#element-1570 {
  top: 83.4375rem;
  left: 3.75rem;
  height: 1.625rem;
  width: 17.5rem;
  z-index: 94;
  color: #37465a;
  font-size: 0.9907rem;
  line-height: 1.6rem;
  text-align: center;
}
#element-1570 .x_dc68832a {
  text-align: center;
  line-height: 1.625rem;
  font-size: 0.9907rem;
}
#element-1578 {
  top: 63.375rem;
  left: 3.75rem;
  height: 1.625rem;
  width: 17.375rem;
  z-index: 99;
  color: #37465a;
  font-size: 0.9907rem;
  line-height: 1.6rem;
  text-align: center;
}
#element-1578 .x_dc68832a {
  text-align: center;
  line-height: 1.625rem;
  font-size: 0.9907rem;
}
#element-1624 {
  top: 24.6875rem;
  left: 9.5rem;
  height: 1.8884rem;
  width: 5.875rem;
  z-index: 109;
}
#element-1621 {
  top: 86rem;
  left: 9.5rem;
  height: 1.8884rem;
  width: 5.875rem;
  z-index: 97;
}
#element-1622 {
  top: 65.5rem;
  left: 9.5rem;
  height: 1.8884rem;
  width: 5.875rem;
  z-index: 101;
}
#element-1618 {
  top: 92.3125rem;
  left: 7.9375rem;
  height: 9rem;
  width: 9rem;
  z-index: 124;
}
#element-1618 .cropped {
  background: url(//v.fastcdn.co/u/4126587d/61472750-0-pics-frame-01.png) 0 0 /
    9rem 9rem;
}
#element-1597 {
  top: 112.5625rem;
  left: 7.875rem;
  height: 9rem;
  width: 9rem;
  z-index: 120;
}
#element-1598 {
  top: 134.5rem;
  left: 7.875rem;
  height: 9rem;
  width: 9rem;
  z-index: 116;
}
#element-1599 {
  top: 156.25rem;
  left: 7.875rem;
  height: 9rem;
  width: 9rem;
  z-index: 112;
}
#element-1614 {
  top: 101.8125rem;
  left: 3.5625rem;
  height: 1.375rem;
  width: 17.75rem;
  z-index: 122;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.35rem;
  text-align: center;
}
#element-1614 .x_efd3fa39 {
  text-align: center;
  line-height: 1.375rem;
  font-size: 1.1146rem;
}
#element-1234 {
  top: 122.875rem;
  left: 3.5625rem;
  height: 1.375rem;
  width: 17.75rem;
  z-index: 118;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.35rem;
  text-align: center;
}
#element-1234 .x_efd3fa39 {
  text-align: center;
  line-height: 1.375rem;
  font-size: 1.1146rem;
}
#element-1580 {
  top: 144.5rem;
  left: 3.5625rem;
  height: 1.375rem;
  width: 17.75rem;
  z-index: 114;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.35rem;
  text-align: center;
}
#element-1580 .x_efd3fa39 {
  text-align: center;
  line-height: 1.375rem;
  font-size: 1.1146rem;
}
#element-1566 {
  top: 166.375rem;
  left: 3.6875rem;
  height: 1.375rem;
  width: 17.375rem;
  z-index: 110;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.35rem;
  text-align: center;
}
#element-1566 .x_efd3fa39 {
  text-align: center;
  line-height: 1.375rem;
  font-size: 1.1146rem;
}
#element-1616 {
  top: 103.4375rem;
  left: 3.75rem;
  height: 1.625rem;
  width: 17.5rem;
  z-index: 123;
  color: #37465a;
  font-size: 0.9907rem;
  line-height: 1.6rem;
  text-align: center;
}
#element-1616 .x_dc68832a {
  text-align: center;
  line-height: 1.625rem;
  font-size: 0.9907rem;
}
#element-1264 {
  top: 124.5625rem;
  left: 3.625rem;
  height: 1.625rem;
  width: 17.5rem;
  z-index: 119;
  color: #37465a;
  font-size: 0.9907rem;
  line-height: 1.6rem;
  text-align: center;
}
#element-1264 .x_dc68832a {
  text-align: center;
  line-height: 1.625rem;
  font-size: 0.9907rem;
}
#element-1582 {
  top: 146.375rem;
  left: 3.625rem;
  height: 1.625rem;
  width: 17.5rem;
  z-index: 115;
  color: #37465a;
  font-size: 0.9907rem;
  line-height: 1.6rem;
  text-align: center;
}
#element-1582 .x_dc68832a {
  text-align: center;
  line-height: 1.625rem;
  font-size: 0.9907rem;
}
#element-1568 {
  top: 168rem;
  left: 3.6875rem;
  height: 1.625rem;
  width: 17.375rem;
  z-index: 111;
  color: #37465a;
  font-size: 0.9907rem;
  line-height: 1.6rem;
  text-align: center;
}
#element-1568 .x_dc68832a {
  text-align: center;
  line-height: 1.625rem;
  font-size: 0.9907rem;
}
#element-1634 {
  top: 105.625rem;
  left: 9.5625rem;
  height: 1.8884rem;
  width: 5.875rem;
  z-index: 125;
}
#element-1632 {
  top: 126.875rem;
  left: 9.5rem;
  height: 1.8884rem;
  width: 5.875rem;
  z-index: 121;
}
#element-1630 {
  top: 148.5625rem;
  left: 9.5rem;
  height: 1.8884rem;
  width: 5.875rem;
  z-index: 117;
}
#element-1628 {
  top: 170.3125rem;
  left: 9.625rem;
  height: 1.8884rem;
  width: 5.875rem;
  z-index: 113;
}
#page-block-ggdycfzqc7s {
  height: 244rem;
  max-width: 100%;
}
#page-block-ggdycfzqc7s .section-holder-border {
  border: 0;
}
#page-block-ggdycfzqc7s .section-block {
  background: rgb(242, 242, 242);
  height: 244rem;
}
#page-block-ggdycfzqc7s .section-holder-overlay {
  display: none;
}
#element-1158 {
  top: 7.8125rem;
  left: 4.9375rem;
  height: 3.0625rem;
  width: 15rem;
  z-index: 11;
  color: #37465a;
  font-size: 2.5387rem;
  line-height: 3.075rem;
  text-align: left;
  font-weight: 400;
}
#element-1158 .x_4099d1b7 {
  text-align: left;
  line-height: 3.0625rem;
  font-size: 2.5387rem;
}
#element-1158 .x_74f0b483 {
  color: #057175;
}
#element-1158 strong {
  font-weight: 700;
}
#element-1158.headline {
  font-weight: 400;
}
#element-1266 {
  top: 11.8125rem;
  left: 3.0625rem;
  height: 3.8125rem;
  width: 18.75rem;
  z-index: 12;
  color: #37465a;
  font-size: 3.7771rem;
  line-height: 3.8125rem;
  text-align: left;
}
#element-1266 .x_0d3e9076 {
  text-align: center;
  line-height: 3.8125rem;
  font-size: 3.7771rem;
}
#element-1266 .x_69c6632a {
  color: #e30613;
}
#element-1417 {
  top: 3.375rem;
  left: 0;
  height: 15.9585rem;
  width: 14.125rem;
  z-index: 43;
}
#element-1418 {
  top: 24.1875rem;
  left: 10.0625rem;
  height: 4.25rem;
  width: 4.875rem;
  z-index: 44;
}
#element-1272 {
  top: 30.3125rem;
  left: 1.25rem;
  height: 5.25rem;
  width: 22.5rem;
  z-index: 26;
  color: #37465a;
  font-size: 1.4241rem;
  line-height: 1.725rem;
  text-align: center;
}
#element-1272 .x_6ee82066 {
  text-align: center;
  line-height: 1.75rem;
  font-size: 1.4241rem;
}
#element-1272 .x_a7fe84b4 {
  color: #0098a5;
}
#element-1667 {
  top: 37.4375rem;
  left: 1.625rem;
  height: 13.625rem;
  width: 21.75rem;
  z-index: 145;
}
#element-1668 {
  top: 72.6875rem;
  left: 1.625rem;
  height: 13.625rem;
  width: 21.75rem;
  z-index: 145;
}
#element-1669 {
  top: 98.6875rem;
  left: 1.625rem;
  height: 13.625rem;
  width: 21.75rem;
  z-index: 145;
}
#element-1670 {
  top: 120.6875rem;
  left: 1.625rem;
  height: 13.625rem;
  width: 21.75rem;
  z-index: 145;
}
#element-1671 {
  top: 152.9rem;
  left: 2.125rem;
  height: 13.625rem;
  width: 21.75rem;
  z-index: 145;
}
#element-1274 {
  top: 53.3125rem;
  left: 1.25rem;
  height: 4.6875rem;
  width: 22.5rem;
  z-index: 27;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.575rem;
  text-align: center;
}
#element-1274 .x_bcbce646 {
  text-align: center;
  line-height: 1.5625rem;
  font-size: 1.1146rem;
}
#element-1274 .x_403c9345 {
  color: #151d2d;
}
#element-1326 {
  top: 59.5rem;
  left: 7.5rem;
  height: 1.625rem;
  width: 3.625rem;
  z-index: 35;
}
.circle {
  border-radius: 50%;
}
.shape {
  height: inherit;
}
.line-horizontal {
  height: 0.625rem;
  margin-bottom: 10px;
}
.line-vertical {
  height: 100%;
  margin-right: 0.625rem;
}
[class*='line-'] {
  box-sizing: content-box;
}
#element-1326 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(142, 214, 216);
}
#element-1322 {
  top: 59.5625rem;
  left: 11.5rem;
  height: 1.5625rem;
  width: 3.625rem;
  z-index: 32;
}
#element-1322 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(205, 205, 205);
}
#element-1320 {
  top: 59.5rem;
  left: 15.5625rem;
  height: 1.5625rem;
  width: 1.625rem;
  z-index: 31;
}
#element-1320 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(205, 205, 205);
}
#element-1318 {
  top: 59.625rem;
  left: 12.375rem;
  height: 1.25rem;
  width: 2.875rem;
  z-index: 33;
  color: #37465a;
  font-size: 0.9072rem;
  line-height: 1.2821rem;
  text-align: left;
}
#element-1318 .x_b7e19525 {
  text-align: left;
  line-height: 1.25rem;
  font-size: 0.9072rem;
}
#element-1318 .x_f2074b6c {
  color: #ffffff;
}
#element-1321 {
  top: 59.5625rem;
  left: 15.9rem;
  height: 1.25rem;
  width: 1.25rem;
  z-index: 34;
  color: #37465a;
  font-size: 0.9072rem;
  line-height: 1.2821rem;
  text-align: left;
}
#element-1321 .x_b7e19525 {
  text-align: left;
  line-height: 1.25rem;
  font-size: 0.9072rem;
}
#element-1321 .x_f2074b6c {
  color: #ffffff;
}
#element-1328 {
  top: 59.6875rem;
  left: 8rem;
  height: 1.125rem;
  width: 3.375rem;
  z-index: 36;
  color: #37465a;
  font-size: 0.805rem;
  line-height: 1.1375rem;
  text-align: left;
}
#element-1328 .x_ff17dd85 {
  text-align: left;
  line-height: 1.125rem;
  font-size: 0.805rem;
}
#element-1328 .x_f2074b6c {
  color: #ffffff;
}
#element-1296 {
  top: 179.6875rem;
  left: 0;
  height: 1.4375rem;
  width: 24.9375rem;
  z-index: 28;
}
#element-1296 .shape {
  border-bottom: 3px dotted #d2d2d2;
}
#element-1660 {
  top: 72rem;
  left: 9.1875rem;
  height: 3.25rem;
  width: 6.625rem;
  z-index: 135;
}
#element-16601 {
}
#element-1161 {
  top: 77.3125rem;
  left: 1.25rem;
  height: 5.25rem;
  width: 22.5rem;
  z-index: 127;
  color: #37465a;
  font-size: 1.4241rem;
  line-height: 1.725rem;
  text-align: center;
}
#element-1161 .x_6ee82066 {
  text-align: center;
  line-height: 1.75rem;
  font-size: 1.4241rem;
}
#element-1161 .x_a7fe84b4 {
  color: #0098a5;
}
#element-1161 .x_752e43b0 {
  text-align: left;
  caret-color: rgb(0, 152, 165);
}
#element-1533 {
  top: 80.5rem;
  left: 0;
  height: 6.25rem;
  width: 24.9375rem;
  z-index: 133;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.575rem;
  text-align: center;
}
#element-1533 .x_bcbce646 {
  text-align: center;
  line-height: 1.5625rem;
  font-size: 1.1146rem;
}
#element-1533 .x_403c9345 {
  color: #151d2d;
}
#element-1342 {
  top: 88rem;
  left: 7.25rem;
  height: 1.625rem;
  width: 3.75rem;
  z-index: 131;
}
#element-1342 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(142, 214, 216);
}
#element-1336 {
  top: 87.9375rem;
  left: 11.5rem;
  height: 1.6875rem;
  width: 3.75rem;
  z-index: 129;
}
#element-1336 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(205, 205, 205);
}
#element-1635 {
  top: 88rem;
  left: 12.4375rem;
  height: 1.3125rem;
  width: 2rem;
  z-index: 134;
  color: #37465a;
  font-size: 0.9288rem;
  line-height: 1.3125rem;
  text-align: left;
}
#element-1635 .x_5319a2c7 {
  text-align: left;
  line-height: 1.3125rem;
  font-size: 0.9288rem;
}
#element-1635 .x_f2074b6c {
  color: #ffffff;
}
#element-1333 {
  top: 88rem;
  left: 15.625rem;
  height: 1.5625rem;
  width: 1.5rem;
  z-index: 128;
}
#element-1333 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(205, 205, 205);
}
#element-1340 {
  top: 88.0625rem;
  left: 15.8125rem;
  height: 1.3125rem;
  width: 1.25rem;
  z-index: 130;
  color: #37465a;
  font-size: 0.9288rem;
  line-height: 1.3125rem;
  text-align: left;
}
#element-1340 .x_5319a2c7 {
  text-align: left;
  line-height: 1.3125rem;
  font-size: 0.9288rem;
}
#element-1340 .x_f2074b6c {
  color: #ffffff;
}
#element-1344 {
  top: 88.1875rem;
  left: 7.875rem;
  height: 1.125rem;
  width: 3.375rem;
  z-index: 132;
  color: #37465a;
  font-size: 0.805rem;
  line-height: 1.1375rem;
  text-align: left;
}
#element-1344 .x_ff17dd85 {
  text-align: left;
  line-height: 1.125rem;
  font-size: 0.805rem;
}
#element-1344 .x_f2074b6c {
  color: #ffffff;
}
#element-1298 {
  top: 144.3125rem;
  left: 0.0625rem;
  height: 1.4375rem;
  width: 24.875rem;
  z-index: 29;
}
#element-1298 .shape {
  border-bottom: 3px dotted #d2d2d2;
}
#element-1297 {
  top: 69.3125rem;
  left: 0.0625rem;
  height: 1.4375rem;
  width: 24.875rem;
  z-index: 29;
}
#element-1297 .shape {
  border-bottom: 3px dotted #d2d2d2;
}
#element-1512 {
  top: 94.5625rem;
  left: 10.3125rem;
  height: 4.125rem;
  width: 4.375rem;
  z-index: 76;
}
#element-1169 {
  top: 102.5rem;
  left: 1.25rem;
  height: 3.625rem;
  width: 22.5rem;
  z-index: 25;
  color: #37465a;
  font-size: 1.4861rem;
  line-height: 1.8rem;
  text-align: center;
}
#element-1169 .x_5f0ed501 {
  text-align: center;
  line-height: 1.8125rem;
  font-size: 1.4861rem;
}
#element-1169 .x_a7fe84b4 {
  color: #0098a5;
}
#element-1534 {
  top: 107.0625rem;
  left: 1.1875rem;
  height: 4.6875rem;
  width: 22.5rem;
  z-index: 84;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.575rem;
  text-align: center;
}
#element-1534 .x_bcbce646 {
  text-align: center;
  line-height: 1.5625rem;
  font-size: 1.1146rem;
}
#element-1534 .x_403c9345 {
  color: #151d2d;
}
#element-1384 {
  top: 112.1875rem;
  left: 7.375rem;
  height: 1.5625rem;
  width: 3.75rem;
  z-index: 41;
}
#element-1384 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(142, 214, 216);
}
#element-1378 {
  top: 112.1875rem;
  left: 11.5rem;
  height: 1.625rem;
  width: 3.75rem;
  z-index: 38;
}
#element-1378 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(205, 205, 205);
}
#element-1380 {
  top: 112.125rem;
  left: 12.3125rem;
  height: 1.3125rem;
  width: 3.6875rem;
  z-index: 39;
  color: #37465a;
  font-size: 0.9288rem;
  line-height: 1.3125rem;
  text-align: left;
}
#element-1380 .x_5319a2c7 {
  text-align: left;
  line-height: 1.3125rem;
  font-size: 0.9288rem;
}
#element-1380 .x_f2074b6c {
  color: #ffffff;
}
#element-1376 {
  top: 112.1875rem;
  left: 15.625rem;
  height: 1.625rem;
  width: 1.625rem;
  z-index: 37;
}
#element-1376 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(205, 205, 205);
}
#element-1382 {
  top: 112.25rem;
  left: 16.125rem;
  height: 1.3125rem;
  width: 1.25rem;
  z-index: 40;
  color: #37465a;
  font-size: 0.9288rem;
  line-height: 1.3125rem;
  text-align: left;
}
#element-1382 .x_5319a2c7 {
  text-align: left;
  line-height: 1.3125rem;
  font-size: 0.9288rem;
}
#element-1382 .x_f2074b6c {
  color: #ffffff;
}
#element-1386 {
  top: 112.3125rem;
  left: 8.4rem;
  height: 1.125rem;
  width: 3.125rem;
  z-index: 42;
  color: #37465a;
  font-size: 0.805rem;
  line-height: 1.1375rem;
  text-align: left;
}
#element-1386 .x_ff17dd85 {
  text-align: left;
  line-height: 1.125rem;
  font-size: 0.805rem;
}
#element-1386 .x_f2074b6c {
  color: #ffffff;
}
#element-1300 {
  top: 195.3125rem;
  left: 0.0625rem;
  height: 1.4375rem;
  width: 24.875rem;
  z-index: 30;
}
#element-1300 .shape {
  border-bottom: 3px dotted #d2d2d2;
}
#element-1700 {
  top: 218.3125rem;
  left: 0.0625rem;
  height: 1.4375rem;
  width: 24.875rem;
  z-index: 30;
}
#element-1700 .shape {
  border-bottom: 3px dotted #d2d2d2;
}
#element-1661 {
  top: 119.625rem;
  left: 9.6875rem;
  height: 3.625rem;
  width: 5.625rem;
  z-index: 143;
}
#element-1172 {
  top: 124.875rem;
  left: 1.25rem;
  height: 3.75rem;
  width: 22.5rem;
  z-index: 136;
  color: #37465a;
  font-size: 1.548rem;
  line-height: 1.875rem;
  text-align: center;
}
#element-1172 .x_ef6d09e5 {
  text-align: center;
  line-height: 1.875rem;
  font-size: 1.548rem;
}
#element-1172 .x_a7fe84b4 {
  color: #0098a5;
}
#element-1172 .x_752e43b0 {
  text-align: left;
  caret-color: rgb(0, 152, 165);
}
#element-1535 {
  top: 129.6875rem;
  left: 0;
  height: 4.6875rem;
  width: 24.9375rem;
  z-index: 142;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.575rem;
  text-align: center;
}
#element-1535 .x_bcbce646 {
  text-align: center;
  line-height: 1.5625rem;
  font-size: 1.1146rem;
}
#element-1535 .x_403c9345 {
  color: #151d2d;
}
#element-1370 {
  top: 137.3125rem;
  left: 7.9375rem;
  height: 1.625rem;
  width: 3.6875rem;
  z-index: 140;
}
#element-1370 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(142, 214, 216);
}
#element-1364 {
  top: 137.3125rem;
  left: 12rem;
  height: 1.625rem;
  width: 3.75rem;
  z-index: 138;
}
#element-1364 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(205, 205, 205);
}
#element-1366 {
  top: 137.3125rem;
  left: 12.75rem;
  height: 1.3125rem;
  width: 3.125rem;
  z-index: 139;
  color: #37465a;
  font-size: 0.9288rem;
  line-height: 1.3125rem;
  text-align: left;
}
#element-1366 .x_5319a2c7 {
  text-align: left;
  line-height: 1.3125rem;
  font-size: 0.9288rem;
}
#element-1366 .x_f2074b6c {
  color: #ffffff;
}
#element-1361 {
  top: 137.3125rem;
  left: 16.125rem;
  height: 1.625rem;
  width: 1.5rem;
  z-index: 137;
}
#element-1361 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(205, 205, 205);
}
#element-1372 {
  top: 137.3875rem;
  left: 8.5625rem;
  height: 1.125rem;
  width: 2.625rem;
  z-index: 141;
  color: #37465a;
  font-size: 0.805rem;
  line-height: 1.1375rem;
  text-align: left;
}
#element-1372 .x_ff17dd85 {
  text-align: left;
  line-height: 1.125rem;
  font-size: 0.805rem;
}
#element-1372 .x_f2074b6c {
  color: #ffffff;
}
#element-1662 {
  top: 137.3125rem;
  left: 16.5rem;
  height: 1.3125rem;
  width: 1.625rem;
  z-index: 144;
  color: #37465a;
  font-size: 0.9288rem;
  line-height: 1.3125rem;
  text-align: left;
}
#element-1662 .x_5319a2c7 {
  text-align: left;
  line-height: 1.3125rem;
  font-size: 0.9288rem;
}
#element-1662 .x_f2074b6c {
  color: #ffffff;
}
#element-1419 {
  top: 172.0625rem;
  left: 0.125rem;
  height: 1.4375rem;
  width: 24.875rem;
  z-index: 45;
}
#element-1419 .shape {
  border-bottom: 3px dotted #d2d2d2;
}
#element-1518 {
  top: 146.5625rem;
  left: 8.8125rem;
  height: 4.625rem;
  width: 7.375rem;
  z-index: 77;
}
#element-1427 {
  top: 152.25rem;
  left: 3.75rem;
  height: 8.75rem;
  width: 17rem;
  z-index: 46;
  color: #37465a;
  font-size: 1.548rem;
  line-height: 2.1875rem;
  text-align: center;
}
#element-1427 .x_67ab24c6 {
  text-align: center;
  line-height: 2.1875rem;
  font-size: 1.548rem;
}
#element-1427 .x_a7fe84b4 {
  color: #0098a5;
}
#element-1536 {
  top: 161.625rem;
  left: 1rem;
  height: 6.25rem;
  width: 22.5rem;
  z-index: 85;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.575rem;
  text-align: center;
}
#element-1536 .x_bcbce646 {
  text-align: center;
  line-height: 1.5625rem;
  font-size: 1.1146rem;
}
#element-1536 .x_403c9345 {
  color: #151d2d;
}
#element-1439 {
  top: 169.0625rem;
  left: 7.25rem;
  height: 1.625rem;
  width: 3.6875rem;
  z-index: 51;
}
#element-1439 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(142, 214, 216);
}
#element-1433 {
  top: 169.0625rem;
  left: 11.375rem;
  height: 1.625rem;
  width: 3.625rem;
  z-index: 48;
}
#element-1433 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(205, 205, 205);
}
#element-1435 {
  top: 169.0625rem;
  left: 12.125rem;
  height: 1.3125rem;
  width: 3rem;
  z-index: 49;
  color: #37465a;
  font-size: 0.9288rem;
  line-height: 1.3125rem;
  text-align: left;
}
#element-1435 .x_2a8d1306 {
  text-align: left;
  line-height: 1.3125rem;
  font-size: 0.9288rem;
}
#element-1435 .x_f2074b6c {
  color: #ffffff;
}
#element-1431 {
  top: 169.0625rem;
  left: 15.4375rem;
  height: 1.625rem;
  width: 1.5rem;
  z-index: 47;
}
#element-1431 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(205, 205, 205);
}
#element-1441 {
  top: 169.25rem;
  left: 7.875rem;
  height: 1.125rem;
  width: 2.625rem;
  z-index: 52;
  color: #37465a;
  font-size: 0.805rem;
  line-height: 1.1375rem;
  text-align: left;
}
#element-1441 .x_ff17dd85 {
  text-align: left;
  line-height: 1.125rem;
  font-size: 0.805rem;
}
#element-1441 .x_f2074b6c {
  color: #ffffff;
}
#element-1437 {
  top: 169.1875rem;
  left: 15.625rem;
  height: 1.3125rem;
  width: 1.25rem;
  z-index: 50;
  color: #37465a;
  font-size: 0.9288rem;
  line-height: 1.3125rem;
  text-align: left;
}
#element-1437 .x_5319a2c7 {
  text-align: left;
  line-height: 1.3125rem;
  font-size: 0.9288rem;
}
#element-1437 .x_f2074b6c {
  color: #ffffff;
}
#element-1442 {
  top: 117.0625rem;
  left: 0.125rem;
  height: 1.4375rem;
  width: 24.875rem;
  z-index: 53;
}
#element-1442 .shape {
  border-bottom: 3px dotted #d2d2d2;
}
#element-1520 {
  top: 174.4375rem;
  left: 9.5rem;
  height: 3.875rem;
  width: 5.875rem;
  z-index: 78;
}
#element-1522 {
  top: 179.5rem;
  height: 1.875rem;
  width: 100%;
  z-index: 81;
  color: #37465a;
  font-size: 1.548rem;
  line-height: 1.875rem;
  text-align: center;
}
#element-1522 .x_ef6d09e5 {
  text-align: center;
  line-height: 1.875rem;
  font-size: 1.548rem;
}
#element-1522 .x_a7fe84b4 {
  color: #0098a5;
}
#element-1521 {
  top: 179.375rem;
  left: 2.875rem;
  height: 2.1875rem;
  width: 11.1875rem;
  z-index: 80;
  color: #37465a;
  font-size: 1.548rem;
  line-height: 2.1875rem;
  text-align: center;
}
#element-1521 .x_67ab24c6 {
  text-align: center;
  line-height: 2.1875rem;
  font-size: 1.548rem;
}
#element-1521 .x_a7fe84b4 {
  color: #0098a5;
}
#element-1449 {
  top: 179.375rem;
  left: 11.25rem;
  height: 2.1875rem;
  width: 10.5rem;
  z-index: 79;
  color: #37465a;
  font-size: 1.548rem;
  line-height: 2.1875rem;
  text-align: center;
}
#element-1449 .x_67ab24c6 {
  text-align: center;
  line-height: 2.1875rem;
  font-size: 1.548rem;
}
#element-1449 .x_a7fe84b4 {
  color: #0098a5;
}
#element-1557 {
  top: 185.875rem;
  height: 1.8125rem;
  width: 100%;
  z-index: 92;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.8rem;
  text-align: center;
  direction: rtl;
}
#element-1557 .x_e6507cf2 {
  text-align: center;
  line-height: 1.8125rem;
  font-size: 1.1146rem;
}
#element-1557 .x_403c9345 {
  color: #151d2d;
}
#element-1539 {
  top: 185.3125rem;
  left: 1.5625rem;
  height: 1.8125rem;
  width: 13.875rem;
  z-index: 88;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.8rem;
  text-align: center;
}
#element-1539 .x_e6507cf2 {
  text-align: center;
  line-height: 1.8125rem;
  font-size: 1.1146rem;
}
#element-1539 .x_403c9345 {
  color: #151d2d;
}
#element-1538 {
  top: 185.3125rem;
  left: 14.0625rem;
  height: 1.8125rem;
  z-index: 87;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.8rem;
  text-align: center;
}
#element-1538 .x_e6507cf2 {
  text-align: center;
  line-height: 1.8125rem;
  font-size: 1.1146rem;
}
#element-1538 .x_403c9345 {
  color: #151d2d;
}
#element-1537 {
  top: 183.75rem;
  left: 1.25rem;
  height: 1.5625rem;
  width: 22.5rem;
  z-index: 86;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.575rem;
  text-align: center;
}
#element-1537 .x_bcbce646 {
  text-align: center;
  line-height: 1.5625rem;
  font-size: 1.1146rem;
}
#element-1537 .x_403c9345 {
  color: #151d2d;
}
#element-1558 {
  top: 188.75rem;
  left: 6.375rem;
  height: 1.8125rem;
  width: 12.6875rem;
  z-index: 93;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.8rem;
  text-align: center;
}
#element-1558 .x_e6507cf2 {
  text-align: center;
  line-height: 1.8125rem;
  font-size: 1.1146rem;
}
#element-1558 .x_403c9345 {
  color: #151d2d;
}
#element-1541 {
  top: 187.125rem;
  left: 3.125rem;
  height: 1.6875rem;
  width: 10.375rem;
  z-index: 89;
  color: #37465a;
  font-size: 1.1765rem;
  line-height: 1.6625rem;
  text-align: right;
}
#element-1541 .x_a34e3e94 {
  text-align: right;
  line-height: 1.6875rem;
  font-size: 1.1765rem;
}
#element-1541 .x_403c9345 {
  color: #151d2d;
}
#element-1461 {
  top: 192.0625rem;
  left: 7.1875rem;
  height: 1.625rem;
  width: 3.75rem;
  z-index: 58;
}
#element-1461 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(142, 214, 216);
}
#element-1455 {
  top: 192.0625rem;
  left: 11.4375rem;
  height: 1.625rem;
  width: 3.625rem;
  z-index: 55;
}
#element-1455 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(205, 205, 205);
}
#element-1457 {
  top: 192.0625rem;
  left: 12.125rem;
  height: 1.3125rem;
  width: 2.875rem;
  z-index: 56;
  color: #37465a;
  font-size: 0.9288rem;
  line-height: 1.3125rem;
  text-align: left;
}
#element-1457 .x_5319a2c7 {
  text-align: left;
  line-height: 1.3125rem;
  font-size: 0.9288rem;
}
#element-1457 .x_f2074b6c {
  color: #ffffff;
}
#element-1453 {
  top: 192.0625rem;
  left: 15.5625rem;
  height: 1.625rem;
  width: 1.5rem;
  z-index: 54;
}
#element-1453 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(205, 205, 205);
}
#element-1463 {
  top: 192.25rem;
  left: 7.8125rem;
  height: 1.125rem;
  width: 2.6875rem;
  z-index: 59;
  color: #37465a;
  font-size: 0.805rem;
  line-height: 1.1375rem;
  text-align: left;
}
#element-1463 .x_ff17dd85 {
  text-align: left;
  line-height: 1.125rem;
  font-size: 0.805rem;
}
#element-1463 .x_f2074b6c {
  color: #ffffff;
}
#element-1459 {
  top: 192.1875rem;
  left: 16rem;
  height: 1.3125rem;
  width: 1.25rem;
  z-index: 57;
  color: #37465a;
  font-size: 0.9288rem;
  line-height: 1.3125rem;
  text-align: left;
}
#element-1459 .x_5319a2c7 {
  text-align: left;
  line-height: 1.3125rem;
  font-size: 0.9288rem;
}
#element-1459 .x_f2074b6c {
  color: #ffffff;
}
#element-1464 {
  top: 92rem;
  left: 0;
  height: 1.4375rem;
  width: 24.875rem;
  z-index: 60;
}
#element-1464 .shape {
  border-bottom: 3px dotted #d2d2d2;
}
#element-1524 {
  top: 198.3125rem;
  left: 10.375rem;
  height: 4.875rem;
  width: 5.25rem;
  z-index: 82;
}
#element-1471 {
  top: 204.4375rem;
  left: 1.75rem;
  height: 3.75rem;
  width: 22.5rem;
  z-index: 61;
  color: #37465a;
  font-size: 1.548rem;
  line-height: 1.875rem;
  text-align: center;
}
#element-1471 .x_ef6d09e5 {
  text-align: center;
  line-height: 1.875rem;
  font-size: 1.548rem;
}
#element-1471 .x_a7fe84b4 {
  color: #0098a5;
}
#element-1543 {
  top: 208.8125rem;
  left: 0;
  height: 4.6875rem;
  width: 24.9375rem;
  z-index: 90;
  color: #37465a;
  font-size: 1.1146rem;
  line-height: 1.575rem;
  text-align: center;
}
#element-1543 .x_bcbce646 {
  text-align: center;
  line-height: 1.5625rem;
  font-size: 1.1146rem;
}
#element-1543 .x_403c9345 {
  color: #151d2d;
}
#element-1483 {
  top: 215.5rem;
  left: 7.1875rem;
  height: 1.625rem;
  width: 3.75rem;
  z-index: 66;
}
#element-1483 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(142, 214, 216);
}
#element-1477 {
  top: 215.5rem;
  left: 11.4375rem;
  height: 1.625rem;
  width: 3.9375rem;
  z-index: 63;
}
#element-1477 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(205, 205, 205);
}
#element-1475 {
  top: 215.5rem;
  left: 15.8125rem;
  height: 1.625rem;
  width: 1.5625rem;
  z-index: 62;
}
#element-1475 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(205, 205, 205);
}
#element-1485 {
  top: 215.625rem;
  left: 7.875rem;
  height: 1.125rem;
  width: 3.5rem;
  z-index: 67;
  color: #37465a;
  font-size: 0.805rem;
  line-height: 1.1375rem;
  text-align: left;
}
#element-1485 .x_ff17dd85 {
  text-align: left;
  line-height: 1.125rem;
  font-size: 0.805rem;
}
#element-1485 .x_f2074b6c {
  color: #ffffff;
}
#element-1481 {
  top: 215.5625rem;
  left: 16rem;
  height: 1.3125rem;
  width: 1.25rem;
  z-index: 65;
  color: #37465a;
  font-size: 0.9288rem;
  line-height: 1.3125rem;
  text-align: left;
}
#element-1481 .x_5319a2c7 {
  text-align: left;
  line-height: 1.3125rem;
  font-size: 0.9288rem;
}
#element-1481 .x_f2074b6c {
  color: #ffffff;
}
#element-1479 {
  top: 215.75rem;
  left: 11.9375rem;
  height: 1.0625rem;
  width: 4rem;
  z-index: 64;
  color: #37465a;
  font-size: 0.743rem;
  line-height: 1.05rem;
  text-align: left;
}
#element-1479 .x_3206b8d5 {
  text-align: left;
  line-height: 1.0625rem;
  font-size: 0.743rem;
}
#element-1479 .x_f2074b6c {
  color: #ffffff;
}
#element-1486 {
  top: 61.375rem;
  left: 0;
  height: 1.4375rem;
  width: 24.9375rem;
  z-index: 68;
}
#element-1486 .shape {
  border-bottom: 3px dotted #d2d2d2;
}
#element-1525 {
  top: 221.125rem;
  left: 9.0625rem;
  height: 3.625rem;
  width: 6.75rem;
  z-index: 83;
}
#element-1493 {
  top: 226.5625rem;
  left: 1.375rem;
  height: 3.75rem;
  width: 22.5rem;
  z-index: 69;
  color: #37465a;
  font-size: 1.548rem;
  line-height: 1.875rem;
  text-align: center;
}
#element-1493 .x_ef6d09e5 {
  text-align: center;
  line-height: 1.875rem;
  font-size: 1.548rem;
}
#element-1493 .x_a7fe84b4 {
  color: #0098a5;
}
#element-1544 {
  top: 231rem;
  left: 1.875rem;
  height: 5.0625rem;
  width: 22.5rem;
  z-index: 91;
  color: #37465a;
  font-size: 1.1765rem;
  line-height: 1.6625rem;
  text-align: center;
}
#element-1544 .x_66d76769 {
  text-align: center;
  line-height: 1.6875rem;
  font-size: 1.1765rem;
}
#element-1544 .x_403c9345 {
  color: #151d2d;
}
#element-1505 {
  top: 237.6875rem;
  left: 7.125rem;
  height: 1.625rem;
  width: 3.75rem;
  z-index: 74;
}
#element-1505 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(142, 214, 216);
}
#element-1499 {
  top: 237.6875rem;
  left: 11.375rem;
  height: 1.625rem;
  width: 3.9375rem;
  z-index: 71;
}
#element-1499 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(205, 205, 205);
}
#element-1497 {
  top: 237.6875rem;
  left: 15.75rem;
  height: 1.625rem;
  width: 1.5625rem;
  z-index: 70;
}
#element-1497 .shape {
  border: 0;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  background: rgb(205, 205, 205);
}
#element-1507 {
  top: 237.875rem;
  left: 7.8125rem;
  height: 1.125rem;
  width: 2.75rem;
  z-index: 75;
  color: #37465a;
  font-size: 0.805rem;
  line-height: 1.1375rem;
  text-align: left;
}
#element-1507 .x_ff17dd85 {
  text-align: left;
  line-height: 1.125rem;
  font-size: 0.805rem;
}
#element-1507 .x_f2074b6c {
  color: #ffffff;
}
#element-1503 {
  top: 237.8125rem;
  left: 15.9375rem;
  height: 1.3125rem;
  width: 1.25rem;
  z-index: 73;
  color: #37465a;
  font-size: 0.9288rem;
  line-height: 1.3125rem;
  text-align: left;
}
#element-1503 .x_5319a2c7 {
  text-align: left;
  line-height: 1.3125rem;
  font-size: 0.9288rem;
}
#element-1503 .x_f2074b6c {
  color: #ffffff;
}
#element-1501 {
  top: 237.9375rem;
  left: 11.875rem;
  height: 1.0625rem;
  width: 3.375rem;
  z-index: 72;
  color: #37465a;
  font-size: 0.743rem;
  line-height: 1.05rem;
  text-align: left;
}
#element-1501 .x_3206b8d5 {
  text-align: left;
  line-height: 1.0625rem;
  font-size: 0.743rem;
}
#element-1501 .x_f2074b6c {
  color: #ffffff;
}
#page-block-ndo1v0x4co {
  height: 81.125rem;
  max-width: 100%;
}
#page-block-ndo1v0x4co .section-holder-border {
  border: 0;
}
#page-block-ndo1v0x4co .section-block {
  background: url(//v.fastcdn.co/u/4126587d/59481156-0-Artboard-1.png) repeat
    rgb(255, 255, 255) 50% 50% / cover;
  height: 81.125rem;
}
#page-block-ndo1v0x4co .section-holder-overlay {
  display: none;
}
#element-1180 {
  top: 3.25rem;
  left: 1rem;
  height: 6rem;
  width: 22.5rem;
  z-index: 6;
  color: #37465a;
  font-size: 1.9814rem;
  line-height: 2rem;
  text-align: center;
}
#element-1180 .x_1bfd115e {
  text-align: center;
  line-height: 2rem;
  font-size: 1.9814rem;
}
#element-1180 .x_69c6632a {
  color: #e30613;
}
#element-1180 .x_a9820a77 {
  color: #515151;
}
#element-1177 {
  top: 8.9375rem;
  left: 1.4375rem;
  height: 52rem;
  width: 22.0625rem;
  z-index: 7;
}
.modal-on {
  overflow: hidden;
}

fieldset {
  margin: 0;
  padding: 0;
  border: 0;
  min-width: 0;
}

#element-1177 .btn.btn-effect3d:active {
  box-shadow: none;
}

#element-1526 {
  top: 65.3125rem;
  left: 9.375rem;
  height: 15.515rem;
  width: 6.25rem;
  z-index: 24;
}
#page-block-0w2zrg83vz7 {
  height: 6.4375rem;
  max-width: 100%;
}
#page-block-0w2zrg83vz7 .section-holder-border {
  border: 0;
}
#page-block-0w2zrg83vz7 .section-block {
  background: rgb(30, 30, 30);
  height: 6.4375rem;
}
#page-block-0w2zrg83vz7 .section-holder-overlay {
  display: none;
}
#element-211 {
  top: 2.5rem;
  left: 1.6875rem;
  height: 1.375rem;
  width: 21.5625rem;
  z-index: 3;
  color: #37465a;
  font-size: 0.8669rem;
  line-height: 1.4rem;
  text-align: center;
}
#element-211 .x_938e8cfc {
  text-align: center;
  line-height: 1.375rem;
  font-size: 0.8669rem;
}
#element-211 .x_f2074b6c {
  color: #ffffff;
}

a.google-form-link:hover {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .hidden-mobile {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .section-fit {
    max-width: 60rem;
  }
  #page-block-isw3p557aqn {
    height: 42.5rem;
    max-width: 100%;
  }
  #page-block-isw3p557aqn .section-holder-border {
    border: 0;
  }
  #page-block-isw3p557aqn .section-block {
    background: url(//v.fastcdn.co/u/4126587d/61394986-0-new-Landing-page-cle.png)
      no-repeat 46% 39% / auto;
    height: 42.5rem;
  }
  #page-block-isw3p557aqn .section-holder-overlay {
    display: none;
  }
  #element-1404 {
    top: 1.3125rem;
    left: 7.875rem;
    height: 26.125rem;
    width: 39rem;
    z-index: 15;
  }
  #element-1192 {
    top: 3.125rem;
    left: 47.25rem;
    height: 4rem;
    width: 12.75rem;
    z-index: 9;
  }
  #element-1408 {
    top: 7.5625rem;
    left: 0;
    height: 4.125rem;
    width: 9.125rem;
    z-index: 17;
  }
  #element-747 {
    top: 7.5625rem;
    left: 23.5625rem;
    height: 7.125rem;
    width: 36.5rem;
    z-index: 4;
    color: #37465a;
    font-size: 5.8824rem;
    line-height: 7.125rem;
    text-align: center;
    font-weight: 700;
  }
  #element-747 .x_e6edcaa1 {
    text-align: center;
    line-height: 7.125rem;
    font-size: 5.8824rem;
  }
  #element-747 .x_74f0b483 {
    color: #057175;
  }
  #element-747 strong {
    font-weight: 700;
  }
  #element-747.headline {
    font-weight: 700;
  }
  #element-1402 {
    top: 8.9375rem;
    left: 0;
    height: 31.5rem;
    width: 38.25rem;
    z-index: 16;
  }
  #element-1407 {
    top: 13.5625rem;
    left: 15.875rem;
    height: 7.875rem;
    width: 17.125rem;
    z-index: 13;
  }
  #element-1182 {
    top: 16.1875rem;
    left: 34.5625rem;
    height: 2.625rem;
    width: 25.8125rem;
    z-index: 8;
    color: #37465a;
    font-size: 2.1672rem;
    line-height: 2.625rem;
    text-align: center;
    font-weight: 400;
  }
  #element-1182 .x_3a6971f3 {
    text-align: center;
    line-height: 2.625rem;
    font-size: 2.1672rem;
  }
  #element-1182 .x_69c6632a {
    color: #e30613;
  }
  #element-1182 strong {
    font-weight: 700;
  }
  #element-1182.headline {
    font-weight: 400;
  }
  #element-758 {
    top: 20.6875rem;
    left: 29.8125rem;
    height: 3.125rem;
    width: 30.125rem;
    z-index: 5;
    color: #37465a;
    font-size: 0.9288rem;
    line-height: 1.5rem;
    text-align: right;
  }
  #element-758 .x_45d83595 {
    text-align: right;
    line-height: 1.5625rem;
    font-size: 0.9576rem;
  }
  #element-758 .x_74f0b483 {
    color: #057175;
  }
  #element-1653 {
    top: 25.1875rem;
    left: 42.375rem;
    height: 4.4375rem;
    width: 17.5625rem;
    z-index: 126;
    font-size: 4.4375rem;
  }
  .timer-box {
    font-size: 0.6em;
  }
  .timer-date {
    height: auto;
  }
  #element-1653 .timer-box {
    color: rgb(255, 255, 255);
    background-color: #37465a;
  }
  #element-1653 .timer-labels {
    color: #37465a;
  }
  #element-1414 {
    top: 38.4375rem;
    left: -22.875rem;
    height: 2.125rem;
    width: 48.125rem;
    z-index: 14;
  }
  #page-block-cfk8nv8rlco {
    height: 34.25rem;
    max-width: 100%;
  }
  #page-block-cfk8nv8rlco .section-holder-border {
    border: 0;
  }
  #page-block-cfk8nv8rlco .section-block {
    background: url(//v.fastcdn.co/u/4126587d/61395214-0-new-Landing-page-cle.png)
      repeat rgb(60, 60, 59) 51% 23% / cover;
    height: 34.25rem;
  }
  #page-block-cfk8nv8rlco .section-holder-overlay {
    display: none;
  }
  #element-1411 {
    top: 6.8125rem;
    left: 5.375rem;
    height: 16.625rem;
    width: 10.875rem;
    z-index: 21;
  }
  #element-918 {
    top: 7.875rem;
    left: 26.5rem;
    height: 4.625rem;
    width: 28rem;
    z-index: 19;
    color: #37465a;
    font-size: 3.839rem;
    line-height: 4.65rem;
    text-align: center;
    font-weight: 400;
  }
  #element-918 .x_b13f30bc {
    text-align: center;
    line-height: 4.625rem;
    font-size: 3.8533rem;
  }
  #element-918 .x_f2074b6c {
    color: #ffffff;
  }
  #element-918 strong {
    font-weight: 700;
  }
  #element-918.headline {
    font-weight: 400;
  }
  #element-1410 {
    top: 13.0625rem;
    left: 26.5rem;
    height: 4.625rem;
    width: 28rem;
    z-index: 20;
    color: #37465a;
    font-size: 3.839rem;
    line-height: 4.65rem;
    text-align: center;
    font-weight: 700;
  }
  #element-1410 .x_b13f30bc {
    text-align: center;
    line-height: 4.625rem;
    font-size: 3.8533rem;
  }
  #element-1410 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1410 strong {
    font-weight: 700;
  }
  #element-1410.headline {
    font-weight: 700;
  }
  #element-1413 {
    top: 13.75rem;
    left: 0;
    height: 12.875rem;
    z-index: 23;
  }
  #element-1412 {
    top: 18.9375rem;
    left: 13.5625rem;
    height: 8.625rem;
    width: 5.875rem;
    z-index: 22;
  }
  #element-917 {
    top: 19.5rem;
    left: 21.1875rem;
    height: 6.75rem;
    width: 38.625rem;
    z-index: 18;
    color: #37465a;
    font-size: 1.0526rem;
    line-height: 1.7rem;
    text-align: center;
    font-weight: 300;
  }
  #element-917 .x_7a88b5a4 {
    text-align: center;
    line-height: 1.6875rem;
    font-size: 1.0526rem;
  }
  #element-917 .x_f2074b6c {
    color: #ffffff;
  }
  #element-917 strong {
    font-weight: 700;
  }
  #element-917.paragraph {
    font-weight: 300;
  }
  #page-block-qkt1x4jq60i {
    height: 53.75rem;
    max-width: 100%;
  }
  #page-block-qkt1x4jq60i .section-holder-border {
    border: 0;
  }
  #page-block-qkt1x4jq60i .section-block {
    background: url(//v.fastcdn.co/u/4126587d/61406299-0-Artboard-1.png) repeat
      rgb(245, 255, 255) 50% 50% / cover;
    height: 53.75rem;
  }
  #page-block-qkt1x4jq60i .section-holder-overlay {
    display: none;
  }
  #element-988 {
    top: 4.375rem;
    left: 14.5rem;
    height: 3.5rem;
    width: 31.25rem;
    z-index: 10;
    color: #37465a;
    font-size: 2.9102rem;
    line-height: 3.525rem;
    text-align: center;
  }
  #element-988 .x_906b70a2 {
    text-align: center;
    line-height: 3.5rem;
    font-size: 2.9102rem;
  }
  #element-988 .x_69c6632a {
    color: #e30613;
  }
  #element-1560 {
    top: 12.6875rem;
    left: 3.125rem;
    height: 8.875rem;
    width: 8.875rem;
    z-index: 108;
  }
  #element-1592 {
    top: 12.6875rem;
    left: 18.0625rem;
    height: 8.875rem;
    width: 8.875rem;
    z-index: 104;
  }
  #element-991 {
    top: 22.4375rem;
    left: 13.8125rem;
    height: 1.375rem;
    width: 17.5rem;
    z-index: 103;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.35rem;
    text-align: center;
  }
  #element-991 .x_efd3fa39 {
    text-align: center;
    line-height: 1.375rem;
    font-size: 1.1146rem;
  }
  #element-989 {
    top: 23.8125rem;
    left: 13.8125rem;
    height: 1.625rem;
    width: 17.5rem;
    z-index: 102;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: center;
  }
  #element-989 .x_dc68832a {
    text-align: center;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-1623 {
    top: 26.0625rem;
    left: 20.8125rem;
    height: 1.125rem;
    width: 3.5rem;
    z-index: 105;
  }
  #element-1593 {
    top: 12.6875rem;
    left: 32.6875rem;
    height: 8.875rem;
    width: 8.9375rem;
    z-index: 100;
  }
  #element-1594 {
    top: 12.6875rem;
    left: 47.5625rem;
    height: 8.875rem;
    width: 8.875rem;
    z-index: 96;
  }
  #element-986 {
    top: 22.375rem;
    left: -1.0625rem;
    height: 1.375rem;
    width: 17.375rem;
    z-index: 106;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.35rem;
    text-align: center;
  }
  #element-986 .x_efd3fa39 {
    text-align: center;
    line-height: 1.375rem;
    font-size: 1.1146rem;
  }
  #element-986 .x_a269ab78 {
    color: #37465a;
  }
  #element-1572 {
    top: 22.375rem;
    left: 43.3125rem;
    height: 1.375rem;
    width: 17.5rem;
    z-index: 95;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.35rem;
    text-align: center;
  }
  #element-1572 .x_efd3fa39 {
    text-align: center;
    line-height: 1.375rem;
    font-size: 1.1146rem;
  }
  #element-1576 {
    top: 22.4375rem;
    left: 28.4375rem;
    height: 1.375rem;
    width: 17.375rem;
    z-index: 98;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.35rem;
    text-align: center;
  }
  #element-1576 .x_efd3fa39 {
    text-align: center;
    line-height: 1.375rem;
    font-size: 1.1146rem;
  }
  #element-995 {
    top: 23.75rem;
    left: -1.0625rem;
    height: 1.625rem;
    width: 17.375rem;
    z-index: 107;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: center;
  }
  #element-995 .x_dc68832a {
    text-align: center;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-1570 {
    top: 23.75rem;
    left: 43.3125rem;
    height: 1.625rem;
    width: 17.5rem;
    z-index: 94;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: center;
  }
  #element-1570 .x_dc68832a {
    text-align: center;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-1578 {
    top: 23.8125rem;
    left: 28.4375rem;
    height: 1.625rem;
    width: 17.375rem;
    z-index: 99;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: center;
  }
  #element-1578 .x_dc68832a {
    text-align: center;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-1624 {
    top: 26rem;
    left: 5.8125rem;
    height: 1.125rem;
    width: 3.5rem;
    z-index: 109;
  }
  #element-1621 {
    top: 26rem;
    left: 50.25rem;
    height: 1.125rem;
    width: 3.5rem;
    z-index: 97;
  }
  #element-1622 {
    top: 26.0625rem;
    left: 35.375rem;
    height: 1.125rem;
    width: 3.5rem;
    z-index: 101;
  }
  #element-1618 {
    top: 30.5rem;
    left: 2.9375rem;
    height: 9rem;
    width: 9rem;
    z-index: 124;
  }
  #element-1618 .cropped {
    background: url(//v.fastcdn.co/u/4126587d/61472750-0-pics-frame-01.png) -0.0004rem -0.0004rem /
      9rem 9rem;
  }
  #element-1597 {
    top: 30.625rem;
    left: 17.875rem;
    height: 9rem;
    width: 9rem;
    z-index: 120;
  }
  #element-1598 {
    top: 30.625rem;
    left: 32.875rem;
    height: 9rem;
    width: 9rem;
    z-index: 116;
  }
  #element-1599 {
    top: 30.625rem;
    left: 47.5625rem;
    height: 9rem;
    width: 9rem;
    z-index: 112;
  }
  #element-1614 {
    top: 40.5625rem;
    left: -1.25rem;
    height: 1.375rem;
    width: 17.75rem;
    z-index: 122;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.35rem;
    text-align: center;
  }
  #element-1614 .x_efd3fa39 {
    text-align: center;
    line-height: 1.375rem;
    font-size: 1.1146rem;
  }
  #element-1234 {
    top: 40.625rem;
    left: 13.5rem;
    height: 1.375rem;
    width: 17.75rem;
    z-index: 118;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.35rem;
    text-align: center;
  }
  #element-1234 .x_efd3fa39 {
    text-align: center;
    line-height: 1.375rem;
    font-size: 1.1146rem;
  }
  #element-1580 {
    top: 40.625rem;
    left: 28.5rem;
    height: 1.375rem;
    width: 17.75rem;
    z-index: 114;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.35rem;
    text-align: center;
  }
  #element-1580 .x_efd3fa39 {
    text-align: center;
    line-height: 1.375rem;
    font-size: 1.1146rem;
  }
  #element-1566 {
    top: 40.625rem;
    left: 43.3125rem;
    height: 1.375rem;
    width: 17.375rem;
    z-index: 110;
    color: #37465a;
    font-size: 1.1146rem;
    line-height: 1.35rem;
    text-align: center;
  }
  #element-1566 .x_efd3fa39 {
    text-align: center;
    line-height: 1.375rem;
    font-size: 1.1146rem;
  }
  #element-1616 {
    top: 42.3125rem;
    left: -1.1875rem;
    height: 1.625rem;
    width: 17.5rem;
    z-index: 123;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: center;
  }
  #element-1616 .x_dc68832a {
    text-align: center;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-1264 {
    top: 42.375rem;
    left: 13.5625rem;
    height: 1.625rem;
    width: 17.5rem;
    z-index: 119;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: center;
  }
  #element-1264 .x_dc68832a {
    text-align: center;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-1582 {
    top: 42.375rem;
    left: 28.5625rem;
    height: 1.625rem;
    width: 17.5rem;
    z-index: 115;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: center;
  }
  #element-1582 .x_dc68832a {
    text-align: center;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-1568 {
    top: 42.375rem;
    left: 43.3125rem;
    height: 1.625rem;
    width: 17.375rem;
    z-index: 111;
    color: #37465a;
    font-size: 0.9907rem;
    line-height: 1.6rem;
    text-align: center;
  }
  #element-1568 .x_dc68832a {
    text-align: center;
    line-height: 1.625rem;
    font-size: 0.9907rem;
  }
  #element-1634 {
    top: 44.625rem;
    left: 5.6875rem;
    height: 1.125rem;
    width: 3.5rem;
    z-index: 125;
  }
  #element-1632 {
    top: 44.625rem;
    left: 20.625rem;
    height: 1.125rem;
    width: 3.5rem;
    z-index: 121;
  }
  #element-1630 {
    top: 44.625rem;
    left: 35.625rem;
    height: 1.125rem;
    width: 3.5rem;
    z-index: 117;
  }
  #element-1628 {
    top: 44.625rem;
    left: 50.3125rem;
    height: 1.125rem;
    width: 3.5rem;
    z-index: 113;
  }
  #page-block-ggdycfzqc7s {
    height: 198rem;
    max-width: 100%;
  }
  #page-block-ggdycfzqc7s .section-holder-border {
    border: 0;
  }
  #page-block-ggdycfzqc7s .section-block {
    background: rgb(242, 242, 242);
    height: 198rem;
  }
  #page-block-ggdycfzqc7s .section-holder-overlay {
    display: none;
  }
  #element-1158 {
    top: 8.5625rem;
    left: 21.875rem;
    height: 3.0625rem;
    width: 15rem;
    z-index: 11;
    color: #37465a;
    font-size: 2.5387rem;
    line-height: 3.075rem;
    text-align: left;
    font-weight: 400;
  }
  #element-1158 .x_4099d1b7 {
    text-align: center;
    line-height: 3.0625rem;
    font-size: 2.5387rem;
  }
  #element-1158 .x_74f0b483 {
    color: #057175;
  }
  #element-1158 strong {
    font-weight: 700;
  }
  #element-1158.headline {
    font-weight: 400;
  }
  #element-1266 {
    top: 12.5rem;
    left: 12.6875rem;
    height: 8.1875rem;
    width: 33.375rem;
    z-index: 12;
    color: #37465a;
    font-size: 6.7492rem;
    line-height: 8.175rem;
    text-align: left;
  }
  #element-1266 .x_b2d32610 {
    text-align: center;
    line-height: 8.1875rem;
    font-size: 6.7492rem;
  }
  #element-1266 .x_69c6632a {
    color: #e30613;
  }
  #element-1417 {
    top: 23.5625rem;
    left: -11.125rem;
    height: 29.375rem;
    width: 26rem;
    z-index: 43;
  }
  #element-1418 {
    top: 26.6875rem;
    left: 27.1875rem;
    height: 4.25rem;
    width: 4.875rem;
    z-index: 44;
  }
  #element-1272 {
    top: 32.625rem;
    left: 12.4375rem;
    height: 5.625rem;
    width: 34.375rem;
    z-index: 26;
    color: #37465a;
    font-size: 1.9814rem;
    line-height: 2.8rem;
    text-align: center;
  }
  #element-1272 .x_e8f3f92d {
    text-align: center;
    line-height: 2.8125rem;
    font-size: 1.9856rem;
  }
  #element-1272 .x_a7fe84b4 {
    color: #0098a5;
  }
  #element-1667 {
    top: 32.6875rem;
    left: 18.125rem;
    height: 13.625rem;
    width: 21.75rem;
    z-index: 145;
  }
  #element-1668 {
    top: 51.9rem;
    left: 18.125rem;
    height: 13.625rem;
    width: 21.75rem;
    z-index: 145;
  }
  #element-1669 {
    top: 71.9rem;
    left: 18.125rem;
    height: 13.625rem;
    width: 21.75rem;
    z-index: 145;
  }
  #element-1670 {
    top: 89.9rem;
    left: 18.125rem;
    height: 13.625rem;
    width: 21.75rem;
    z-index: 145;
  }
  #element-1671 {
    top: 114.9rem;
    left: 18.125rem;
    height: 13.625rem;
    width: 21.75rem;
    z-index: 145;
  }
  #element-1274 {
    top: 42.4375rem;
    left: -0.0625rem;
    height: 2rem;
    width: 59.625rem;
    z-index: 27;
    color: #37465a;
    font-size: 1.2384rem;
    line-height: 2rem;
    text-align: center;
  }
  #element-1274 .x_05f58128 {
    text-align: center;
    line-height: 2rem;
    font-size: 1.241rem;
  }
  #element-1274 .x_403c9345 {
    color: #151d2d;
  }
  #element-1326 {
    top: 46.25rem;
    left: 25rem;
    height: 1.625rem;
    width: 3.625rem;
    z-index: 35;
  }
  #element-1326 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(142, 214, 216);
  }
  #element-1322 {
    top: 46.25rem;
    left: 29rem;
    height: 1.625rem;
    width: 3.625rem;
    z-index: 32;
  }
  #element-1322 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(205, 205, 205);
  }
  #element-1320 {
    top: 46.25rem;
    left: 32.8125rem;
    height: 1.625rem;
    width: 1.625rem;
    z-index: 31;
  }
  #element-1320 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(205, 205, 205);
  }
  #element-1318 {
    top: 46.3125rem;
    left: 30rem;
    height: 1.3125rem;
    width: 2rem;
    z-index: 33;
    color: #37465a;
    font-size: 0.9288rem;
    line-height: 1.3125rem;
    text-align: left;
  }
  #element-1318 .x_a84c1223 {
    text-align: left;
    line-height: 1.3125rem;
    font-size: 0.9072rem;
  }
  #element-1318 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1321 {
    top: 46.3125rem;
    left: 32.875rem;
    height: 1.3125rem;
    width: 1.25rem;
    z-index: 34;
    color: #37465a;
    font-size: 0.9288rem;
    line-height: 1.3125rem;
    text-align: left;
  }
  #element-1321 .x_a84c1223 {
    text-align: left;
    line-height: 1.3125rem;
    font-size: 0.9072rem;
  }
  #element-1321 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1328 {
    top: 46.4375rem;
    left: 25.625rem;
    height: 1.125rem;
    width: 2.5rem;
    z-index: 36;
    color: #37465a;
    font-size: 0.805rem;
    line-height: 1.1375rem;
    text-align: left;
  }
  #element-1328 .x_ff17dd85 {
    text-align: left;
    line-height: 1.125rem;
    font-size: 0.805rem;
  }
  #element-1328 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1296 {
    top: 48.6875rem;
    left: -0.125rem;
    height: 1.4375rem;
    width: 59.875rem;
    z-index: 28;
  }
  #element-1296 .shape {
    border-bottom: 3px dotted #d2d2d2;
  }
  #element-1660 {
    top: 51.25rem;
    left: 27.25rem;
    height: 3.25rem;
    width: 6.625rem;
    z-index: 135;
  }
  #element-1161 {
    top: 56rem;
    height: 2.8125rem;
    width: 58.3125rem;
    z-index: 127;
    color: #37465a;
    font-size: 1.9814rem;
    line-height: 2.8rem;
    text-align: left;
  }
  .contents {
    display: flex;
    justify-content: center;
  }
  #element-1161 .x_0249e598 {
    text-align: left;
    line-height: 2.8125rem;
    font-size: 1.9814rem;
  }
  #element-1161 .x_a7fe84b4 {
    color: #0098a5;
  }
  #element-1161 .x_752e43b0 {
    text-align: left;
    caret-color: rgb(0, 152, 165);
  }
  #element-1533 {
    top: 60rem;
    left: 0.4375rem;
    height: 6rem;
    width: 60rem;
    z-index: 133;
    color: #37465a;
    font-size: 1.2384rem;
    line-height: 2rem;
    text-align: center;
  }
  #element-1533 .x_9480cd66 {
    text-align: center;
    line-height: 2rem;
    font-size: 1.241rem;
  }
  #element-1533 .x_403c9345 {
    color: #151d2d;
  }
  #element-1342 {
    top: 65.5rem;
    left: 25.6875rem;
    height: 1.625rem;
    width: 3.75rem;
    z-index: 131;
  }
  #element-1342 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(142, 214, 216);
  }
  #element-1336 {
    top: 65.5rem;
    left: 29.8125rem;
    height: 1.625rem;
    width: 3.75rem;
    z-index: 129;
  }
  #element-1336 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(205, 205, 205);
  }
  #element-1635 {
    top: 65.5rem;
    left: 30.75rem;
    height: 1.3125rem;
    width: 2rem;
    z-index: 134;
    color: #37465a;
    font-size: 0.9288rem;
    line-height: 1.3125rem;
    text-align: left;
  }
  #element-1635 .x_a84c1223 {
    text-align: left;
    line-height: 1.3125rem;
    font-size: 0.9072rem;
  }
  #element-1635 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1333 {
    top: 65.5rem;
    left: 33.8125rem;
    height: 1.625rem;
    width: 1.5rem;
    z-index: 128;
  }
  #element-1333 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(205, 205, 205);
  }
  #element-1340 {
    top: 65.5625rem;
    left: 34rem;
    height: 1.3125rem;
    width: 1.375rem;
    z-index: 130;
    color: #37465a;
    font-size: 0.9288rem;
    line-height: 1.3125rem;
    text-align: left;
  }
  #element-1340 .x_a84c1223 {
    text-align: left;
    line-height: 1.3125rem;
    font-size: 0.9072rem;
  }
  #element-1340 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1344 {
    top: 65.6875rem;
    left: 26.375rem;
    height: 1.125rem;
    width: 3.375rem;
    z-index: 132;
    color: #37465a;
    font-size: 0.805rem;
    line-height: 1.1375rem;
    text-align: left;
  }
  #element-1344 .x_ff17dd85 {
    text-align: left;
    line-height: 1.125rem;
    font-size: 0.805rem;
  }
  #element-1344 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1298 {
    top: 68.6875rem;
    left: -0.1875rem;
    height: 1.4375rem;
    width: 60rem;
    z-index: 29;
  }
  #element-129L274 .shape {
    border-bottom: 3px dotted #d2d2d2;
  }
  #element-1297 {
    top: 49.3125rem;
    left: 0.0625rem;
    height: 1.4375rem;
    width: 100%;
    z-index: 29;
  }
  #element-1298 .shape {
    border-bottom: 3px dotted #d2d2d2;
  }
  #element-1512 {
    top: 71.25rem;
    left: 27.5rem;
    height: 4.125rem;
    width: 4.375rem;
    z-index: 76;
  }
  #element-1169 {
    top: 76.25rem;
    left: 8.125rem;
    height: 2.875rem;
    width: 43.125rem;
    z-index: 25;
    color: #37465a;
    font-size: 2.0433rem;
    line-height: 2.8875rem;
    text-align: center;
  }
  #element-1169 .x_07f466dd {
    text-align: center;
    line-height: 2.875rem;
    font-size: 2.0433rem;
  }
  #element-1169 .x_a7fe84b4 {
    color: #0098a5;
  }
  #element-1534 {
    top: 79.75rem;
    left: -0.1875rem;
    height: 4rem;
    width: 60rem;
    z-index: 84;
    color: #37465a;
    font-size: 1.2384rem;
    line-height: 2rem;
    text-align: center;
  }
  #element-1534 .x_05f58128 {
    text-align: center;
    line-height: 2rem;
    font-size: 1.241rem;
  }
  #element-1534 .x_403c9345 {
    color: #151d2d;
  }
  #element-1384 {
    top: 83.5rem;
    left: 24.9375rem;
    height: 1.625rem;
    width: 3.5rem;
    z-index: 41;
  }
  #element-1384 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(142, 214, 216);
  }
  #element-1378 {
    top: 83.5rem;
    left: 28.9375rem;
    height: 1.625rem;
    width: 3.75rem;
    z-index: 38;
  }
  #element-1378 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(205, 205, 205);
  }
  #element-1380 {
    top: 83.5rem;
    left: 29.75rem;
    height: 1.3125rem;
    width: 2.25rem;
    z-index: 39;
    color: #37465a;
    font-size: 0.9288rem;
    line-height: 1.3125rem;
    text-align: left;
  }
  #element-1380 .x_a84c1223 {
    text-align: left;
    line-height: 1.3125rem;
    font-size: 0.9072rem;
  }
  #element-1380 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1376 {
    top: 83.5rem;
    left: 33rem;
    height: 1.625rem;
    width: 1.5rem;
    z-index: 37;
  }
  #element-1376 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(205, 205, 205);
  }
  #element-1382 {
    top: 83.5625rem;
    left: 33.5rem;
    height: 1.3125rem;
    width: 0.6875rem;
    z-index: 40;
    color: #37465a;
    font-size: 0.9288rem;
    line-height: 1.3125rem;
    text-align: left;
  }
  #element-1382 .x_a84c1223 {
    text-align: left;
    line-height: 1.3125rem;
    font-size: 0.9072rem;
  }
  #element-1382 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1386 {
    top: 83.6875rem;
    left: 25.4375rem;
    height: 1.125rem;
    width: 2.625rem;
    z-index: 42;
    color: #37465a;
    font-size: 0.805rem;
    line-height: 1.1375rem;
    text-align: left;
  }
  #element-1386 .x_ff17dd85 {
    text-align: left;
    line-height: 1.125rem;
    font-size: 0.805rem;
  }
  #element-1386 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1300 {
    top: 86.5625rem;
    left: -0.1875rem;
    height: 1.4375rem;
    width: 60rem;
    z-index: 30;
  }
  #element-1300 .shape {
    border-bottom: 3px dotted #d2d2d2;
  }
  #element-1700 {
    top: 86.5625rem;
    left: -0.1875rem;
    height: 1.4375rem;
    width: 60rem;
    z-index: 30;
  }
  #element-1700 .shape {
    border-bottom: 3px dotted #d2d2d2;
  }
  #element-1661 {
    top: 88.6875rem;
    left: 27.125rem;
    height: 3.625rem;
    width: 5.625rem;
    z-index: 143;
  }
  #element-1172 {
    top: 92.875rem;
    left: -0.375rem;
    height: 9.375rem;
    width: 60.125rem;
    z-index: 136;
    color: #37465a;
    font-size: 2.1672rem;
    line-height: 3.0625rem;
    text-align: center;
  }
  #element-1172 .x_ce3582e7 {
    text-align: center;
    line-height: 3.125rem;
    font-size: 2.1931rem;
  }
  #element-1172 .x_a7fe84b4 {
    color: #0098a5;
  }
  #element-1172 .x_752e43b0 {
    text-align: left;
    caret-color: rgb(0, 152, 165);
  }
  #element-1535 {
    top: 97.5625rem;
    left: -0.3125rem;
    height: 6rem;
    width: 60rem;
    z-index: 142;
    color: #37465a;
    font-size: 1.2384rem;
    line-height: 2rem;
    text-align: center;
  }
  #element-1535 .x_9480cd66 {
    text-align: center;
    line-height: 2rem;
    font-size: 1.241rem;
  }
  #element-1535 .x_403c9345 {
    color: #151d2d;
  }
  #element-1370 {
    top: 104.125rem;
    left: 25.125rem;
    height: 1.625rem;
    width: 3.375rem;
    z-index: 140;
  }
  #element-1370 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(142, 214, 216);
  }
  #element-1364 {
    top: 104.125rem;
    left: 29rem;
    height: 1.625rem;
    width: 3.5rem;
    z-index: 138;
  }
  #element-1364 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(205, 205, 205);
  }
  #element-1366 {
    top: 104.125rem;
    left: 29.625rem;
    height: 1.3125rem;
    width: 2.25rem;
    z-index: 139;
    color: #37465a;
    font-size: 0.9288rem;
    line-height: 1.3125rem;
    text-align: left;
  }
  #element-1366 .x_a84c1223 {
    text-align: left;
    line-height: 1.3125rem;
    font-size: 0.9072rem;
  }
  #element-1366 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1361 {
    top: 104.125rem;
    left: 32.9375rem;
    height: 1.625rem;
    width: 2.1875rem;
    z-index: 137;
  }
  #element-1361 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(205, 205, 205);
  }
  #element-1372 {
    top: 104.25rem;
    left: 25.5625rem;
    height: 1.125rem;
    width: 3.5rem;
    z-index: 141;
    color: #37465a;
    font-size: 0.805rem;
    line-height: 1.1375rem;
    text-align: left;
  }
  #element-1372 .x_ff17dd85 {
    text-align: left;
    line-height: 1.125rem;
    font-size: 0.805rem;
  }
  #element-1372 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1662 {
    top: 104.25rem;
    left: 33.5rem;
    height: 1.3125rem;
    width: 1.625rem;
    z-index: 144;
    color: #37465a;
    font-size: 0.9288rem;
    line-height: 1.3125rem;
    text-align: left;
  }
  #element-1662 .x_727f2cc2 {
    text-align: left;
    line-height: 1.3125rem;
    font-size: 0.9072rem;
  }
  #element-1662 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1419 {
    top: 106.5625rem;
    left: -0.125rem;
    height: 1.4375rem;
    width: 60rem;
    z-index: 45;
  }
  #element-1419 .shape {
    border-bottom: 3px dotted #d2d2d2;
  }
  #element-1518 {
    top: 109.6875rem;
    left: 26rem;
    height: 4.625rem;
    width: 7.375rem;
    z-index: 77;
  }
  #element-1427 {
    top: 116.0625rem;
    left: 8.9375rem;
    height: 6.25rem;
    width: 42.125rem;
    z-index: 46;
    color: #37465a;
    font-size: 2.1672rem;
    line-height: 3.0625rem;
    text-align: center;
  }
  #element-1427 .x_2ff4a83b {
    text-align: center;
    line-height: 3.125rem;
    font-size: 2.1931rem;
  }
  #element-1427 .x_a7fe84b4 {
    color: #0098a5;
  }
  #element-1536 {
    top: 122.1875rem;
    left: -0.1875rem;
    height: 4rem;
    width: 60rem;
    z-index: 85;
    color: #37465a;
    font-size: 1.2384rem;
    line-height: 2rem;
    text-align: center;
    direction: rtl;
  }
  #element-1536 .x_05f58128 {
    text-align: center;
    line-height: 2rem;
    font-size: 1.241rem;
  }
  #element-1536 .x_403c9345 {
    color: #151d2d;
  }
  #element-1439 {
    top: 128.875rem;
    left: 25.125rem;
    height: 1.625rem;
    width: 3.375rem;
    z-index: 51;
  }
  #element-1439 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(142, 214, 216);
  }
  #element-1433 {
    top: 128.875rem;
    left: 29rem;
    height: 1.625rem;
    width: 3.5rem;
    z-index: 48;
  }
  #element-1433 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(205, 205, 205);
  }
  #element-1435 {
    top: 128.875rem;
    left: 29.625rem;
    height: 1.3125rem;
    width: 2.25rem;
    z-index: 49;
    color: #37465a;
    font-size: 0.9288rem;
    line-height: 1.3125rem;
    text-align: left;
  }
  #element-1435 .x_a84c1223 {
    text-align: left;
    line-height: 1.3125rem;
    font-size: 0.9072rem;
  }
  #element-1435 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1431 {
    top: 128.875rem;
    left: 32.8125rem;
    height: 1.625rem;
    width: 1.5rem;
    z-index: 47;
  }
  #element-1431 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(205, 205, 205);
  }
  #element-1441 {
    top: 129rem;
    left: 25rem;
    height: 1.125rem;
    width: 3.5rem;
    z-index: 52;
    color: #37465a;
    font-size: 0.805rem;
    line-height: 1.1375rem;
    text-align: left;
  }
  #element-1441 .x_ff17dd85 {
    text-align: left;
    line-height: 1.125rem;
    font-size: 0.805rem;
  }
  #element-1441 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1437 {
    top: 129rem;
    left: 33rem;
    height: 1.3125rem;
    width: 1.125rem;
    z-index: 50;
    color: #37465a;
    font-size: 0.9288rem;
    line-height: 1.3125rem;
    text-align: left;
  }
  #element-1437 .x_a84c1223 {
    text-align: left;
    line-height: 1.3125rem;
    font-size: 0.9072rem;
  }
  #element-1437 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1442 {
    top: 131.625rem;
    left: -0.125rem;
    height: 1.4375rem;
    width: 60rem;
    z-index: 53;
  }
  #element-1442 .shape {
    border-bottom: 3px dotted #d2d2d2;
  }
  #element-1520 {
    top: 134.8125rem;
    left: 26.75rem;
    height: 3.875rem;
    width: 5.875rem;
    z-index: 78;
  }
  #element-1522 {
    top: 139.3125rem;
    height: 3.125rem;
    width: 100%;
    z-index: 81;
    color: #37465a;
    font-size: 2.1672rem;
    line-height: 3.0625rem;
    text-align: center;
  }
  #element-1522 .x_2ff4a83b {
    text-align: center;
    line-height: 3.125rem;
    font-size: 2.1931rem;
  }
  #element-1522 .x_a7fe84b4 {
    color: #0098a5;
  }
  #element-1521 {
    top: 140.3125rem;
    left: 31.5625rem;
    height: 3.125rem;
    width: 11.25rem;
    z-index: 80;
    color: #37465a;
    font-size: 2.1672rem;
    line-height: 3.0625rem;
    text-align: center;
  }
  #element-1521 .x_2ff4a83b {
    text-align: center;
    line-height: 3.125rem;
    font-size: 2.1931rem;
  }
  #element-1521 .x_a7fe84b4 {
    color: #0098a5;
  }
  #element-1449 {
    top: 140.3125rem;
    left: 43.3125rem;
    height: 3.125rem;
    width: 10.5rem;
    z-index: 79;
    color: #37465a;
    font-size: 2.1672rem;
    line-height: 3.0625rem;
    text-align: center;
  }
  #element-1449 .x_2ff4a83b {
    text-align: center;
    line-height: 3.125rem;
    font-size: 2.1931rem;
  }
  #element-1449 .x_a7fe84b4 {
    color: #0098a5;
  }
  #element-1557 {
    top: 145.9375rem;
    height: 2rem;
    width: 100%;
    z-index: 92;
    color: #37465a;
    font-size: 1.2384rem;
    line-height: 2rem;
    text-align: center;
  }
  #element-1557 .x_05f58128 {
    text-align: center;
    line-height: 2rem;
    font-size: 1.241rem;
  }
  #element-1557 .x_403c9345 {
    color: #151d2d;
  }
  #element-1539 {
    top: 143.9375rem;
    height: 2rem;
    width: 13.5rem;
    z-index: 88;
    color: #37465a;
    font-size: 1.2384rem;
    line-height: 2rem;
    text-align: center;
  }
  #element-1539 .x_05f58128 {
    text-align: center;
    line-height: 2rem;
    font-size: 1.241rem;
  }
  #element-1539 .x_403c9345 {
    color: #151d2d;
  }
  #element-1538 {
    top: 143.9375rem;
    height: 2rem;
    width: 100%;
    z-index: 87;
    color: #37465a;
    font-size: 1.2384rem;
    line-height: 2rem;
    text-align: center;
  }
  #element-1538 .x_05f58128 {
    text-align: center;
    line-height: 2rem;
    font-size: 1.241rem;
  }
  #element-1538 .x_403c9345 {
    color: #151d2d;
  }
  #element-1537 {
    top: 143.9375rem;
    height: 2rem;
    width: 100%;
    z-index: 86;
    color: #37465a;
    font-size: 1.2384rem;
    line-height: 2rem;
    text-align: center;
  }
  #element-1537 .x_05f58128 {
    text-align: center;
    line-height: 2rem;
    font-size: 1.241rem;
  }
  #element-1537 .x_403c9345 {
    color: #151d2d;
  }
  #element-1558 {
    top: 145.9375rem;
    left: 17.375rem;
    height: 2rem;
    width: 13.75rem;
    z-index: 93;
    color: #37465a;
    font-size: 1.2384rem;
    line-height: 2rem;
    text-align: center;
  }
  #element-1558 .x_05f58128 {
    text-align: center;
    line-height: 2rem;
    font-size: 1.241rem;
  }
  #element-1558 .x_403c9345 {
    color: #151d2d;
  }
  #element-1541 {
    top: 145.9375rem;
    left: 31.3125rem;
    height: 2rem;
    width: 10.625rem;
    z-index: 89;
    color: #37465a;
    font-size: 1.2384rem;
    line-height: 2rem;
    text-align: center;
  }
  #element-1541 .x_05f58128 {
    text-align: center;
    line-height: 2rem;
    font-size: 1.241rem;
  }
  #element-1541 .x_403c9345 {
    color: #151d2d;
  }
  #element-1461 {
    top: 150.4375rem;
    left: 25.1875rem;
    height: 1.5rem;
    width: 3.5rem;
    z-index: 58;
  }
  #element-1461 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(142, 214, 216);
  }
  #element-1455 {
    top: 150.4375rem;
    left: 29.0625rem;
    height: 1.5rem;
    width: 3.5rem;
    z-index: 55;
  }
  #element-1455 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(205, 205, 205);
  }
  #element-1457 {
    top: 150.4375rem;
    left: 28.8125rem;
    height: 1.3125rem;
    width: 4.25rem;
    z-index: 56;
    color: #37465a;
    font-size: 0.9288rem;
    line-height: 1.3125rem;
    text-align: left;
  }
  #element-1457 .x_a84c1223 {
    text-align: left;
    line-height: 1.3125rem;
    font-size: 0.9072rem;
  }
  #element-1457 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1453 {
    top: 150.4375rem;
    left: 32.875rem;
    height: 1.5rem;
    width: 1.5rem;
    z-index: 54;
  }
  #element-1453 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(205, 205, 205);
  }
  #element-1463 {
    top: 150.5625rem;
    left: 25.1rem;
    height: 1.125rem;
    width: 3.5rem;
    z-index: 59;
    color: #37465a;
    font-size: 0.805rem;
    line-height: 1.1375rem;
    text-align: left;
  }
  #element-1463 .x_ff17dd85 {
    text-align: left;
    line-height: 1.125rem;
    font-size: 0.805rem;
  }
  #element-1463 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1459 {
    top: 150.5625rem;
    left: 33rem;
    height: 1.3125rem;
    width: 1.25rem;
    z-index: 57;
    color: #37465a;
    font-size: 0.9288rem;
    line-height: 1.3125rem;
    text-align: left;
  }
  #element-1459 .x_a84c1223 {
    text-align: left;
    line-height: 1.3125rem;
    font-size: 0.9072rem;
  }
  #element-1459 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1464 {
    top: 153.375rem;
    left: -0.125rem;
    height: 1.4375rem;
    width: 60rem;
    z-index: 60;
  }
  #element-1464 .shape {
    border-bottom: 3px dotted #d2d2d2;
  }
  #element-1524 {
    top: 155.5625rem;
    left: 27.0625rem;
    height: 4.875rem;
    width: 5.25rem;
    z-index: 82;
  }
  #element-1471 {
    top: 161rem;
    left: 0.75rem;
    height: 3.125rem;
    width: 57.875rem;
    z-index: 61;
    color: #37465a;
    font-size: 2.1672rem;
    line-height: 3.0625rem;
    text-align: center;
  }
  #element-1471 .x_2ff4a83b {
    text-align: center;
    line-height: 3.125rem;
    font-size: 2.1931rem;
  }
  #element-1471 .x_a7fe84b4 {
    color: #0098a5;
  }
  #element-1543 {
    top: 165rem;
    left: -0.1875rem;
    height: 4rem;
    width: 60rem;
    z-index: 90;
    color: #37465a;
    font-size: 1.2384rem;
    line-height: 2rem;
    text-align: center;
  }
  #element-1543 .x_05f58128 {
    text-align: center;
    line-height: 2rem;
    font-size: 1.241rem;
  }
  #element-1543 .x_403c9345 {
    color: #151d2d;
  }
  #element-1483 {
    top: 170.875rem;
    left: 25.1875rem;
    height: 1.625rem;
    width: 3.375rem;
    z-index: 66;
  }
  #element-1483 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(142, 214, 216);
  }
  #element-1477 {
    top: 170.875rem;
    left: 29.0625rem;
    height: 1.625rem;
    width: 3.5rem;
    z-index: 63;
  }
  #element-1477 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(205, 205, 205);
  }
  #element-1475 {
    top: 170.875rem;
    left: 32.875rem;
    height: 1.625rem;
    width: 1.5rem;
    z-index: 62;
  }
  #element-1475 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(205, 205, 205);
  }
  #element-1485 {
    top: 171rem;
    left: 25.625rem;
    height: 1.125rem;
    width: 3.5rem;
    z-index: 67;
    color: #37465a;
    font-size: 0.805rem;
    line-height: 1.1375rem;
    text-align: left;
  }
  #element-1485 .x_ff17dd85 {
    text-align: left;
    line-height: 1.125rem;
    font-size: 0.805rem;
  }
  #element-1485 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1481 {
    top: 171rem;
    left: 33rem;
    height: 1.3125rem;
    width: 1.25rem;
    z-index: 65;
    color: #37465a;
    font-size: 0.9288rem;
    line-height: 1.3125rem;
    text-align: left;
  }
  #element-1481 .x_a84c1223 {
    text-align: left;
    line-height: 1.3125rem;
    font-size: 0.9072rem;
  }
  #element-1481 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1479 {
    top: 171.0625rem;
    left: 29.3125rem;
    height: 1.0625rem;
    width: 4rem;
    z-index: 64;
    color: #37465a;
    font-size: 0.743rem;
    line-height: 1.05rem;
    text-align: left;
  }
  #element-1479 .x_3206b8d5 {
    text-align: left;
    line-height: 1.0625rem;
    font-size: 0.743rem;
  }
  #element-1479 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1486 {
    top: 174.1875rem;
    left: -0.125rem;
    height: 1.4375rem;
    width: 60rem;
    z-index: 68;
  }
  #element-1486 .shape {
    border-bottom: 3px dotted #d2d2d2;
  }
  #element-1525 {
    top: 177.25rem;
    left: 26.3125rem;
    height: 3.625rem;
    width: 6.75rem;
    z-index: 83;
  }
  #element-1493 {
    top: 181.75rem;
    left: -0.1875rem;
    height: 3.125rem;
    width: 60rem;
    z-index: 69;
    color: #37465a;
    font-size: 2.1672rem;
    line-height: 3.0625rem;
    text-align: center;
  }
  #element-1493 .x_2ff4a83b {
    text-align: center;
    line-height: 3.125rem;
    font-size: 2.1931rem;
  }
  #element-1493 .x_a7fe84b4 {
    color: #0098a5;
  }
  #element-1544 {
    top: 185.9375rem;
    left: -0.125rem;
    height: 2rem;
    width: 60rem;
    z-index: 91;
    color: #37465a;
    font-size: 1.2384rem;
    line-height: 2rem;
    text-align: center;
  }
  #element-1544 .x_05f58128 {
    text-align: center;
    line-height: 2rem;
    font-size: 1.241rem;
  }
  #element-1544 .x_403c9345 {
    color: #151d2d;
  }
  #element-1505 {
    top: 190.125rem;
    left: 25.1875rem;
    height: 1.625rem;
    width: 3.375rem;
    z-index: 74;
  }
  #element-1505 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(142, 214, 216);
  }
  #element-1499 {
    top: 190.125rem;
    left: 29.0625rem;
    height: 1.625rem;
    width: 3.5rem;
    z-index: 71;
  }
  #element-1499 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(205, 205, 205);
  }
  #element-1497 {
    top: 190.125rem;
    left: 32.875rem;
    height: 1.625rem;
    width: 1.5rem;
    z-index: 70;
  }
  #element-1497 .shape {
    border: 0;
    border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
    background: rgb(205, 205, 205);
  }
  #element-1507 {
    top: 190.25rem;
    left: 25.625rem;
    height: 1.125rem;
    width: 3.5rem;
    z-index: 75;
    color: #37465a;
    font-size: 0.805rem;
    line-height: 1.1375rem;
    text-align: left;
  }
  #element-1507 .x_ff17dd85 {
    text-align: left;
    line-height: 1.125rem;
    font-size: 0.805rem;
  }
  #element-1507 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1503 {
    top: 190.25rem;
    left: 33rem;
    height: 1.3125rem;
    width: 1.4375rem;
    z-index: 73;
    color: #37465a;
    font-size: 0.9288rem;
    line-height: 1.3125rem;
    text-align: left;
  }
  #element-1503 .x_a84c1223 {
    text-align: left;
    line-height: 1.3125rem;
    font-size: 0.9072rem;
  }
  #element-1503 .x_f2074b6c {
    color: #ffffff;
  }
  #element-1501 {
    top: 190.3125rem;
    left: 29.3125rem;
    height: 1.0625rem;
    width: 4rem;
    z-index: 72;
    color: #37465a;
    font-size: 0.743rem;
    line-height: 1.05rem;
    text-align: left;
  }
  #element-1501 .x_3206b8d5 {
    text-align: left;
    line-height: 1.0625rem;
    font-size: 0.743rem;
  }
  #element-1501 .x_f2074b6c {
    color: #ffffff;
  }
  #page-block-ndo1v0x4co {
    height: 63.3125rem;
    max-width: 100%;
  }
  #page-block-ndo1v0x4co .section-holder-border {
    border: 0;
  }
  #page-block-ndo1v0x4co .section-block {
    background: url(//v.fastcdn.co/u/4126587d/59481156-0-Artboard-1.png) repeat
      rgb(255, 255, 255) 50% 50% / cover;
    height: 63.3125rem;
  }
  #page-block-ndo1v0x4co .section-holder-overlay {
    display: none;
  }
  #element-1180 {
    top: 7.4375rem;
    left: 2.9375rem;
    height: 7.625rem;
    width: 53.6875rem;
    z-index: 6;
    color: #37465a;
    font-size: 3.1579rem;
    line-height: 3.825rem;
    text-align: right;
  }
  #element-1180 .x_34049cb0 {
    text-align: right;
    line-height: 3.8125rem;
    font-size: 3.1579rem;
  }
  #element-1180 .x_69c6632a {
    color: #e30613;
  }
  #element-1180 .x_a9820a77 {
    color: #515151;
  }
  #element-1177 {
    top: 12.375rem;
    left: 30rem;
    height: 43.75rem;
    width: 26.625rem;
    z-index: 7;
  }

  #element-1177 .btn.btn-effect3d:active {
    box-shadow: none;
  }

  #element-1177 ::placeholder {
    color: #5e6c7b;
  }
  #element-1177 .user-invalid {
    border-color: #e12627;
  }

  #element-1177 select.valid {
    color: #ff0000;
  }
  #element-1177 .form-btn-geometry {
    top: 10rem;
    left: 10rem;
    .ant-btn {
      width: 130px;
    }
  }

  #element-1526 {
    top: 13.875rem;
    left: 5.9375rem;
    height: 44.0625rem;
    width: 17.75rem;
    z-index: 24;
  }
  #page-block-0w2zrg83vz7 {
    height: 5.25rem;
    max-width: 100%;
  }
  #page-block-0w2zrg83vz7 .section-holder-border {
    border: 0;
  }
  #page-block-0w2zrg83vz7 .section-block {
    background: rgb(30, 30, 30);
    height: 5.25rem;
  }
  #page-block-0w2zrg83vz7 .section-holder-overlay {
    display: none;
  }
  #element-211 {
    top: 1.875rem;
    left: 19.0625rem;
    height: 1.375rem;
    width: 21.5rem;
    z-index: 3;
    color: #37465a;
    font-size: 0.8669rem;
    line-height: 1.4rem;
    text-align: center;
  }
  #element-211 .x_938e8cfc {
    text-align: center;
    line-height: 1.375rem;
    font-size: 0.8669rem;
  }
  #element-211 .x_f2074b6c {
    color: #ffffff;
  }
}

.br-bosta-clinic__last-section__text {
  font-size: 3.1579rem;
  color: #515151;
}

.br-bosta-clinic__last-section__red-text {
  color: #e30613;
}

.br-bosta-clinic__last-section {
  text-align: center;
}

.br-bosta-clinic__last-section__image {
  width: 283px;
}

.br-bosta-clinic__last-section__footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}

.br-bosta-clinic__last-section .ant-btn.ant-btn-primary.button-primary {
  width: 150px;
  font-size: 1.5rem;
}
