@import 'styles/variables';

.br-signup__form__terms,
.ant-form-item.br-signup__form__terms {
  min-height: unset;

  &.ant-form-item-has-error {
    .ant-form-item-explain {
      display: none;
    }
    .ant-form-item-control-input-content {
      box-shadow: none;
    }
  }

  .br-signup-terms-link {
    color: @teal-500;
  }

  .br-sign-up__terms-rejected {
    color: @red-500;
  }
}
