@import 'styles/variables.less';

.br-address-details-section {
  .br-form-city.ant-form-item,
  .br-forms-new-input .ant-form-item,
  .ant-form-item.br-address-details__street {
    min-height: unset;
    margin-bottom: 8px;
    .ant-form-item-explain {
      margin-bottom: 0;
    }
  }

  .ant-row.ant-form-item-row {
    margin-bottom: 0;
  }

  .ant-form-item.br-forms-new-input.br-form-city {
    margin: 12px 0 0 0;
    .ant-col.ant-form-item-label {
      display: block;
    }
  }

  .br-address-details__form-title {
    color: @text-gray-dark;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 4px;
    .br-button-component__with-prefix span {
      display: flex;
    }
  }
  .ant-form-item:not(.br-form-city-area) {
    .ant-col.ant-form-item-label {
      display: none;
    }
  }

  .br-address-details__form-title__point-pickup {
    width: 100%;
    margin-left: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .br-address-details__form-title__point-pickup__text {
    color: @text-gray;
    .font({.body()});
  }

  .br-address-details__form-title__point-pickup__action {
    color: @text-teal;
    cursor: pointer;
    .font({.button()});
  }

  .ant-divider-horizontal.ant-divider-with-text {
    margin: 24px 0;
    border-color: @gray-200;
  }

  .ant-divider-inner-text {
    padding: 0 8px;
    .font({.body()});
    color: @text-gray;
  }
}

.ant-row.ant-form-item.br-customer-details__work-address.br-form-without-label {
  min-height: unset !important;
}

.br-address-details-section__actions {
  display: flex;
  gap: 8px;
  margin-top: 12px;
  .ant-btn {
    width: 100%;
  }
}

.ant-input[disabled].br-address-details__circlek-disabled-input {
  margin-top: 4px;
  border-radius: 4px;
}

.br-address-details-section__actions-or {
  margin: 0 12px;
}

.br-address-details-section__actions__without-pudo {
  align-items: center;
}

@media only screen and (max-width: @xs) {
  .br-additional-info-fields {
    .br-additional-info-fields__floor-apt {
      flex-direction: row;
    }
  }
}

@media only screen and (min-width: @sm) {
  .br-address-details-section .br-address-details__form-title {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: @sm) {
  .br-address-details-section__actions__without-pudo {
    flex-direction: column;
  }

  .br-address-details-section__actions__left-seperator,
  .br-address-details-section__actions__right-seperator {
    width: 100%;
    height: 1px;
    display: block;
    background: @gray-200;
  }

  .br-address-details-section__actions-or-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 10px 0;
  }
}
