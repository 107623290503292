@import 'styles/variables.less';

.br-bosta-insights__report {
  padding: 100px;
  background: linear-gradient(
    180deg,
    rgba(29, 189, 205, 0) 0%,
    rgba(29, 189, 205, 0.1) 100%
  );
}

@media only screen and (max-width: @md) {
  .br-bosta-insights__report {
    padding: 100px 30px;
  }
}
