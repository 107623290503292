@import 'styles/variables.less';

.br-capital__features-section {
  display: flex;
  padding: 96px 16px;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  align-self: stretch;
  background: #fff;

  & .br-capital__features-section-header {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.72px;
  }

  & .br-capital__features-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: 37px 91px;
    max-width: 885px;
    min-height: 277px;

    & .br-capital__features-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 24px;
      width: 397px;

      > .br-capital__features-item-wrapper {
        width: 78px;
        height: 78px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 20px;
        border: 1px solid #e5f4f2;
        background: #fff;
        box-shadow: @shadow-xs;

        > svg {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
        }
      }

      & p.br-capital__features-item-title {
        font-size: 20px;
        font-weight: 700;
        color: @black;
      }

      & p.br-capital__features-item-subtitle {
        color: @gray-600;
        font-size: 20px;
        font-weight: 300;
        line-height: 30px;
      }
    }
  }
}

@media only screen and (max-width: @sm) {
  .br-capital__features-section {
    & .br-capital__features-section-header {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: -0.48px;
      padding: 32px;
    }

    & .br-capital__features-container {
      width: 100%;
      gap: 24px;
    }
  }
}
