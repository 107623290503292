@import 'styles/variables';

.br-new-signup {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 49px;

  > div {
    width: 100%;
  }

  .br-new-signup__form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: @red-25;
    border-radius: 24px;
    padding: 75px 28px;
    position: relative;
    width: calc(100% - 66px);

    > span:first-child {
      margin-bottom: 8px;
    }

    .br-signup {
      width: 100%;
      max-width: 635px;
      padding: 33px;
      margin-top: 58px;
      background-color: @white;
      z-index: 1;
      border-radius: 30px;
      border: 0.5px solid transparent;
      background: linear-gradient(white, white) padding-box,
        linear-gradient(230.55deg, #e30613 0%, #1dbdcd 100%) border-box;

      .ant-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 7px;
        max-width: 462px;
        margin: auto;

        > div {
          margin: 0;
        }

        .br-signup__content-title {
          display: none;
        }

        .br-sidebar-content__sub-title {
          margin: 0;
          color: @text-gray-dark;
        }

        .br-signup__form__terms .ant-form-item-control-input-content {
          min-height: unset;
        }

        .ant-form-item-label {
          text-align: start;
          margin-bottom: 7px;
        }

        .ant-col.ant-form-item-control,
        .ant-input-affix-wrapper,
        .ant-select-selector {
          min-height: 54px;
        }

        .ant-input-affix-wrapper,
        .ant-select-selector {
          border: 0.5px solid transparent;
          background: linear-gradient(white, white) padding-box,
            linear-gradient(230.55deg, #e30613 0%, #1dbdcd 100%) border-box;
        }

        .br-international-phone {
          .ant-input-affix-wrapper {
            border-right-color: @gray-200;
          }

          .ant-form-item-explain.ant-form-item-explain-connected {
            min-height: 24px;
          }
        }

        .ant-select-selector {
          border-left-color: @gray-200;
        }

        .br-registration-form-card__submit-btn {
          margin-top: 37px;
          width: fit-content;
          align-self: center;
          min-width: 288px;
          border-radius: 20px;
          height: 70px;
          border: 1px solid transparent;
          background: linear-gradient(@teal-500, @teal-500) padding-box,
            linear-gradient(150deg, #e30613 25.79%, #1dbdcd 74.43%) border-box;

          .font({.grid-title()});
        }
      }

      .br-international-phone {
        max-height: unset;
      }
    }

    > img {
      position: absolute;
      right: 18px;
      top: 74px;
      width: 764px;
      height: 764px;
      opacity: 0.4;
    }
  }
}

.br-new-signup__bosta-offers {
  margin: 39px 0;

  .br-new-signup__bosta-offers-header {
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-column-gap: 24px;
    align-items: center;
    width: 80%;
    margin: auto;

    &::before,
    &::after {
      content: '';
      display: block;
      height: 2px;
      background: linear-gradient(90deg, #e30613 11.55%, #1dbdcd 91.79%);
    }
  }

  > .br-new-signup__bosta-offers-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin-top: 24px;
    position: relative;
    height: 500px;

    > .br-new-signup__offers-image {
      position: absolute;
      right: 0;
      height: 630px;
      width: 510px;
      z-index: 1;
    }

    > .br-new-signup__offers-vectors {
      position: absolute;
      right: 0;
      top: -120px;
      height: 730px;
    }
  }

  .br-new-signup__bosta-offers-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 90px;
    padding-left: 11%;
    z-index: 2;

    > div {
      display: flex;
      flex-direction: column;

      .br-new-signup__bosta-offers-title {
        margin-bottom: 14px;
        display: flex;
        gap: 6px;
      }
    }
  }
}

.br-new-signup__fast-easy {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  background: linear-gradient(180deg, #fffafa 29.73%, #ffffff 100%);
  padding: 42px 20px 108px;
  border-top-right-radius: 250px;
  position: relative;
  overflow: hidden;

  > img {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.4;
  }

  .br-new-signup__fast-easy-cards {
    display: grid;
    grid-template-columns: 580px 580px;
    grid-column-gap: 102px;
    grid-row-gap: 56px;

    .br-new-signup__fast-easy-card {
      background-color: @white;
      padding: 20px;
      height: 120px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 17px;
      border-radius: 10px;
      border: 1px solid @gray-200;
      border: 1px solid transparent;
      background: linear-gradient(white, white) padding-box,
        linear-gradient(267.55deg, #e30613 0%, #1dbdcd 100%) border-box;

      > div {
        display: flex;
        align-items: center;
        gap: 11px;

        .anticon {
          color: transparent;
        }

        > span:last-child {
          color: @teal-400;
        }
      }
    }
  }
}

.br-new-signup__eish-waiting {
  display: flex;
  justify-content: flex-end;
  padding-right: 93px;
  position: relative;
  align-items: center;
  height: 756px;
  background: linear-gradient(
    153.35deg,
    #dcf4f7 16.71%,
    rgba(255, 251, 251, 0) 83.29%
  );
  border-top-left-radius: 250px;
  overflow: hidden;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 24px;
    max-width: 620px;
    z-index: 4;
  }

  img {
    position: absolute;

    &.br-new-signup__eish-waiting-saudi {
      left: 0;
      bottom: 0;
      z-index: 2;
    }

    &.br-new-signup__eish-waiting-logo {
      left: 275px;
      bottom: 60px;
      height: 600px;
      width: 600px;
      opacity: 0.2;
    }

    &.br-new-signup__eish-waiting-vectors {
      left: 0;
      opacity: 0.4;
    }
  }
}

.br-new-signup__red {
  color: @red-600;
}

.br-new-signup__teal {
  color: @teal-500;
}

html[dir='rtl'] .br-new-signup .br-signup .ant-checkbox {
  transform: scaleX(-1);
}

@media only screen and (max-width: 1370px) {
  .br-new-signup__eish-waiting {
    img {
      &.br-new-signup__eish-waiting-saudi {
        width: 680px;
        height: 630px;
        bottom: 0;
      }

      &.br-new-signup__eish-waiting-logo {
        bottom: auto;
        width: 400px;
        height: 400px;
      }
    }
  }
}

@media only screen and (max-width: @lg) {
  .br-new-signup {
    .br-new-signup__fast-easy {
      padding-left: 20px;
      padding-right: 20px;

      .br-new-signup__fast-easy-cards {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .br-new-signup__fast-easy-card {
          height: 81px;
          padding: 12px;
          gap: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: @md) {
  .br-new-signup {
    .br-new-signup__form-container {
      > img {
        right: 0;
        top: 10px;
        width: 280px;
        height: 280px;
      }
    }

    .br-new-signup__bosta-offers {
      > .br-new-signup__bosta-offers-container {
        > .br-new-signup__offers-image {
          height: 334px;
          width: 280px;
          z-index: 1;
          top: 130px;
        }

        > .br-new-signup__offers-vectors {
          top: 100px;
          height: 380px;
          width: 319px;
        }
      }

      .br-new-signup__bosta-offers-content {
        > div {
          max-width: 300px;
        }
      }
    }

    .br-new-signup__eish-waiting {
      height: unset;
      border-top-left-radius: 100px;

      > div {
        max-width: 200px;
        padding-top: 30px;
      }

      img {
        &.br-new-signup__eish-waiting-saudi {
          width: 480px;
          height: 430px;
          bottom: unset;
        }

        &.br-new-signup__eish-waiting-logo {
          left: 0px;
          bottom: 0px;
          width: 400px;
          height: 400px;
        }
      }
    }
  }
}

@media only screen and (max-width: @sm) {
  .br-new-signup {
    .br-new-signup__form-container .br-signup .ant-form {
      .ant-col.ant-form-item-control,
      .ant-input-affix-wrapper,
      .ant-select-selector {
        min-height: 40px !important;
      }
    }

    .br-new-signup__eish-waiting {
      padding-right: 0;
      height: unset;
      border-top-left-radius: 100px;

      > div {
        max-width: 200px;
        padding: 30px 0;
        gap: 10px;

        .br-new-signup__teal {
          margin-top: 6px;
          padding: 0 40px;
        }
      }

      img {
        &.br-new-signup__eish-waiting-saudi {
          width: 200px;
          height: 190px;
          bottom: unset;
        }

        &.br-new-signup__eish-waiting-logo {
          left: 65px;
          bottom: 90px;
          height: 168px;
          width: 168px;
          opacity: 0.4;
        }

        &.br-new-signup__eish-waiting-vectors {
          left: -200px;
          top: -100px;
          height: 526px;
        }
      }
    }

    .br-new-signup__bosta-offers {
      .br-new-signup__bosta-offers-header {
        width: 90%;
      }
    }
  }
}

@media only screen and (max-width: @xs) {
  .br-new-signup {
    padding-top: 0;
    .br-new-signup__form-container {
      width: calc(100% - 18px);
      height: 320px;
      padding: 37px 10px;

      > span {
        text-align: center;
      }

      .br-signup {
        margin-top: 27px;
        padding: 10px 20px 20px;

        .ant-form {
          .ant-col.ant-form-item-control,
          .ant-input-affix-wrapper,
          .ant-select-selector {
            min-height: 28px;
          }

          .br-registration-form-card__submit-btn {
            min-width: 136px;
            margin-top: 27px;
            height: 36px;
          }
        }
      }
    }

    .br-new-signup__bosta-offers {
      margin: 550px 0 0;

      > .br-new-signup__bosta-offers-container {
        height: unset;

        > .br-new-signup__offers-image {
          top: 25px;
        }

        > .br-new-signup__offers-vectors {
          top: 0px;
        }
      }

      .br-new-signup__bosta-offers-content {
        padding-left: 14px;

        > div {
          max-width: 200px;
        }
      }
    }

    .br-new-signup__fast-easy {
      margin-top: 20px;
      border-top-right-radius: 100px;
    }
  }
}

@media only screen and (max-width: 375px) {
  .br-new-signup {
    .signup-ReCAPTCHA {
      iframe {
        width: 243px;
      }
    }
  }
}
