@import 'styles/variables.less';

.br-registration-container {
  background: @gradient;
  min-height: 100%;
  width: 100%;
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-input-affix-wrapper {
    height: 44px;
    padding: 12px 16px;
  }

  .ant-input-affix-wrapper {
    padding: 0 16px;
  }
}

.br-registration-container__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12.22%;
  min-height: 68px;
  margin-bottom: 24px;
}

.br-registration-container__header__action {
  display: flex;
  align-items: center;
}

.br-registration-container__track-order.br-button-component__with-suffix.button-md:hover
  span:last-child
  path {
  stroke: @teal-700;
}

html[dir='rtl'] .br-registration-container__header__action {
  .ant-btn:first-child svg {
    transform: scaleX(-1);
  }
}

.br-registration-container__track-order.br-button-component__with-suffix.button-md {
  margin-right: 32px;
  span:last-child {
    margin-left: 8px;
    path {
      fill: none;
      stroke: @teal-500;
    }
  }
}

.br-registration-container__logo {
  height: 30px;
}

.ant-btn:not(.ant-btn-circle).br-registration-container__language-btn {
  width: 104px;
  display: flex;
  justify-content: flex-end;
}

html[dir='ltr'] .br-registration-container__language-btn {
  .br-button-component__label {
    font-family: 'Cairo/SemiBold';
  }
  svg {
    margin-right: 0;
    margin-left: 4px;
  }
}

html[dir='rtl'] .br-registration-container__language-btn {
  .br-button-component__label {
    .font({.button()});
  }
  svg {
    margin-right: 4px;
    margin-left: 0;
  }
}

.br-registration-container__hexagon {
  position: absolute;
  top: 30%;
  left: 85vw;
}

.br-registration-container__red-hexagon {
  position: absolute;
  bottom: 21.39%;
  right: 0;
}

.br-registeration-container__bottom-line {
  position: absolute;
  bottom: 50px;
  right: 0;
  width: 100%;
  height: 3px;
  background: @gray-500;
}

.br-registration-container__left-hexagon {
  position: absolute;
  bottom: 20px;
  left: 0;
}

html[dir='rtl'] {
  .br-registration-container__left-hexagon,
  .br-registration-container__red-hexagon {
    transform: scaleX(-1);
  }
}

.br-registration-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.br-registration-form-card {
  position: relative;
  box-shadow: @shadow-lg;
  border-radius: 8px;
  background-color: @white;
  min-width: 536px;
  min-height: 224px;
  padding: 40px;
  z-index: 1;
  overflow: auto;
  .ant-form-item:not(:last-of-type) {
    margin-bottom: 14px;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px @white inset !important;
    box-shadow: 0 0 0 30px @white inset !important;
  }
  .ant-form-item-has-success.ant-form-item-has-feedback .anticon-check-circle {
    display: none;
  }
  .br-registration-form-card__submit-btn {
    width: 100%;
    margin-top: 22px;
  }

  .ant-form-item.br-signup__form__terms {
    margin-bottom: 0;
    .ant-form-item-control-input-content {
      min-height: 0;
    }
  }
}

.br-registration-form-card:has(.br-signup) {
  max-width: 536px;
}

.br-registration-container__with-footer {
  background-image: url('../../assets/images/signup-background.png');
  .br-registration-form-card {
    padding: 0;
  }
}

.br-registration-action-link {
  margin-top: 44px;
  display: flex;
  justify-content: center;
}

.br-registration-container__loading {
  height: 100%;
  padding: 80px;
  .ant-spin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    svg {
      transform: scale(2);
    }
    .ant-spin-text {
      margin-top: 18px;
      color: @text-gray-dark;
    }
  }
  .br-registration-action-link {
    display: none;
  }
}

.br-registration-container__loading {
  height: 100%;
  padding: 80px;
  .ant-spin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    svg {
      transform: scale(2);
    }
    .ant-spin-text {
      margin-top: 18px;
      color: @text-gray-dark;
    }
  }
  .br-registration-action-link {
    display: none;
  }
}

@media only screen and (max-width: @sm) {
  .br-registration-container {
    background-position: 6% 100%;
    background-size: 250%;
  }
  .br-registration-container__with-footer {
    background-size: 150%;
  }

  .br-registration-container__left-hexagon {
    left: -35%;
  }
}

@media only screen and (max-width: @xs) {
  .br-registration-container__header {
    margin-bottom: 0;
    padding: 20px 24px;
  }
  .br-registration-content {
    width: 100%;
    padding: 0;
  }

  .br-registration-form-card {
    padding: 24px 16px;
    width: calc(100vw - 16px);
    min-width: calc(100vw - 16px);
  }

  .br-registration-action-link {
    margin-top: 48px;
  }
}
