@import 'styles/variables.less';

.br-reports {
  padding: 100px;

  .display-lg-extra {
    margin-bottom: 44px;
  }

  .sidebar-child {
    .font-14;
  }
}

.br-reports_cards {
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.br-reports__card {
  width: 280px;
  height: 459px;

  .heading {
    margin-bottom: 4px;
  }
}

.br-reports__card__image {
  max-width: 100%;
  justify-content: center;
  transition: transform 0.2s;
  margin-bottom: 12px;

  img {
    width: 100%;
  }
}

@media only screen and (max-width: @md) {
  .br-reports {
    padding: 30px;
  }
}
