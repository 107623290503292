@import 'styles/variables';

.br-international-shipping__form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 64px;
  width: 100%;
  padding: 72px 0;

  .ant-form {
    width: 100%;
    max-width: 448px;
  }

  .ant-btn {
    width: 100%;
  }

  .ant-checkbox-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;

    label {
      margin-left: 0;
    }
  }
}

.br-international-shipping__form__capacity-label {
  display: flex;
  align-items: center;
  gap: 4px;
}

@media only screen and (max-width: @lg) {
  .br-international-shipping__form-container {
    padding: 12px 16px;

    .ant-btn {
      margin-top: 20px;
    }
  }
}
