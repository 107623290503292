@import 'styles/variables.less';

.br-fulfillment__highlight-section {
  padding: 96px 64px;
  position: relative;
  border-bottom: 3.5px solid rgba(159, 222, 228, 0.3);

  p {
    margin: 0;
  }

  .br-fulfillment__highlight-content {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 96px;
    align-items: center;

    .br-fulfillment__highlight-info {
      display: flex;
      flex-direction: column;
      max-width: 598px;
      width: 100%;
      align-items: flex-start;
      gap: 48px;
      z-index: 10;

      .br-fulfillment__highlight-title {
        text-align: start;
        .display-lg-extra();
      }

      button {
        padding: 12px 24px;
        border-radius: 8px;
        .button-lg();
      }
    }

    .br-fulfillment__highlight-image {
      max-width: 503px;
      width: 100%;
      object-fit: contain;
      flex-shrink: 1;
      z-index: 10;
    }
  }

  .br-fulfillment__highlight-vector {
    position: absolute;
    left: 315px;
    bottom: -29px;
    max-width: 361px;
    max-height: 362px;
    z-index: 1;
  }
}

html[dir='rtl'] .br-fulfillment__highlight-vector {
  transform: scaleX(-1);

  left: 399px;
}

@media only screen and (max-width: @lg) {
  .br-fulfillment__highlight-section {
    .br-fulfillment__highlight-content {
      .br-fulfillment__highlight-info {
        gap: 24px;

        .br-fulfillment__highlight-title {
          max-width: 400px;
          width: 100%;
          .display-lg();
        }

        button {
          padding: 8px 16px;
        }
      }

      .br-fulfillment__highlight-image {
        max-width: 306px;
      }
    }

    .br-fulfillment__highlight-vector {
      max-width: 213.843px;
      max-height: 213.216px;
      left: 240px;
      bottom: -18px;
    }
  }
}

@media only screen and (max-width: @md) {
  .br-fulfillment__highlight-section {
    padding: 40px 16px;
    border-bottom: 2px solid rgba(159, 222, 228, 0.3);

    .br-fulfillment__highlight-content {
      flex-direction: column-reverse;
      gap: 24px;

      .br-fulfillment__highlight-info {
        align-items: center;
        gap: 24px;

        .br-fulfillment__highlight-title {
          text-align: center;
          .display-xs();
        }
      }

      .br-fulfillment__highlight-image {
        max-width: 206px;
      }
    }

    .br-fulfillment__highlight-vector {
      transform: scaleX(-1);
      max-width: 113.843px;
      max-height: 113.216px;
      left: 540px;
      bottom: -9px;
    }
  }
  html[dir='rtl'] .br-fulfillment__highlight-vector {
    left: 150px;
  }
}

@media only screen and (max-width: @sm) {
  .br-fulfillment__highlight-section {
    .br-fulfillment__highlight-vector {
      left: 440px;
      bottom: -9px;
    }
  }
  html[dir='rtl'] .br-fulfillment__highlight-vector {
    left: 20px;
  }
}
@media only screen and (max-width: @xs) {
  .br-fulfillment__highlight-section {
    .br-fulfillment__highlight-content {
      .br-fulfillment__highlight-info {
        .br-fulfillment__highlight-title {
          text-align: center;
          .body-medium();
        }
      }

      .br-fulfillment__highlight-image {
        max-width: 206px;
      }
    }

    .br-fulfillment__highlight-vector {
      left: auto;
      right: 10px;
      bottom: -9px;
    }
  }
  html[dir='rtl'] .br-fulfillment__highlight-vector {
    right: 10px;
  }
}
