.br-integrations {
  .r-hex {
    margin-top: 100px;
  }
  .r-hex-inner-2:before {
    background: url(../../assets/images/Integrations1.png);
    background-repeat: no-repeat;
    background-position: 60%;
    background-size: cover;
  }
}

@media only screen and (max-width: 991px) {
  .br-integrations {
    .br__hero__content-svg {
      &.svg-1 {
        bottom: 270px;
      }
    }
  }
}
