@import 'styles/variables.less';

.br-grid-title {
  color: @text-gray-dark;
}

.br-grid-subtitle {
  color: @gray-600;
  margin: 50px 0;
}

.br-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 53px;
}

.br-grid-item {
  flex-basis: calc(50% - 50px);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 46px;
  z-index: 1;
  .anticon {
    font-size: 30px;
    margin-bottom: 12px;
    width: fit-content;
    svg path,
    rect {
      fill: transparent;
    }
  }
  span {
    color: @gray-600;
  }
  .display-xs {
    margin-bottom: 8px;
  }
}

.br-grid-single-item {
  flex-basis: 100%;
}

.br-grid-item .br-grid-item__link {
  margin-top: 5px;
  color: @text-teal;
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    margin-left: 3px;
  }
}

@media only screen and (max-width: 991px) {
  .br-grid-title {
    text-align: center;
    width: 100%;
  }

  .br-grid-item {
    align-items: center;
    flex-basis: 100%;

    & span {
      text-align: center;
    }
  }
}
