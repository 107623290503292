.br-merged-integrations__connect-and-save {
  position: relative;
  display: flex;
  gap: 148px;
  padding-left: 110px;
  margin-top: 97px;
}

.br-merged-integrations__connect-and-save__image {
  min-width: 34%;
  margin-right: 51px;
  div {
    position: absolute;
    overflow: hidden;
  }
  .svg-1 {
    right: 351px;
    top: 450px;
    z-index: 1;
  }
  .svg-2 {
    right: 345px;
    top: 529px;
    z-index: 3;
  }
  .svg-3 {
    right: 233px;
    top: 314px;
    z-index: 2;
  }
  .svg-4 {
    right: 201px;
    top: 438px;
    z-index: 3;
  }
  .svg-5 {
    right: 90px;
    top: 239px;
    z-index: 1;
  }
  .svg-6 {
    right: -11px;
    top: 529px;
    z-index: 2;
  }
  .svg-7 {
    right: 345px;
    top: 120px;
    overflow: unset;
    width: 268px;
    height: 176px;
  }
  .svg-8 {
    right: 361px;
    top: 227px;
    z-index: 3;
    overflow: unset;
  }
  .svg-9 {
    right: 14px;
    top: 633px;
    z-index: 4;
  }
  .svg-10 {
    right: 0;
    top: 649px;
    z-index: 4;
  }
}

html[dir='rtl'] .br-merged-integrations__connect-and-save__image div {
  transform: scaleX(-1);
}

.br-merged-integrations__connect-and-save__content {
  flex: 50%;
}

@media only screen and (max-width: 991px) {
  .br-merged-integrations__connect-and-save {
    padding: 0 30px;
    flex-direction: column;
    text-align: center;
    gap: 0;
  }

  .br-merged-integrations__connect-and-save__image {
    margin: 0;
    & > img {
      max-width: 100%;
      height: 100%;
    }
  }
}
