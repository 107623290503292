.br-admin-login-container{
    display: flex;
    justify-content: center;
    padding: 20px;
    .ant-form{
        width: 50%;
    }
    .ant-btn{
        width: 100%;
margin-top: 20px;
    }
}