@import 'styles/variables.less';

.br-fulfillment__video-section {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 96px 0;
  .br-fulfillment__video-iframe {
    width: 820px;
    height: 515px;
    border-radius: 25px;
  }
}

@media only screen and (max-width: @md) {
  .br-fulfillment__video-section {
    .br-fulfillment__video-iframe {
      width: 720px;
    }
  }
}

@media only screen and (max-width: @sm) {
  .br-fulfillment__video-section {
    padding: 32px 24px;
    width: 100%;

    .br-fulfillment__video-iframe {
      width: 327px;
      height: 218px;
    }
  }
}
