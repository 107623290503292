@import 'styles/variables.less';
@import 'styles/fonts-variables.less';

.br__partner__section {
  padding: 110px;
  margin-bottom: 60px;
  z-index: 1;
  position: relative;

  &-container {
    display: flex;
  }
}

.br__partner__section-content {
  flex-grow: 1;
  flex-basis: 0;
}

.br__partner__section-philosophy .br__partner__content-title {
  width: 60%;
}

.br__partner__content-title {
  font-size: 30px;
  font-weight: 600;
}

.br__partner__content-subtitle {
  margin-top: 20px;
  font-family: @font-regular-2;
  font-size: 16px;
  font-weight: 400;
}

.br__partner__content__more-details {
  font-family: @font-regular-2;
  font-weight: 400;
  font-size: 16px;
  color: @teal-500;
  display: flex;
  width: 55%;
  align-items: center;
  margin-left: 65px;
}

.br__partner__content__partners-logos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 607px;

  & .partner-logo {
    max-width: 145px;
    margin: 20px 50px 20px 0;
  }
}

.br__partner__section-philosophy {
  margin-left: 35%;
}

.br__partner__section-svg {
  position: absolute;
  right: 0;
  top: -170px;
  z-index: -1;
}

@media only screen and (max-width: 991px) {
  .br__partner__section {
    padding: 0 30px;
    & .br__partner__section-container {
      flex-direction: column;
      align-items: center;
    }

    & .partner-logo {
      margin: 0;
    }
  }

  .br__partner__section-philosophy {
    margin: 250px 0;
    text-align: center;

    & .br__partner__content-title {
      width: 100%;
    }
  }

  .br__partner__section-svg {
    right: -80px;
    top: unset;
    bottom: 0;

    & > svg {
      max-height: 700px;
    }
  }

  .br__partner__content-title {
    text-align: center;
    margin-bottom: 20px;
  }

  .br__partner__content__more-details {
    width: 100%;
    text-align: center;
    margin: 0;
  }

  .br__partner__content__partners-logos {
    justify-content: center;
  }
}
