@import '~antd/dist/antd.less';
@import './variables.less';
@import './antd-overrides.less';
@import './fonts.less';
@import './fonts-variables.less';
@import './fonts-classes.less';
@import './animations.less';

#root {
  height: 100%;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

html,
body {
  background-color: @white;
  overflow: hidden;
  height: 100%;
  margin: 0;
  padding-top: 39px;

  &:has(.br-shipment-tracking) {
    padding-top: 34px;
  }
  .font({.body()});
}

body
  > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: @scrollbar-thumb;
  border-radius: 2px;
}

*:focus {
  outline: none;
}

.display-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.isVisible {
  visibility: visible;
}

.isHidden {
  visibility: hidden;
}

//icon classes
//icons classes
.ant-icon-xs {
  font-size: 12px;
  svg {
    width: 12px;
    height: 12px;
  }
}

.ant-icon-sm {
  font-size: 16px;
  svg {
    width: 16px;
    height: 16px;
  }
}

.ant-icon-md {
  font-size: 20px;
  svg {
    width: 20px;
    height: 20px;
  }
}

.ant-icon-lg {
  font-size: 24px;
  svg {
    width: 24px;
    height: 24px;
  }
}

.ant-icon-xl {
  font-size: 32px;
  svg {
    width: 32px;
    height: 32px;
  }
}

.ant-message {
  z-index: 99999999999999;
}

.br-form-row {
  display: flex;
}

.br-form-row > *:not(:last-child) {
  margin-right: 8px;
}

.br-form-row > * {
  flex: 1;
}

::-webkit-scrollbar-thumb {
  background-color: @gray-200;
  border-radius: 8px;
}

::-webkit-scrollbar {
  width: 16px;
  background: @gray-50;
  border-left: 1px solid @gray-200;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border: 4px solid rgba(@black, 0);
  background-clip: padding-box;
}

.ant-form-item .ant-form-item-label .br-form-optional-label {
  text-align: center;
  display: flex;
  align-items: center;
  vertical-align: middle;
  color: @gray-400;
  border-radius: 2px;
  padding-left: 4px;
  padding-right: 3px;
  height: fit-content;
  .font({.caption()});
}
