.br__investors__section {
  position: relative;
  z-index: 10;
}

.br__investors__section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.br__investors__section-content {
  width: 400px;
  position: relative;
}

.br__investors__content-title {
  font-size: 30px;
  font-weight: 600;
  text-align: left;
}

.br__investors__content-description {
  font-size: 16px;
  width: 400px;
}

.br__investors__content__investors-logos {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 44px;

  & .investors-logo {
    margin-right: 20px;
  }
}

.br__investors__section-main-svg {
  position: absolute;
  left: -120px;
  top: -70px;
  z-index: -1;
}

@media only screen and (max-width: 991px) {
  .br__investors__section {
    margin-top: 150px;
    padding: 0 30px;
  }

  .br__investors__section-container,
  .br__investors__content-title {
    text-align: center;
  }

  .br__investors__section-content,
  .br__investors__content-description {
    width: 100%;
  }

  .br__investors__section-main-svg {
    left: -10px;
    top: -300px;
    & > svg {
      max-width: 350px;
    }
  }

  .br__investors__content__investors-logos {
    flex-direction: column;
  }
}
