@import 'styles/variables.less';

.br-exception-card,
.br-exception-card__exception-data,
.br-exception-card__icon {
  display: flex;
  align-items: center;
}

.br-exception-card {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 856px;
  margin: 24px auto;
  border-radius: 8px;
  box-shadow: @shadow-sm;
  padding: 24px;

  .br-button-component {
    gap: 6px;

    &:hover {
      svg path {
        fill: @teal-600;
      }
    }
  }
}

.br-exception-card__exception-data {
  gap: 20px;
}

.br-exception-card__icon {
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: @gray-100;
  border-radius: 24px;
}

.br-exception-card__data {
  text-align: start;

  .br-exception-card__data-date {
    color: @text-gray-light;
    margin-bottom: 4px;
    .font({.caption()});
  }

  .br-exception-card__data-text {
    .font({.display-sm()});
  }
}

@media only screen and (max-width: @xs) {
  .br-exception-card,
  .br-exception-card__exception-data {
    flex-direction: column;
    align-items: flex-start;
  }

  .br-exception-card {
    gap: 20px;
  }

  .br-exception-card__exception-data {
    gap: 12px;
  }
}
