@import 'styles/variables.less';

.br-article-section__container {
  display: flex;
  padding: 20px;
  border: 1px solid @gray-200;
  margin: 20px 0;
  border-radius: 8px;
  width: 100%;
  > div {
    width: 50%;
    &:first-child {
      margin-right: 10px;
    }
  }
}

.br-create-edit-article__sections {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  > div {
    border: 1px solid @gray-200;
    border-radius: 8px;
    width: 100%;
    padding: 20px;
    &:first-child {
      margin-right: 10px;
    }
  }
}

.br-create-edit-article {
  padding: 20px;
  .ant-tabs-tab-btn {
    padding: 20px;
  }
}

.br-rich-text-editor
  .ql-snow
  .ql-picker:not(.ql-color-picker):not(.ql-icon-picker)
  svg {
  left: 80%;
}


html[dir='rtl'] .br-create-edit-article {
  direction: ltr;
}
