.br-integrations__ecommerce-plugins {
  position: relative;
  display: flex;
  margin-top: 97px;
  padding-left: 110px;
  gap: 80px;
}

.br-integrations__ecommerce-plugins__content {
  flex: 60%;
}

.br-integrations__ecommerce-plugins__content__underline-span {
  text-decoration: underline;
}

.br-integrations__ecommerce-plugins__image {
  width: 663px;
  margin-right: 50px;
  div {
    position: absolute;
  }
  .svg-1 {
    right: 463px;
    top: 277px;
    z-index: 3;
  }
  .svg-2 {
    right: 266px;
    top: 233px;
    z-index: 3;
  }
  .svg-3 {
    right: 71px;
    top: 240px;
    z-index: 1;
  }
  .svg-4 {
    right: 0px;
    top: 130px;
  }
  .svg-5 {
    right: 266px;
    top: 80px;
    z-index: 2;
  }
  .svg-6 {
    right: 269px;
    top: 113px;
  }
}

html[dir='rtl'] .br-integrations__ecommerce-plugins__image div {
  transform: scaleX(-1);
}

@media only screen and (max-width: 991px) {
  .br-integrations__ecommerce-plugins {
    padding: 0 30px;
    flex-direction: column;
    text-align: center;
  }

  .br-integrations__ecommerce-plugins__image {
    width: unset;
    margin: 0;
    & > svg {
      max-width: 350px;
      height: 100%;
    }
  }
}
