@import 'styles/variables';

.verify-phone-update .ant-form-item {
  display: flex;
  flex-direction: column;
}

.verify-phone-update .ant-form-item-label label {
  color: @black;
  font-size: 14px;
  // font-family: @font-500;
  display: flex;
  margin-bottom: 8px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 15px;
}

.verify-update-modal .br-update-phone-modal__header {
  padding: 16px 24px;
  box-shadow: @border-bottom;
}

.verify-update-modal {
  .ant-modal-content .ant-modal-body {
    padding: 0;
  }

  .br-location-contact-form__footer__right-side-buttons .ant-btn:first-child {
    margin-right: 0;
  }
}

.verify-update-modal .update-modal .br-signup-from__country-select {
  padding: 16px 20px;
}

.verify-update-modal
  .ant-form
  .ant-select.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: unset;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.verify-update-modal .ant-form .ant-input-group-compact .ant-select-show-arrow {
  max-height: 36px;
}

.verify-update-modal .br-location-contact-form-header-with-back {
  .br-content-header__titles {
    padding-left: 15px;
  }
}

.verify-update-modal .update-modal .br-international-phone {
  padding: 0 20px 35px;
}

html[dir='rtl'] .br-update-phone-modal__header .ant-btn svg {
  transform: scaleX(-1);
}
