.br-blog-home__articles {
  padding: 32px 4%;
}

.br-blog-home__image-container img {
  max-width: 100%;
  width: 100%;
}

.br-blog-articles__article-image:hover {
  -ms-transform: scale(1.03); 
  -webkit-transform: scale(1.03); 
  transform: scale(1.03);
}

.br-blog-home__image-info {
  padding: 0 62px;
  position: absolute;
  top: 85px;
  left: 10px;
  max-width: 51%;
  .br-header__title {
    max-width: 700px;
  }
}

.br-blog-home__header {
  position: relative;
  margin-bottom: 50px;
}

html[dir='rtl'] .br-blog-home__image {
  transform: rotateY(180deg);
}

@media only screen and (max-width: 991px) {
  .br-blog-home__header {
    display: flex;
    flex-direction: column-reverse;
  }
  .br-blog-home__image-info {
    top: 0;
    position: relative;
    max-width: 100%;
  }
}
