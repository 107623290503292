@import 'styles/variables.less';

.br-capital__eligable-section {
  display: flex;
  padding: 96px 16px;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  align-self: stretch;

  & .br-capital__eligable-section-header {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.72px;
  }

  & .br-capital__eligable-content {
    display: flex;
    align-items: center;
    gap: 69px;
    min-width: 939px;
    height: 614px;

    & .br-capital__eligable-paragraphs {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 48px;
    }

    & .br-capital__eligable-item {
      display: flex;
      max-width: 404px;
      align-items: flex-start;
      gap: 22px;

      > span {
        display: flex;
        width: 40px;
        height: 40px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 50%;
        color: @white;
        background-color: @teal-500;
      }

      & .paragraph-title {
        font-size: 21px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.5px;
      }

      & .paragraph-subtitle {
        font-size: 17px;
        font-weight: 400;
        line-height: 29px;
        letter-spacing: -0.5px;
        color: @gray-600;
      }
    }

    > img {
      height: 614px;
      width: 466px;
      border-radius: 8px;
      object-fit: cover;
      object-position: 30%;
    }
  }
}

@media only screen and(max-width:@sm) {
  .br-capital__eligable-section {
    width: 100vw;
    padding: 0 16px;
    gap: 32px;

    & .br-capital__eligable-section-header {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: -0.48px;
      padding: 0 32px;
    }

    & .br-capital__eligable-paragraphs {
      width: 100%;
      order: 2;
    }

    .br-capital__eligable-paragraph {
      width: 100%;
    }

    .br-capital__eligable-content {
      flex-direction: column;
      width: 100%;
      min-width: 100%;
      height: auto;
      gap: 40px;

      > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 8px;
        order: 1;
      }
    }
  }
}
