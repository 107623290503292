@import 'styles/variables.less';

.br-integrations__header {
  height: 757px;
  padding: 200px 0 83px 110px;
  background: @header-gradient;
  position: relative;
}

.br-integrations__header__text {
  width: 666px;
}

.br-integrations__header {
  & .br__hero__content-svg {
    &.svg-main {
      top: 0;
      right: 0;
    }
    &.svg-1 {
      top: 90px;
      right: 397px;
      & > svg {
        width: 105px;
        height: 105px;
      }
    }
    &.svg-2 {
      top: 582px;
      right: 758px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .br-integrations__header {
    padding: 0 30px;
    height: 100%;
  }

  .br-integrations__header {
    & .br__hero__content-svg {
      margin-top: 60px;
      &.svg-main {
        right: -65px;
        & > img {
          max-width: 100%;
        }
      }

      &.svg-1 {
        right: unset;
        top: unset;
        left: 70px;
        bottom: 350px;

        & > svg {
          max-width: 73px;
        }
      }

      &.svg-2 {
        right: unset;
        top: unset;
        left: 0;
        bottom: 0;

        & > svg {
          max-width: 160px;
        }
      }
    }
  }
}
