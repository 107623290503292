@import 'styles/variables.less';

.br-privacy-policy {
  padding: 50px 110px;

  .grid-title,
  .display-xs {
    margin-bottom: 20px;
  }
}

.br-privacy-ploicy__description {
  color: @gray-600;
  margin-bottom: 20px;
}

.br-privacy-ploicy__help-link {
  color: @red-500;
  margin: 0 3px;
}

@media only screen and (max-width: 991px) {
  .br-privacy-policy {
    padding: 0 30px;
  }
}
