@import 'styles/variables.less';
@import 'styles/fonts-variables.less';

.br-solutions-delivery-container {
  padding: 100px 110px;
  position: relative;
  z-index: 10;
}

.br-solutions-delivery-content {
  display: flex;
  flex-direction: row-reverse;
}

.br-solutions-delivery-subtitle {
  font-size: 20px;
  font-weight: 400;
  font-family: @font-regular-1;
  margin-top: 20px;
  margin-bottom: 0;
  color: @gray-600;
}

.br-solutions-delivery-content-grid {
  display: flex;
  width: 50%;
  flex-direction: column;

  & .br-grid {
    flex-wrap: nowrap;
    flex-direction: column;
  }
}

.br-solutions-delivery-svg {
  position: absolute;
  left: 0;
  top: 160px;
}

@media only screen and (max-width: 991px) {
  .br-solutions-delivery-container {
    text-align: center;
    padding: 100px 30px;
  }

  .br-solutions-delivery-content {
    flex-direction: column;
  }

  .br-solutions-delivery-content-grid {
    width: 100%;
  }

  .br-solutions-delivery-svg {
    position: relative;
    top: 0;

    & > svg {
      max-width: 350px;
      max-height: 350px;
    }
  }
}
