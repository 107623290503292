@import 'styles/variables.less';

.br-header {
  display: flex;
  flex-direction: column;
}

.br-header__title {
  color: @text-gray-dark;
  z-index: 1;
}

.br-header__subtitle {
  margin: 18px 0 30px;
  color: @text-gray;
}

.br-header > .button-primary {
  width: 212px;
}

@media only screen and (max-width: @lg) {
  .br-header__subtitle {
    max-width: calc(100% - 20vw);
  }
}

@media only screen and (max-width: 991px) {
  .br-header > .button-primary {
    width: 100%;
  }
}
