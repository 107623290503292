@import 'styles/variables.less';

.br-hero {
  padding: 190px 110px;
  margin-top: 50px;
  position: relative;
  z-index: 10;
  background: @header-gradient;
}

.br-about-header__subtitle {
  margin-top: 20px;
}

.br-use-cases__hero {
  .br__hero__content-svg {
    position: absolute;
    &.svg-main {
      right: 0;
      top: 0;
    }

    &.svg-1 {
      top: 50px;
      right: 431px;
    }

    &.svg-2 {
      bottom: 65px;
      right: 669px;
    }
  }

  .r-hex-inner-2:before {
    background: url(../../../../assets/images/Businesess.png);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }
}

@media only screen and (max-width: 991px) {
  .br-use-cases__hero {
    .br__hero__content-svg {
      &.svg-main {
        margin-top: 100px;
        position: relative;
      }

      &.svg-1 {
        right: unset;
        top: unset;
        bottom: 130px;
        left: 0;

        & > svg {
          max-width: 150px;
        }
      }

      &.svg-2 {
        right: unset;
        bottom: 50px;
        left: 30px;
      }
    }
  }
}
