@import 'styles/variables.less';
@import 'styles/fonts-variables.less';

.br-capital__hero-section {
  display: flex;
  padding: 50px 0px 96px 0;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  align-self: stretch;
  background: linear-gradient(180deg, #fff 0%, #ecfafc 100%);

  > div > p {
    margin: 0;
  }

  .br-capital__hero-section-header {
    max-width: 801px;
    font-size: 52px;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: -1.04px;
    text-align: center;
  }

  .br-capital__hero-section-description {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }

  & .br-capital__hero-content {
    display: flex;
    width: 1280px;
    padding: 0px 32px;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
}

.br-capital__hero-logos {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 46px;

  > img > {
    width: 161px;
    height: 98px;
    object-fit: contain;
  }

  > span {
    width: 1px;
    height: 52px;
    background: #000;
  }

  > svg {
    width: 193px;
    height: 56px;
  }
}

@media only screen and (max-width: @sm) {
  .br-capital__hero-section {
    .br-capital__hero-content {
      display: flex;
      width: 100vw;
      padding: 0px 16px;
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }

    .br-capital__hero-section-header {
      font-size: 32px;
    }

    .br-capital__hero-section-description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    .br-capital__hero-logos {
      display: flex;
      align-items: center;
      gap: 30px;

      > img {
        width: 120px;
        height: 59px;
      }

      > span {
        width: 1px;
        height: 52px;
        background: #000;
      }

      > svg {
        width: 161px;
        height: 56px;
      }
    }
  }
}
