@import 'styles/variables.less';

.br-simple-card {
  width: 224px;
  height: 224px;
  padding: 58px 0 45px 0;
  background: @white;
  border: 1px solid @gray-200;
  border-radius: 20px;
  box-shadow: 0px 20px 20px rgba(@black, 0.1);
  text-align: center;
}

.br-simple-card__image {
  margin-bottom: 27px;
}

.br-simple-card__title {
  color: @gray-600;
}
