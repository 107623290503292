@import 'styles/variables.less';

.br-fulfillment__signup-section {
  padding: 96px 0px;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
  }

  .br-fulfillment__signup-text {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 24px;

    .br-fulfillment__signup-header {
      color: @red-600;
      .display-xl();
    }

    .br-fulfillment__signup-subtitle {
      .display-lg();
    }
  }

  .br-fulfillment__signup-form {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    max-width: 454px;
    width: 100%;
    margin-top: 64px;

    form {
      gap: 24px;
    }
    .ant-form-item .ant-form-item-label {
      padding: 0;
    }
    .br-fulfillment__form-submit-btn {
      margin-top: 24px;
      border-radius: 8px;
      width: 100%;
    }

    button {
      border-radius: 8px;
      .button-lg();
    }
  }
}

@media only screen and (max-width: @sm) {
  .br-fulfillment__signup-section {
    padding: 40px 16px;
    p {
      margin: 0;
    }

    .br-fulfillment__signup-text {
      gap: 12px;

      .br-fulfillment__signup-header {
        color: @red-600;
        .display-xs();
      }

      .br-fulfillment__signup-subtitle {
        .body-medium();
      }
    }

    .br-fulfillment__signup-form {
      margin-top: 32px;
      .br-fulfillment__form-submit-btn {
        margin-top: 8px;
      }
    }
  }
}
