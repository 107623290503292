@import 'styles/variables.less';

.br-capital__thank-you-section {
  display: flex;
  padding: 96px 0px;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  align-self: stretch;

  .br-capital__thank-you-content {
    display: flex;
    width: 464px;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    > h1 {
      text-align: center;
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 54px;
      letter-spacing: -0.72px;
    }

    > p {
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }
  }
}
