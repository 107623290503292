@import 'styles/variables.less';

.br-fulfillment__process-section {
  display: flex;
  flex-direction: column;
  padding: 96px 0px;
  align-items: center;

  justify-content: center;
  p {
    margin: 0;
  }

  .br-fulfillment__process-title {
    text-align: center;
    .display-xl();
  }

  .br-fulfillment__process-album {
    position: relative;
    display: flex;
    flex-direction: row;
    margin-top: 64px;
    gap: 32px;
    align-items: center;
    justify-content: center;
    padding: 0 47.5px;

    .background_vector {
      position: absolute;
    }

    .background_vector-ar {
      position: absolute;
      transform: scaleX(-1);
    }

    .br-fulfillment__process-info {
      position: relative;

      img {
        width: 100%;
      }

      p {
        position: absolute;
        justify-content: center;
        bottom: -5%;
        margin-bottom: 32px;
        left: 50%;
        transform: translate(-50%, -50%);
        color: @white;
        .display-lg();
      }
    }
  }
}

@media only screen and (max-width: @sm) {
  .br-fulfillment__process-section {
    padding: 40px 16px;
    align-items: center;
    justify-content: center;

    .br-fulfillment__process-title {
      .display-sm();
    }

    .br-fulfillment__process-album {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 24px;
      gap: 24px;
      padding: 0 8px;

      .background_vector {
        transform: rotate(90deg);
      }
      .background_vector-ar {
        transform: rotate(-90deg);
      }

      .br-fulfillment__process-info {
        img {
          width: 100%;
        }

        p {
          position: absolute;
          display: flex;
          justify-content: center;
          left: 50%;
          transform: translate(-50%, -50%);
          color: @white;
          .display-lg();
        }
      }
    }
  }
}
