@import 'styles/variables';

.br-rate-delivery-service__modal {
  .ant-modal {
    max-height: calc(100% - 200px);
  }

  .ant-rate-star-full {
    svg > * {
      fill: @yellow-300;
    }
  }

  .ant-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .ant-form-item {
    min-height: auto;
  }

  .ant-form-item > .ant-form-item-label > label {
    display: flex;
    align-items: center;
  }
}

.br-rate-delivery-service__delivery-details__date {
  color: @text-gray;
}
