.br-solutions-hero .br__hero__content-svg {
  &.svg-main {
    right: 0;
    top: 0;
  }

  &.svg-1 {
    bottom: 20px;
    right: 500px;
    top: 500px;
  }

  &.svg-2 {
    top: 80px;
    right: 506px;
  }
}

.br-solutions-hero .r-hex-inner-2:before {
  background: url(../../../../assets/images/Solutions1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 30%;
}

@media only screen and (max-width: 991px) {
  .br-solutions-hero {
    & .br-hero-header {
      margin-bottom: 60px;
    }
  }

  .br-solutions-hero .br__hero__content-svg {
    &.svg-1 {
      bottom: 20px;
      right: unset;
      left: -10px;
      top: 500px;
      & > svg {
        max-width: 160px;
      }
    }

    &.svg-2 {
      top: unset;
      right: unset;
      left: 40px;
      bottom: 120px;

      & > svg {
        max-width: 75px;
      }
    }
  }
}
