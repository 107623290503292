@import 'styles/fonts-variables.less';

.br-download-app-section {
  padding: 70px 110px;
  margin-top: 340px;
  position: relative;
  z-index: 10;
}

.br-download-app-seciton-content {
  flex-direction: column;
}

.br-download-app-section-title {
  font-family: @font-regular-1;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  margin-bottom: 30px;
  margin-top: 68px;
  width: 40%;
  text-align: center;
}

.br-download-app-section-svg {
  position: relative;
  justify-content: center;

  & .main-svg {
    position: absolute;
    top: -300px;
    z-index: -1;
  }
}
@media only screen and (max-width: 991px) {
  .br-download-app-section {
    padding: 0 30px;
  }

  .br-download-app-section-title {
    width: 100%;
  }
}
