@import 'styles/variables';

.br-international-shipping__features-container {
  display: flex;
  align-items: center;
  gap: 48px;
  padding: 72px 80px;
}

.br-international-shipping__features-list {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.br-international-shipping__features-list__item {
  display: flex;
  align-items: flex-start;
  gap: 21px;
}

.br-international-shipping__features-list__item-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.br-international-shipping__features-list__subtitle {
  color: @gray-600;
}

.br-international-shipping__features-list__item-icon {
  min-width: 60px;
  min-height: 60px;
  background-color: @white;
  box-shadow: @shadow-sm;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: @lg) {
  .br-international-shipping__features-container {
    flex-direction: column;
    padding: 12px 16px;

    .br-international-shipping__features-content,
    .br-international-shipping__features-content > img {
      max-width: 100%;
    }
  }
}
