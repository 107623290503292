@import './fonts-classes.less';

.font(@rules) {
  @rules();
}

@text-color: @text-gray-dark;

@gray-25: #fcfcfd;
@gray-50: #f9fafb;
@gray-100: #f2f4f7;
@gray-200: #e4e7ec;
@gray-300: #d0d5dd;
@gray-400: #98a2b3;
@gray-500: #667085;
@gray-600: #475467;
@gray-650: #4f5665;
@gray-700: #344054;
@gray-800: #1d2939;
@gray-900: #111619;

@red-25: #fffbfa;
@red-50: #fef3f2;
@red-100: #fee4e2;
@red-200: #fecdca;
@red-300: #fda29b;
@red-400: #f97066;
@red-500: #f04438;
@red-600: #e30613;
@red-700: #b42318;

@red-800: #7a271a;
@red-900: #330000;

@teal-25: #fafdfe;
@teal-50: #f3fafb;
@teal-100: #ecfafc;
@teal-200: #d9f4f8;
@teal-300: #9fdee4;
@teal-400: #1dbdcd;
@teal-500: #0098a5;
@teal-600: #137a84;
@teal-700: #0b5d66;
@teal-800: #14494e;
@teal-900: #083136;

@yellow-25: #fffcf5;
@yellow-50: #fffaeb;
@yellow-100: #fef0c7;
@yellow-200: #fedf89;
@yellow-300: #fec84b;
@yellow-400: #fdb022;
@yellow-500: #f79009;
@yellow-600: #dc6803;
@yellow-700: #b54708;
@yellow-800: #93370d;
@yellow-900: #7a2e0e;

@green-25: #f6fef9;
@green-50: #ecfdf3;
@green-100: #d1fadf;
@green-200: #a6f4c5;
@green-300: #6ce9a6;
@green-400: #32d583;
@green-500: #12b76a;
@green-600: #039855;
@green-700: #027a48;
@green-800: #05603a;
@green-900: #054f31;

@blue-25: #f5faff;
@blue-50: #eff8ff;
@blue-100: #d1e9ff;
@blue-200: #b2ddff;
@blue-300: #84caff;
@blue-400: #53b1fd;
@blue-500: #2e90fa;
@blue-600: #1570ef;
@blue-700: #175cd3;
@blue-800: #1849a9;
@blue-900: #194185;

@black: #000000;
@white: #ffffff;

//Tokens

@gradient: linear-gradient(3.76deg, @teal-100 -0.32%, @teal-25 100%);
@header-gradient: linear-gradient(
  180deg,
  rgba(@teal-400, 0) 0%,
  rgba(@teal-400, 0.1) 100%
);

//Brand
@bosta-red: @red-600;
@deep-teal: @teal-500;
@light-aqua: @teal-400;

//Text

@text-gray-dark: @gray-900;
@text-gray: @gray-500;
@text-gray-light: @gray-400;
@text-gray-link: @gray-650;
@text-white: @white;
@text-teal: @teal-500;
@text-red: @red-600;
@text-red-light: @red-400;
@text-red-dark: @red-900;
@text-green-dark: @green-900;
@text-blue-dark: @blue-900;

//Background
@bg-gray-light: @gray-50;
@bg-gray: @gray-200;
@bg-gray-dark: @gray-800;
@bg-teal-light: @teal-50;
@bg-red-light: @red-100;
@bg-overlay-dark: rgba(@gray-500, 0.6);
@bg-hovered: @gray-50;
@bg-pressed: @gray-100;
@bg-selected: @teal-100;
@bg-disabled: @gray-100;

//Icons
@icon-default: @gray-500;
@icon-subdued: @gray-400;
@icon-disabled: @gray-300;
@icon-red: @red-400;
@icon-red-dark: @red-700;
@icon-green: @green-600;
@icon-teal: @teal-500;
@icon-yellow: @yellow-600;
@icon-green-dark: @green-600;
@icon-yellow-dark: @yellow-600;

//Borders
@border-all-sides: inset 1px 0px 0px @gray-200, inset -1px 0px 0px @gray-200,
  inset 0px -1px 0px @gray-200, inset 0px 1px 0px @gray-200;
@border-top-bottom: inset 0px -1px 0px @gray-200, inset 0px 1px 0px @gray-200;
@border-right-left: inset 1px 0px 0px @gray-200, inset -1px 0px 0px @gray-200;
@border-top: inset 0px 1px 0px @gray-200;
@border-bottom: inset 0px -1px 0px @gray-200;
@border-right: inset -1px 0px 0px @gray-200;
@border-left: inset 1px 0px 0px @gray-200;

//scrollbar
@scrollbar-thumb: #cbd5e0;

// box shadow
@box-shadow: #0000001a;

@shadow-xs: 0px 1px 2px rgba(@gray-800, 0.1);
@shadow-xs-green: 0px 1px 2px rgba(@gray-800, 0.1), 0px 0px 0px 4px @teal-200;
@shadow-xs-red: 0px 1px 2px rgba(@teal-200, 0.05), 0px 0px 0px 4px @red-100;
@shadow-sm: 0px 0px 1px 1px @gray-200, 0px 4px 4px rgba(@gray-800, 0.05);
@shadow-md: 0px 2px 4px rgba(@gray-800, 0.05), 0px 4px 16px rgba(@gray-800, 0.1);
@shadow-lg: 0px 0px 3px rgba(@gray-800, 0.1), 0px 4px 20px rgba(@gray-800, 0.15);
@shadow-xl: 0px 0px 4px rgba(@gray-800, 0.1), 0px 8px 40px rgba(@gray-800, 0.2);

@2xs: 328px;
@xs: 576px;
@sm: 768px;
@md: 992px;
@lg: 1280px;
@xl: 1440px;

.font(@rules) {
  @rules();
}

.responsive(@maxWidth; @rules) {
  @media only screen and (max-width: @maxWidth) {
    @rules();
  }
}
