@import 'styles/variables.less';

.br-content-header {
  display: flex;
  justify-content: space-between;
}

.br-content-header__titles {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.br-sidebar-content__title {
  color: @text-gray-dark;
}

.br-sidebar-content__sub-title {
  color: @text-gray;
}

.br-content-header__with-title-only {
  margin-bottom: 15px;
}

.br-sidebar-content-header-with-sub-title {
  margin-bottom: 16px;
}

.br-content-header-with-sub-title {
  margin-bottom: 30px;
}

.br-content-header-with-sub-title,
.br-sidebar-content-header-with-sub-title,
.br-sidebar-content__sub-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.br-content-header__title {
  display: flex;
  justify-content: space-between;
  color: @text-gray-dark;
}

.br-content-header__back-button.ant-btn {
  border: none;
  box-shadow: none;
}

html[dir='rtl'] .br-content-header__back-button.ant-btn {
  transform: rotate(180deg);
}
