@import 'styles/variables.less';

.br-pricing-calculator-form__form-row {
  display: flex;
  gap: 12px;
}

.br-pricing-calculator-form {
  & .ant-input-number.ant-input-number-in-form-item {
    width: 100%;
    border-radius: 4px;
  }
}

.br-pricing-calculator-form__size-option-content {
  display: flex;
  gap: 12px;
  & > div {
    display: flex;
    flex-direction: column;
    &>span:first-child {
        display: flex;
        align-items: center;
        & svg{
          width: 10px;
          & path{
              fill: @gray-400;
          }
        
        }
      }
    & > span:last-child {
      color: @gray-500;
    }
  }
}

.br-pricing-calculator-form__package-size-form-item {
  & label {
    width: 100%;
  }
}

.br-pricing-calculator-form__package-size-label {
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > span:last-child {
    color: @teal-500;
    cursor: pointer;
  }
}

.br-pricing-calculator-form__submit-button.ant-btn {
  width: 100%;
  margin-top: 10px;
}

.br-package-guide-modal.ant-modal{
  width: 100%;
    border: none;
      box-shadow: none;
& .ant-modal-content {
  background-color: transparent;
  border: none;
  box-shadow: none;
  padding: 0;
    & .ant-modal-body {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
    }
  }
}

@media only screen and (max-width: @md) {
  .br-package-guide-modal.ant-modal {
      & .ant-modal-content {
        height: 100%;
        overflow: hidden;
       
      }
    }
}