@import 'styles/variables.less';

.br-success-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 32px 40px;
  max-width: 520px;
  box-shadow: @shadow-md;
  background-color: @white;
  height: fit-content;
  border-radius: 12px;

  > svg {
    margin-bottom: 20px;
  }

  .display-xs {
    margin-bottom: 16px;
  }

  .subtitle-md {
    margin-bottom: 32px;
  }

  .br-button-component.ant-btn {
    display: flex;
    justify-content: space-between;

    .br-button-component__label {
      .font({.body-medium()});
    }

    .br-success-card__copy-link {
      display: flex;
      align-items: center;
      gap: 8px;

      svg path {
        fill: none;
      }
    }
  }
}
