@import 'styles/variables.less';

.br-available-dates__label {
  font-weight: 600;
  margin-bottom: 16px;
}

.ant-radio-group.ant-radio-group-outline.br-available-dates {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;

  .ant-radio-button-wrapper {
    min-width: 86px;
    height: auto;
    padding: 8px;
    border-radius: 4px;
    border-width: 1px;
    text-align: center;

    &:not(:first-child)::before {
      content: none;
    }

    .br-available-dates__date-day span {
      display: block;
      color: initial;
    }

    .ant-radio-button {
      border-radius: inherit;

      &.ant-radio-button-checked {
        background-color: @bg-teal-light;
        border-color: @teal-500;
      }
    }
  }
}
