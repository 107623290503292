@import 'styles/variables.less';
@import 'styles/fonts-variables.less';
@fulfillmentHeroImage: '../../assets/images/fulfillment-landing-hero.png';

.br-fulfillment__hero-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 201px 275px;
  background-image: url(@fulfillmentHeroImage);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  > div > p {
    margin: 0;
  }

  .br-fulfillment__hero-content {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 32px;
    max-width: 874px;
    width: 100%;
    z-index: 10;

    .br-fulfillment__hero-section-header {
      width: 100%;
      color: @white;
      .display-xl();
    }

    .br-fulfillment__hero-section-description {
      padding: 0 105px;
      color: @white;
      .display-md();
    }

    button {
      padding: 12px 24px;
      .button-lg();
    }

    .br-fulfillment__hero-submit-button {
      border-radius: 8px;
    }
  }
}

@media only screen and (max-width: @lg) {
  .br-fulfillment__hero-section {
    padding: 201px 16px;

    .br-fulfillment__hero-content {
      max-width: 1000px;
      max-height: 160px;
      width: 100%;

      .br-fulfillment__hero-section-description {
        padding: 0 75px;
      }
    }
  }
}

@media only screen and (max-width: @md) {
  .br-fulfillment__hero-section {
    padding: 150px 20px;

    .br-fulfillment__hero-content {
      .br-fulfillment__hero-section-header {
        .display-lg();
      }

      .br-fulfillment__hero-section-description {
        padding: 0 90px;
        .display-sm();
      }
    }
  }
}

@media only screen and (max-width: @sm) {
  .br-fulfillment__hero-section {
    padding: 44px 16px;
    margin: 0;

    .br-fulfillment__hero-content {
      max-width: 343px;
      max-height: 160px;
      width: 100%;
      gap: 8px;

      .br-fulfillment__hero-section-header {
        .display-xs();
      }

      .br-fulfillment__hero-section-description {
        padding: 0 36px;
        .caption-lg();
      }

      button {
        margin-top: 8px;
      }
    }
  }
}
