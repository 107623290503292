@import 'styles/variables.less';
@import 'styles/fonts-variables.less';

.br-pricing-hero {
  & .svg-main {
    right: 0;
    top: 0;
  }

  & .svg-1 {
    top: unset;
    left: unset;
    bottom: 10px;
  }

  & .svg-2 {
    right: 800px;
    top: 90px;
  }

  .r-hex-inner-2:before {
    background: url(../../assets/images/Pricing-hexagon.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 97%;
    background-size: 60em 798px;
  }
}

.br_sa_container .br-pricing .r-hex-inner-2:before {
  background: url(../../assets/images/Princing-KSA-Hero.jpg);
  background-size: 60em 796px;
  background-position: 97%;
}

.br-pricing-info-section {
  position: relative;
  margin-top: 162px;
  display: flex;
  justify-content: space-between;
  padding-right: 10%;
  .br-pricing-info-section__left-section {
    margin-left: 16%;
  }
}

.br-pricing__pay-as-you-go-container {
  width: 100%;
  max-width: 583px;
  margin-bottom: 50px;
}

.br-pricing-info-section-header {
  margin-bottom: 50px;
}

.br-pricing-info-section__pay-as-you-go-header {
  margin-bottom: 14px;
}

.br-pricing-info-section__svgs {
  min-width: 50%;
  div {
    position: absolute;
  }
  .svg-1 {
    left: 259px;
    bottom: 146px;
    z-index: 1;
  }
  .svg-2 {
    left: 0;
    bottom: 142px;
    z-index: 3;
  }
  .svg-3 {
    left: 38px;
    bottom: 143px;
    z-index: 4;
  }
  .svg-4 {
    left: 100px;
    bottom: 143px;
    width: 224px;
    height: 275px;
    z-index: 2;
  }
  .svg-5 {
    left: 402px;
    bottom: 184px;
    z-index: 2;
  }
  .svg-6 {
    left: 411px;
    bottom: 165px;
    z-index: 2;
  }
  .svg-7 {
    left: 406px;
    bottom: 146px;
    z-index: 2;
  }
  .svg-8 {
    left: 505px;
    bottom: 146px;
  }
  .svg-9 {
    left: 98px;
    bottom: 436px;
    width: 309px;
    height: 203px;
  }
  .svg-10 {
    left: 430px;
    bottom: 494px;
  }
}

html[dir='rtl'] .br-pricing-info-section__svgs div {
  transform: scaleX(-1);
}

.br-pricing__not-settled-title {
  color: @gray-600;
  margin-bottom: 5px;
}

.br-pricing__not-settled-sub-title {
  color: @gray-600;
}

.br-pricing-info__logo-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 583px;
  gap: 30px;
}

.br-pricing-info__logo-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  min-width: 160px;
}

.br-pricing-info__logo-container.br-pricing-info__second-logo-container {
  width: 100%;
  max-width: calc(583px / 1.66);
  margin-top: 51px;
}

.ant-btn.br-pricing-info-section-btn {
  margin-top: 50px;
  padding: 17px 35px;
  width: fit-content;
}

.br-pricing-info-pricing-table-section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 100px;
  .display-sm {
    max-width: 50%;
    text-align: center;
  }
}

.ant-table-wrapper.br-pricing__pricing-table {
  width: 100%;
  max-width: 872px;
  & .ant-table-content {
    & .ant-table-thead {
      & tr {
        & .ant-table-cell {
          border-left: 0;
          border-bottom: 2px solid @gray-500;
          font-size: 16px;
          font-family: @font-bold-1;
          color: @gray-600;
        }
      }
    }
    & .ant-table-tbody {
      & tr {
        & .ant-table-cell {
          border-left: 0;
          border-bottom: 1px dotted @gray-500;
          font-size: 16px;
          font-family: @font-regular-2;
          color: @gray-600;
        }
      }
      & tr.remove-border {
        & .ant-table-cell {
          border-bottom: none;
        }
      }
      & tr.add-border-bottom {
        & .ant-table-cell {
          border-bottom: 1px solid @teal-500;
        }
      }

      & tr.add-border-top {
        & .ant-table-cell {
          border-top: 1px solid @teal-500;
        }
        & .ant-table-cell:first-child {
          border-left: 1px solid @teal-500;
          border-top-left-radius: 10px;
        }
        & .ant-table-cell:last-child {
          border-right: 1px solid @teal-500;
          border-top-right-radius: 10px;
        }
      }
      & tr.add-border-bottom {
        & .ant-table-cell:first-child {
          border-left: 1px solid @teal-500;
          border-bottom-left-radius: 10px;
        }
        & .ant-table-cell:last-child {
          border-right: 1px solid @teal-500;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }
}

.ant-table-wrapper.br-pricing__pricing-table
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: white;
}

.br-pricing-info__pricing-estimate-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(/src/assets/images/pricing-estimate-background.svg);
  background-repeat: no-repeat;
  background-position: top;
  margin-top: 100px;
  padding: 32px 0 150px;
}

.br-pricing__trusted-by-container {
  margin-top: 90px;
}

.br-pricing .ant-form {
  margin-top: 50px;
  border-bottom: 2px dotted @gray-500;
  padding-bottom: 30px;
  margin-bottom: 35px;
}

.br-pricing .ant-form input.ant-input {
  border: 1px solid @gray-200;
  height: 60px;
  border-radius: 10px;
  width: 234px;
}

.br-pricing .ant-form .ant-form-item-control-input-content {
  margin-right: 20px;
}

.br-pricing .ant-form .ant-form-item-control-input-content:last-child {
  margin-right: 0px;
}

.br-pricing .ant-form.ant-form-inline .ant-form-item:last-child {
  margin-right: 0;
}

.br-pricing__estimated-value-result-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 114px;
  & div {
    display: flex;
    flex-direction: column;
    margin-left: 35px;
  }
}

.br-pricing__trusted-by-container {
  & h3 {
    margin-bottom: 50px;
  }
}

.br-pricing__trusted-by-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 110px;
  & .ant-carousel {
    width: 100vw;
    & .slick-list {
      height: 390px;
    }
  }
}

.br-pricing-carousel-card-container {
  width: 100%;
  max-width: 266px;
  box-shadow: 0px 20px 20px @box-shadow;
  border-radius: 20px;
  background-color: white;
  padding: 23px;
  margin: 0 51px;
  height: 290px;
  max-height: 290px;
  & p {
    margin-bottom: 0;
    color: @gray-600;
  }
  & .br-pricing-carousel-card-header {
    margin-bottom: 23px;
    & h4 {
      color: @gray-600;
      margin-bottom: 0;
    }
    & h4:first-child {
      margin-bottom: 2px;
    }
  }
}

@media only screen and (max-width: @lg) {
  .br-pricing-info-section {
    padding-right: 0;
  }
}

@media only screen and (max-width: 991px) {
  .br-pricing-info-pricing-table-section {
    padding: 0 30px;
    text-align: center;
  }

  .br-pricing-info__pricing-estimate-section {
    & .br-testimonial-carousel-container {
      margin-top: 40px;
    }
  }

  .br-pricing {
    .br__hero__content-svg {
      &.svg-main {
        margin-top: 60px;
        right: -20%;
        & > img {
          max-width: 100%;
        }
      }

      &.svg-1 {
        left: 0;
        bottom: 130px;

        & > svg {
          max-width: 160px;
        }
      }

      &.svg-2 {
        right: unset;
        top: unset;
        left: 50px;
        bottom: -50px;
      }
    }
  }

  .br-pricing-info__logo-container {
    justify-content: space-around;
  }

  .br-pricing-info__logo-content {
    min-width: 40%;
    align-items: center;
  }

  .br-pricing-info-section {
    margin-top: 72px;
    padding: 0 30px;
    align-items: center;
    text-align: center;
  }

  .br-pricing-info-section__svgs {
    .br-pricing-info-section__main-image {
      position: unset;
    }
  }

  .ant-btn.br-pricing-info-section-btn {
    margin: 50px auto 0;
  }

  .br-pricing-info-section__svgs {
    display: none;
  }
}
