@import 'styles/variables';

.br-international-shipping__header-container {
  display: flex;
  align-items: center;
  gap: 32px;
  padding: 72px 129px;
  background: linear-gradient(180deg, @white 0%, @teal-100 100%);

  > img {
    max-width: 478px;
  }
}

.br-international-shipping__header-content,
.br-internatioanl-shipping__header-features {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.br-internatioanl-shipping__header-features > div {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.br-internatioanl-shipping__header-features span {
  color: @text-gray;
}

.br-international-shipping__header-check__icon.anticon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: @red-100;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  svg > * {
    fill: @red-500;
  }
}

.br-international-shipping__header-content .ant-btn {
  max-width: 100px;
}

@media only screen and (max-width: @lg) {
  .br-international-shipping__header-container {
    flex-direction: column-reverse;
    padding: 12px 16px;

    > img {
      max-width: 100%;
    }
  }
}
