@import 'styles/variables.less';

.br-blog-articles__article-title {
  text-align: flex-start;
  margin-top: 10px;

  a {
    color: @gray-600;
  }
}

.br-blog-articles__circle {
  display: block;
  height: 5px;
  width: 5px;
  margin: 0 3px;
  background-color: @gray-600;
  border-radius: 50%;
}

.br-blog-articles__article-info,
.br-blog-article,
.br-blog-articles__article-image,
.br-blog-articles {
  display: flex;
}

.br-blog-articles {
  gap: 20px;
  flex-wrap: wrap;

  .br-blog-article {
    width: fit-content;
    min-width: 20%;
    max-width: 23%;
  }
}

.br-blog-articles__article-info {
  align-items: center;
}

.br-blog-articles__article-info__by {
  margin-right: 5px;
}

.br-blog-article {
  width: 468px;
  flex-direction: column;
}

.br-blog-articles__article-image {
  max-width: 100%;
  justify-content: center;
  transition: transform 0.2s;
  img {
    width: 100%;
  }
}

.br-blog-articles__edit-article {
  margin-left: 10px;
}

@media only screen and (max-width: 991px) {
  .br-blog-articles__article-image img {
    max-width: 100%;
  }
}
