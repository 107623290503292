.br-dashboard-features-container {
  padding: 60px 110px;
  margin-top: 50px;
}

.br-dashboard-features-content {
  position: relative;
}

.br-dashboard-features-title {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
}

.br-dashboard-features {
  margin-top: 50px;
}

.br-dashboard-features-main-img {
  margin-top: -100px;
}

@media only screen and (max-width: 991px) {
  .br-dashboard-features-container {
    padding: 60px 30px;
  }
  .br-dashboard-feature-content {
    flex-direction: column-reverse;
    &.right {
      flex-direction: column-reverse;
    }
  }

  .br-dashboard-feature-description {
    text-align: center;
    margin: 0;
  }

  .br-dashbaord-feature-image {
    & > img {
      max-width: 100%;
    }
  }

  .br-dashboard-features-content {
    margin-top: 20px;
  }

  .br-dashboard-features-main-img {
    display: none;
  }
}
