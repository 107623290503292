@import 'styles/variables.less';

.br-shipment-details__tracking-timeline.ant-timeline {
  color: @text-gray-dark;
  & li.ant-timeline-item:last-child {
    padding-bottom: 0;
  }

  & li.ant-timeline-item {
    padding-bottom: 16px;
    & .ant-timeline-item-head {
      width: 16px;
      height: 16px;
      background-color: @gray-300;
      border: none;
    }
    & .ant-timeline-item-tail {
      display: block;
      left: 7px;
      margin-top: 12px;
      height: calc(100% - 28px);
    }
    & .ant-timeline-item-content {
      margin-left: 28px;
      & .br-shipment-details_tracking-status {
        padding-top: 6px;
        margin-bottom: 10px;
        color: @text-gray-dark;
      }
    }
  }
}

.br-shipment-details__tracking-log {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  border: 1px solid @gray-200;
  width: fit-content;
  padding: 8px 16px;
  margin-bottom: 16px;
}
