@import 'styles/variables.less';

.br-capital__testimonial-client-card {
  display: flex;
  width: 570px;
  height: 271px;
  padding: 40px;
  align-items: center;
  gap: 30px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid @gray-200;
  background: @white;
  box-shadow: @shadow-xs;

  & .br-capital__client-info {
    display: flex;
    width: 180px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 16px;

    > img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 50%;
    }

    p.client-business {
      font-size: 21px;
      font-weight: 600;
      line-height: 130%;
      margin-bottom: 0;
    }

    p.client-name {
      font-size: 14px;
      font-weight: 400;
      line-height: 160%;
    }
  }

  & .br-capital__client-opinion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex: 1 0 0;

    > .br-capital__tetsimonials-rate {
      display: flex;
      gap: 5px;
    }

    > p {
      color: @gray-900;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
    }
  }

  @media only screen and (max-width: @sm) {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    padding: 16px;
    gap: 30px;
    border-radius: 20px;
    border: 1px solid @gray-200;
    background: @white;
    box-shadow: @shadow-xs;
  }
}
