@import 'styles/variables';

.br-rate-confirmation__modal {
  .ant-modal {
    max-width: 400px;
  }

  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;
    gap: 20px;

    > span {
      text-align: center;
    }

    > .ant-btn {
      width: 100%;
      margin-top: 12px;
    }
  }
}

.br-rate-confirmation-modal__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: @green-100;
  border: 8px solid @green-50;

  > svg > * {
    stroke: @icon-green;
  }
}
