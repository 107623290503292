@import 'styles/variables.less';

.br-bosta-insights-hero {
  overflow: hidden;

  .r-hex {
    margin: 3.5em 0;
    margin-right: -119px;
  }

  .br-hero-header {
    max-width: 50%;
  }

  .svg-1 {
    z-index: 1;
    right: 31%;
  }

  .br__hero__content-svg.svg-2 {
    top: 0;
  }

  .r-hex-inner-2:before {
    background: url(../../assets/images/insights-cover.jpeg);
    background-size: 69em 767px;
    background-repeat: no-repeat;
  }

  .br__hero__content-svg.svg-2 {
    right: 61%;
  }

  .br-header {
    max-width: 57%;
  }

  div.br-header__title {
    .font({.signup-sm()});
  }

  h1.br-header__title {
    margin: 18px 0 20px;
  }

  .br-header__subtitle {
    .font({.header-title()});
    color: @gray-900;
  }
}

.br-bosta-insights__description {
  color: @text-gray;
}

@media only screen and (max-width: @md) {
  .br-bosta-insights-hero {
    .br-hero-header {
      margin-bottom: 10px;
    }

    .br-header,
    .br-hero-header {
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: @xs) {
  .br-bosta-insights-hero {
    .r-hex-inner-2:before {
      margin-top: -113px;
      background-size: 40em 767px;
    }

    .svg-1 {
      right: 50px;
    }
  }
}
