@import 'styles/variables.less';
@import 'styles/fonts-variables.less';

.br-solutions-more-solutions-container {
  padding: 100px 110px;
  position: relative;
  z-index: 1;
}

.br-solutions-more-solutions-content {
  display: flex;
  flex-direction: row-reverse;
}

.br-solutions-more-solutions-subtitle {
  font-size: 20px;
  font-weight: 400;
  font-family: @font-regular-1;
  margin-top: 20px;
  margin-bottom: 0;
  color: @gray-600;
}

.br-solutions-more-solutions-content-grid {
  display: flex;
  width: 50%;
  flex-direction: column;

  & .br-grid {
    flex-wrap: nowrap;
    flex-direction: column;
  }
}

.br-solutions-more-solutions-svg {
  position: absolute;
  left: 45px;
  top: 42px;
}

@media only screen and (max-width: 991px) {
  .br-solutions-more-solutions-container {
    padding: 100px 30px;
    text-align: center;
  }

  .br-solutions-more-solutions-content {
    flex-direction: column;

    &-grid {
      width: 100%;
    }
  }

  .br-solutions-more-solutions-svg {
    position: relative;
    top: unset;
    left: unset;

    & > img {
      max-width: 300px;
    }
  }
}
