@import 'styles/variables';

.br-smart-shipping__header {
  display: grid;
  grid-template-columns: 1fr max-content 1fr;
  align-items: center;
  width: 80%;
  margin: auto;
  height: 144px;
  font-size: 42px;
  font-weight: 700;

  &::before,
  &::after {
    content: '';
    display: block;
    height: 2px;
    background: linear-gradient(90deg, #e30613 11.55%, #1dbdcd 91.79%);
  }
}

.br-smart-shipping__content {
  height: 560px;
  border-top-right-radius: 250px;
  position: relative;
  overflow: hidden;
  background: linear-gradient(180deg, #fffafa 29.73%, #fff 100%);
  display: grid;
  grid-template-columns: 45% 45%;
  grid-column-gap: 10%;
  grid-row-gap: 56px;
  padding: 120px 104px;

  > img {
    position: absolute;
    right: 0;
    top: 0;
    opacity: 0.5;
  }
}

.br-smart-shipping__element {
  display: flex;
  flex-direction: column;
  gap: 17px;

  > :first-child {
    color: @teal-400;
  }
}

@media only screen and (max-width: @sm) {
  .br-smart-shipping__header {
    font-size: 16px;
    grid-column-gap: 8px;
    width: 100%;
    height: 60px;
    &::before,
    &::after {
      content: '';
      display: block;
      height: 2px;
      background: linear-gradient(90deg, @red-600 11.55%, @teal-400 91.79%);
    }
  }

  .br-smart-shipping__content {
    padding: 16px 24px;
    grid-template-columns: 100%;
    grid-row-gap: 20px;
    border-top-right-radius: 100px;
    height: fit-content;
  }

  .br-smart-shipping__element {
    gap: 4px;

    > :first-child {
      color: @teal-400;
    }
  }
}
