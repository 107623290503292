@import 'styles/variables.less';
@import 'styles/fonts-variables.less';

.br__vision__section {
  padding: 110px;
  margin-bottom: 60px;
  position: relative;
  z-index: 1;

  &-container {
    display: flex;
    position: relative;
  }
}

.br__vision__section-content {
  flex-grow: 1;
  flex-basis: 0;
  position: relative;
}

.br__vision__content-title {
  font-size: 30px;
  font-weight: 600;
}

.br__vision__content-stats {
  font-family: @font-regular-1;
  font-size: 20px;
  font-weight: 400;
  width: 240px;

  & .subtitle-bold {
    display: inline-block;
    font-weight: 700;
  }
}

.br__vision__content-subtitle {
  color: @gray-600;
  margin-top: 20px;
}

.br-vision-content__cta {
  margin-top: 28px;
}

.br__vision__section-svg {
  position: absolute;

  &.main-svg {
    left: -110px;
    top: -250px;
    z-index: -1;
  }

  &.business-svg {
    left: 100px;
    top: 120px;
    z-index: 1;
  }

  &.parcels-svg {
    left: 280px;
    top: -70px;
  }

  &.business-and-parcels-svg {
    display: none;
  }
}

@media only screen and (max-width: 991px) {
  .br__vision__section {
    padding: 100px 30px;
    text-align: center;

    &-container {
      flex-direction: column-reverse;
    }
  }

  & .br__vision__content-stats {
    width: 100%;
    margin-top: 100px;
  }

  .br__vision__section-svg {
    position: relative;
    &.main-svg {
      display: none;
    }

    &.business-svg,
    &.parcels-svg {
      display: none;
    }

    &.business-and-parcels-svg {
      display: block;
    }
  }
}
