@import 'styles/variables.less';

.br-home__header {
  position: relative;
  background: @header-gradient;
  z-index: 2;

  &.br-hero-main {
    padding: 140px 110px;
  }
}

.br-home__header {
  & .br__hero__content-svg {
    position: absolute;
    &.svg-main {
      right: 0;
      top: 0;
    }
    &.svg-1 {
      right: 0;
      top: 432px;
      z-index: 1;
    }

    &.svg-2 {
      top: 260px;
      right: 700px;
    }

    &.svg-3 {
      right: 704px;
      top: 453px;
      z-index: 2;
    }

    &.svg-4 {
      right: 491px;
      top: 340px;
      z-index: 1;
    }

    &.svg-5 {
      right: 472px;
      top: 340px;
    }
  }
}

.br-home__header-container {
  width: 499px;
}

.br-home__delivery-services {
  position: relative;
  padding: 97px 0 73px 110px;
  z-index: 1;
}

.br-home__delivery-services {
  .br-home__delivery-services-right-svg {
    position: absolute;
    &.delayed-animation {
      overflow: hidden;
    }
    &.svg-1 {
      right: 455px;
      top: 626px;
      z-index: 4;
    }
    &.svg-2 {
      right: 318px;
      top: 467px;
      z-index: 3;
    }
    &.svg-3 {
      right: 148px;
      top: 468px;
      z-index: 2;
    }
    &.svg-4 {
      right: 189px;
      top: 682px;
      z-index: 3;
    }
    &.svg-5 {
      right: 79px;
      top: 579px;
      z-index: 2;
    }
    &.svg-6 {
      right: 0;
      top: 378px;
    }
    &.svg-7 {
      right: 350px;
      top: 282px;
    }
    &.svg-8 {
      right: 552px;
      top: 432px;
      z-index: -1;
    }
    &.svg-9 {
      right: 292px;
      top: 219px;
      z-index: -1;
    }
    &.svg-10 {
      right: 200px;
      top: 303px;
      z-index: -1;
    }
  }
}

html[dir='rtl'] .br-home__delivery-services-right-svg,
html[dir='rtl'] .br-home__prices-img {
  transform: scaleX(-1);
}

.br-home__delivery-services__services {
  width: 583px;
  .br-grid-item:first-child {
    .anticon {
      svg path {
        fill: @red-600;
      }
    }
  }
}

.br-home__success-numbers {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-top: 124px;
  padding: 0 278px;

  .br-home__success-numbers__cards {
    display: flex;
    justify-content: space-between;

    div:not(:last-child) {
      margin-right: 37px;
    }

    div:nth-child(3) {
      padding-top: 61px;
    }

    div {
      z-index: 2;
      padding-top: 74px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-repeat: no-repeat;
      width: 193px;
      height: 275px;
      color: @white;
      cursor: default;
      span:first-child {
        .font({.display-xl()});
      }
      span:last-child {
        .font({.display-sm()});
      }
    }

    div:first-child {
      margin-top: 67px;
      width: 193px;
      height: 275px;
      background-image: url(/src/assets/icons/HomeSucces1.svg);
      &:hover {
        background-image: url(/src/assets/icons/HomeSucces1-mobile.svg);
      }
    }

    div:nth-child(2) {
      margin-top: 133px;
      background-image: url(/src/assets/icons/HomeSucces2.svg);
      &:hover {
        background-image: url(/src/assets/icons/HomeSucces2-mobile.svg);
      }
    }

    div:nth-child(3) {
      margin-top: 47px;
      background-image: url(/src/assets/icons/HomeSucces1.svg);
      span:first-child {
        font-size: 27px;
      }
      span:last-child {
        width: 112px;
        text-align: center;
      }
      &:hover {
        background-image: url(/src/assets/icons/HomeSucces1-mobile.svg);
      }
    }

    div:nth-child(4) {
      margin-top: 83px;
      background-image: url(/src/assets/icons/HomeSucces2.svg);
      &:hover {
        background-image: url(/src/assets/icons/HomeSucces2-mobile.svg);
      }
    }
  }
}

.br-home__prices {
  display: flex;
  justify-content: flex-end;
  position: relative;
  padding-right: 112px;
  .r-hex {
    top: -140px;
    left: -140px;
    z-index: 1;
  }
  .r-hex-inner-2:before {
    background: url(../../assets/images/Home2.png);
    background-repeat: no-repeat;
    background-size: 76em 770px;
  }
}

.br-home-ksa .br-home__prices .r-hex-inner-2:before {
  background: url(../../assets/images/2.jpg);
  background-position: 30%;
}

html[dir='rtl'] .br-home-ksa .br-home__prices .r-hex-inner-2:before {
  background-position: 30%;
}

.br-home__prices-img {
  position: absolute;
  top: -205px;
  left: 0;
  z-index: 1;
}

.br-home__prices-text {
  margin-top: 189px;
  padding-left: 107px;
  width: 583px;
  .br-grid {
    margin-top: 24px;
  }
}

.br-home__prices-partners-container {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 90px 35px 0;
  margin: 190px auto 0;
  .br-grid-item {
    flex-basis: calc(50% - 16px);
  }

  .br-grid-item:first-child {
    align-items: flex-end;
    text-align: right;
    svg circle {
      fill: transparent;
    }
  }

  .br-trusted-companies-section {
    padding: 0;
    margin: 58px 0;
    .br-trusted-companies-section-logos {
      width: 80%;
    }
  }

  .br__sign-up-and-start__section {
    padding-top: 0;
  }
}

.br-home__prices-partners__img {
  position: absolute;
  top: 0;
  right: calc(50% - 327px);
}

.br-home__prices-partners__prices {
  width: 245px;
}

.br-home__prices-partners__partners {
  width: 506px;
  z-index: 1;
}

.br-home__header .r-hex-inner-2:before {
  background: url(../../assets/images/Home1.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-position: 40%;
}

.br-home-ksa .br-home__header .r-hex-inner-2:before {
  background: url(../../assets/images/1.jpeg);
  background-position: 25%;
}

html[dir='rtl'] .br-home-ksa .br-home__header .r-hex-inner-2:before {
  background-position: 65%;
}

html[dir='rtl'] .br-home__prices-partners__partners {
  width: 488px;
}

@media only screen and (max-width: 1390px) {
  .br-home__header-container {
    width: 380px;
  }
  .br-home__delivery-services-cloud {
    right: 22%;
  }
}

@media only screen and (max-width: 991px) {
  .br-home__header.br-hero-main {
    padding: 0 30px;
  }

  .br-home__header .br-hero-header {
    width: 100%;
  }

  .br-home__header {
    & .br__hero__content-svg {
      &.svg-main {
        position: relative;
        margin-top: 40px;
        right: -20%;
        & > img {
          max-width: 100%;
        }
      }

      &.svg-1 {
        top: unset;
        bottom: 0;
        right: -150px;
        & > svg {
          max-width: 500px;
          max-height: 100%;
        }
      }

      &.svg-2 {
        top: unset;
        right: unset;
        bottom: 280px;
        left: 0;
      }

      &.svg-3 {
        display: none;
      }

      &.svg-4 {
        bottom: 0;
        right: 37%;
        top: 80%;
        & > svg {
          max-width: 400px;
          max-height: 100%;
        }
      }

      &.svg-5 {
        bottom: 0;
        right: 37%;
        top: 80%;
        & > svg {
          max-width: 400px;
          max-height: 100%;
        }
      }
    }
  }

  .br-home__delivery-services {
    padding: 100px 30px;
    display: flex;
    flex-direction: column;
  }

  .br-home__delivery-services {
    .br-home__delivery-services-right-svg {
      display: none;
    }
  }

  .br-home__delivery-services-right-img {
    position: relative;
    top: unset;

    & > svg {
      max-width: 100%;
      height: 100%;
    }
  }

  .br-home__delivery-services__services {
    width: 100%;
    text-align: center;
  }

  .br-home__success-numbers {
    padding: 0 30px;
    text-align: center;

    & > .grid-title {
      font-size: 28px;
      margin-bottom: 40px;
    }
  }

  .br-home__success-numbers .br-home__success-numbers__cards {
    display: block;
    position: relative;
    height: 600px;
    width: 100%;
    div {
      position: absolute;
      width: 164px;
      height: 234px;
      background-size: contain;
      padding-top: 52px;
    }

    div:first-child {
      left: 0;
      top: 0;
      margin-top: 0;
      width: 164px;
      height: 234px;
      background-image: url(/src/assets/icons/HomeSucces1.svg);
    }

    div:nth-child(2) {
      right: 0;
      top: 50px;
      margin-top: 0;
      background-image: url(/src/assets/icons/HomeSucces2-mobile.svg);
    }

    div:nth-child(3) {
      left: 0;
      top: 247px;
      padding-top: 20px;
      margin-top: 0;
      background-image: url(/src/assets/icons/HomeSucces1-mobile.svg);
    }

    div:nth-child(4) {
      right: 0;
      top: 300px;
      margin-top: 0;
      background-image: url(/src/assets/icons/HomeSucces2.svg);
    }

    div:not(:last-child) {
      margin-right: 0;
    }
  }

  .br-home__prices {
    padding: 0 30px;
    justify-content: center;
    flex-direction: column-reverse;
    text-align: center;

    & .br-home__prices-img {
      position: relative;
      top: unset;
      left: -20%;
    }

    & .br-home__prices-text {
      width: 100%;
      padding: 0;
      margin: 0;

      & > .br-grid-title {
        font-size: 28px;
      }
    }

    & .r-hex-inner-2:before {
      background-size: 30em 321px;
    }

    .r-hex {
      top: -89px;
      right: -140px;
      left: unset;
    }
  }

  .br-home__prices-partners-container {
    padding: 100px 30px;
    margin-top: 60px;

    & .br-grid-item:first-child {
      align-items: center;
    }
  }

  .br-home__prices-partners__partners {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    & > .br-grid {
      flex-direction: column;
    }
  }

  html[dir='rtl'] .br-home__prices-partners__partners {
    width: 100%;
  }
  .br-home.br-home-ksa .br-home__prices {
    .r-hex {
      margin-top: 120px;
    }
    .r-hex-inner-2:before {
      background-size: calc(100vw - 9%) 92%;
    }
  }
}

@media only screen and (max-width: @sm) {
  .br-home-ksa .br-home__header .r-hex-inner-2:before {
    background-size: calc(100vw - -30%) 89%;
    background-repeat: no-repeat;
    background-position: 20%;
  }

  html[dir='rtl'] .br-home-ksa .br-home__header .r-hex-inner-2:before {
    background-size: calc(100vw - 14%) 81%;
  }

  .br-home.br-home-ksa .br-home__prices {
    .r-hex-inner-2:before {
      background-size: calc(100vw - 1%) 73%;
    }
  }
  html[dir='rtl'] .br-home.br-home-ksa .br-home__prices .r-hex-inner-2:before {
    background-position: 9%;
  }
}
