@import 'styles/variables.less';

.br-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: @white;
  padding: 16px 70px;
  position: fixed;
  top: 0;
  z-index: 100000000000;
  width: 100%;

  &.br-saudi-signup {
    z-index: 10;
  }

  &.tracking-page {
    background-color: @bg-teal-light;
  }
}

.anticon.br-navbar__logo {
  svg {
    width: 120px;
    height: 36px;
  }
}

.br-navbar__menu-cotainer {
  display: flex;
  justify-content: space-between;
}

.br-navbar__middle-section {
  display: flex;
  align-items: center;
  margin-left: 16px;
}

.br-navbar__right-section {
  display: flex;
  align-items: center;
}

.br-navbar__link {
  height: min-content;
  white-space: nowrap;
  color: @text-gray-link;
  margin-right: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  svg {
    margin: 1px 0 0 7px;
    width: 10px;
    height: 6px;
  }
}

.br-navbar__link.ant-dropdown-open,
.br-navbar__link:hover {
  color: @red-600;
  svg {
    transform: rotate(270deg);
    path {
      fill: @red-600;
    }
  }
}

.br-navbar__link-track-shipment {
  color: @red-600;
  svg {
    path {
      fill: @red-600;
    }
  }
}

.br-navbar__link-track-shipment.ant-dropdown-open {
  svg {
    transform: rotate(270deg);
  }
}

.br-navbar__link-sign-in {
  color: @text-gray-dark;
}

.br-navbar__link-sign-up {
  color: @red-600;
  border: 1px solid @red-600;
  padding: 13px 32px;
  border-radius: 50px;
  white-space: nowrap;
}

.br-navbar__link-sign-up:hover {
  background-color: @red-600;
  color: @white;
}

.br-navbar__link-lang {
  margin-left: 10px;
}

.br-navbar-tracking-menu-container {
  display: flex;
  flex-direction: column;
  padding: 18px;
  & h5 {
    margin-bottom: 16px;
    cursor: default;
    color: @gray-600;
  }

  & .ant-input-search.ant-input-search-with-button .ant-input-group {
    width: 286px;
    & input.ant-input {
      width: 100%;
      height: 50px;
      border: 1px solid @gray-200;
      border-radius: 7px 0px 0px 7px;
      padding: 18px;
    }
    & .ant-input-group-addon button {
      width: fit-content;
      height: 50px;
      border-radius: 0px 7px 7px 0px;
      & svg {
        width: 25px;
        height: 25px;
      }
    }
  }
}

.ant-dropdown.br-nav-bar-tracking-link-menu-container {
  top: 93px !important;
  & .ant-dropdown-menu.ant-dropdown-menu-vertical.ant-dropdown-menu-light {
    border-radius: 10px;
    border-top-right-radius: 0;
    border: 1px solid @gray-200;
    box-shadow: 0px 10px 40px @box-shadow;

    &
      .ant-dropdown-menu-item.ant-dropdown-menu-item-only-child.ant-dropdown-menu-item-active {
      background-color: white;
    }
  }
}

.ant-dropdown-trigger.br-navbar__link.br-navbar__link-track-shipment.navbar-link.ant-dropdown-open {
  background: @white;
  border: 1px solid @gray-200;
  border-bottom-color: transparent;
  box-shadow: 0 -10px 19px 2px #0000001a;
  border-radius: 10px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  z-index: 9999999;
}

.ant-dropdown-trigger.br-navbar__link.br-navbar__link-track-shipment {
  padding: 21px 28px;
  border: 1px solid transparent;
}

.br-navbar__hamburger {
  display: none;
}

.br-navbar__hamburger.br-navbar__hamburger-open {
  > div:first-child {
    transform: rotate(45deg);
  }
  > div:nth-child(2) {
    opacity: 0;
    transform: translateX(20px);
  }
  > div:last-child {
    transform-origin: 1px;
    transform: rotate(-45deg);
  }
}

html[dir='rtl'] .br-navbar__hamburger.br-navbar__hamburger-open {
  > div:first-child {
    transform: rotate(-45deg);
  }
  > div:nth-child(2) {
    transform: translateX(-20px);
  }
  > div:last-child {
    transform: rotate(45deg);
  }
}

.br-navbar__right-section__locale-link {
  display: none;
  cursor: pointer;
}

.br-navbar__right-section__locale-link-ar {
  font-family: 'Cairo/Bold';
}

.br-navbar__right-section__locale-link-en {
  font-family: 'Lato/Bold';
  font-size: 16px;
}

.br-navbar__right-section__registeration-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.br-navbar__right-section-mobile {
  display: none;
  align-items: center;
  height: 34px;
}

.br-navbar__tracking-mobile {
  .br-navbar__link {
    color: @red-600;
    display: flex;
    margin-right: 26px;
    height: 32px;
    border: 1px solid transparent;
    border-bottom: 0;
    padding: 8px;
    .anticon {
      margin-top: 2px;
      svg path {
        fill: @red-600;
      }
    }
  }
  .br-navbar__link.ant-dropdown-open {
    border: 1px solid @gray-200;
    border-bottom: 0;
    box-shadow: 0 -10px 19px 2px #0000001a;
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .ant-dropdown.br-nav-bar-tracking-link-menu-container {
    top: 63px !important;
  }
  .br-navbar-tracking-menu-container {
    padding: 0;
    max-width: 260px;
    .ant-input-search.ant-input-search-with-button .ant-input-group {
      width: 100%;
      & input.ant-input {
        height: 25px;
      }
      & .ant-input-group-addon button {
        width: fit-content;
        height: 38px;
        border-radius: 0px 7px 7px 0px;
        & svg {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
}

.br-navbar__tracking-search-icon {
  margin-right: 16px;
  cursor: pointer;

  svg {
    width: 20px;
    height: 20px;

    > * {
      stroke: @gray-900;
    }
  }
}

.br-navbar.tracking-page {
  .br-navbar__right-section-mobile {
    .br-navbar__tracking-mobile {
      display: flex;

      .br-navbar__right-section__locale-link {
        display: block;
        color: @text-gray;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .br-container {
    .br-navbar {
      padding: 20px;
    }

    .br-navbar__middle-section {
      display: none;
    }
    .br-navbar__menu-cotainer {
      .br-navbar__middle-section {
        display: none;
      }
      .br-navbar__right-section {
        display: none;
      }
    }

    .br-navbar__menu-cotainer.br-navbar__menu-cotainer-open {
      height: 100vh;
      transform: translateX(0);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 80px 0 20px;
      padding: 0 30px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transition: transform 0.4s ease-in-out;
      z-index: 100;
      background-color: @white;
      .br-navbar__middle-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-left: 0;
        .br-navbar__link {
          margin-right: 0;
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 23px 0;
          border-bottom: 1px dashed @gray-500;
        }
        .br-navbar__link:nth-child(4) {
          border-bottom: 1px solid @gray-500;
        }
      }

      .br-navbar__right-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 222px;
        .br-navbar__link-track-shipment {
          display: none;
        }
        .br-navbar__right-section__locale-dropdown {
          display: none;
        }
        .br-navbar__right-section__locale-link-container {
          width: 100%;
        }
        .br-navbar__right-section__locale-link {
          width: 100%;
          display: flex;
          cursor: pointer;
          padding: 23px 0;
          border-bottom: 1px dashed @gray-500;
        }
        .br-navbar__link-sign-in {
          margin: 24px auto;
          width: min(500px, 100%);
          border: 1px solid @gray-600;
          padding: 13px 32px;
          border-radius: 50px;
          display: flex;
          justify-content: center;
        }
        .br-navbar__link-sign-up {
          width: min(500px, 100%);
          display: flex;
          justify-content: center;
        }
        .br-navbar__right-section__registeration-container {
          width: 100%;
          flex-direction: column;
          align-items: center;
        }
      }
    }

    .br-navbar__hamburger {
      width: 22px;
      height: 22px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      cursor: pointer;
      > div {
        width: 22px;
        height: 3px;
        background: @gray-600;
        transition: transform 0.4s linear, opacity 0.4s linear;
        position: relative;
        transform-origin: 0px;
      }
    }

    .br-navbar__right-section-mobile {
      display: flex;
    }
  }
}

.br-navbar-announcments.br-navbar {
  z-index: 3;
  display: flex;
  justify-content: center;
  height: 39px;
  background-color: #4396a3;
  color: @white;
}

.br-nav-bar-with-announcements.br-navbar {
  top: 39px;
  z-index: 2;
}
