@import 'styles/variables.less';

.br-modal__title {
  .font({.display-sm()});
}

.br-modal__subtitle {
  color: @text-gray;
  
  .font({.body()});
}
