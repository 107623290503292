@import 'styles/variables';

.br-international-phone {
  &.ant-form-item {
    min-height: unset;
  }

  .ant-select.ant-select-single.ant-select-show-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    .br-international-phone__country-flag {
      width: 21px;
      margin-right: 10px;
      border-radius: 2px;
      height: min-content;
    }
    .ant-select-selector {
      border-radius: 0;
      border-radius: 4px 0 0 4px;
      .br-international-phone__country-name,
      .br-international-phone__country-code-number {
        display: none;
      }
    }
    .ant-select-dropdown {
      .ant-select-item-option-content {
        display: flex;
        align-items: center;
        .br-international-phone__country-code-number {
          margin-left: 8px;
          color: @text-gray;
        }
        .br-international-phone__country-code-name {
          display: none;
        }
      }

      .ant-select-item {
        max-height: 36px;
        margin: 8px;
      }

      .ant-select-item.ant-select-item-option-active:not(.ant-select-item-option-selected-disabled) {
        background-color: transparent;
      }

      .ant-select-item-option-selected:not(.ant-select-item-option-disabled),
      .ant-select-item-option-selected.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: @bg-selected;
        margin: 0 8px;
        border-radius: 4px;
      }

      .ant-select-item-option:not(.ant-select-item-option-disabled):not(.ant-select-item-option-selected):hover {
        background-color: @bg-pressed;
        border-radius: 4px;
      }

      .ant-select-item-option-selected:not(.ant-select-item-option-disabled)::before {
        content: '';
        display: block;
        height: 100%;
        width: 4px;
        position: absolute;
        left: -8px;
        top: 0;
        background-color: @teal-500;
        border-radius: 0px 4px 4px 0px;
      }
    }
  }

  .br-forms-new-input {
    min-height: 0;
    width: 100%;
    .ant-row.ant-form-item,
    > .ant-form-item {
      min-height: 0;
    }
    .ant-form-item-label {
      display: none;
    }
    .ant-input-affix-wrapper {
      border-radius: 0 4px 4px 0;
    }
    .ant-form-item-control {
      position: inherit;
      .ant-form-item-explain {
        margin-bottom: 0;
      }
      .ant-form-item-explain-error {
        position: absolute;
        left: 0;
      }
    }
  }
}

.br-international-phone {
  .ant-select:not(.ant-pagination-options-size-changer) .ant-select-arrow {
    margin-top: -8px;
  }

  .ant-select-selector .ant-select-selection-item {
    align-items: center;
  }
}

html[dir='rtl'] .br-international-phone {
  .ant-input-group.ant-input-group-compact {
    flex-direction: row-reverse;
  }
  .ant-select-selector .ant-select-selection-item {
    flex-direction: row-reverse;
    padding-left: 18px;
    padding-right: 0;
    .br-international-phone__country-flag {
      margin-right: 0;
      margin-left: 10px;
    }
  }
  .ant-select-show-arrow .ant-select-arrow {
    right: 73px;
  }
  .ant-select.ant-select-single.ant-select-show-arrow .ant-select-selector {
    border-radius: 0 4px 4px 0;
  }
  .br-forms-new-input .ant-input-affix-wrapper {
    border-radius: 4px 0 0 4px;
  }
  .ant-input-group.ant-input-group-compact > *:not(:last-child) {
    margin-left: -1px;
    border-left-width: 1px;
  }
  .ant-input-affix-wrapper {
    flex-direction: row-reverse;
    > span {
      margin-left: 4px;
      margin-right: 0;
    }
    > input {
      text-align: end;
    }
  }
}

.br-international-phone__without-select
  .br-forms-new-input
  .ant-input-affix-wrapper {
  border-radius: 4px;
}
