.br-preview-image-modal .ant-modal {
  margin-top: 100px;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  .ant-modal-header {
    padding: 10px 16px;
  }

  .ant-modal-body {
    display: flex;
    justify-content: center;
    padding: 0px;
  }

  .ant-modal-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    svg {
      width: 15px;
      height: 15px;
      cursor: pointer;
    }
  }
}
