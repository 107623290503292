@import 'styles/variables.less';
.r-hex {
  overflow: hidden;
  position: absolute;
  display: inline-block;
  margin: 3em 0;
  width: 750px;
  height: 648px;
  transform: rotate(-30deg) skewX(30deg);
  border-radius: 20px;
  top: 0;
  right: 0%;
  margin-right: -120px;
}
.r-hex *,
.r-hex *:before {
  display: block;
  overflow: hidden;
  width: inherit;
  height: inherit;
  border-radius: inherit;
}

.r-hex:first-child .r-hex-inner:before,
.r-hex-inner-2,
.r-hex-inner {
  transform: skewX(-30deg) rotate(60deg) skewX(30deg);
}

.r-hex-inner-2:before {
  margin-top: -50px;
  height: 850px;
  transform: skewX(-30deg) rotate(-90deg);
  background-repeat: no-repeat;
  content: '';
}

.br-hero-main {
  padding: 190px 110px;
  margin-top: 50px;
  position: relative;
  z-index: 10;
  background: @header-gradient;
}

.br__hero__content-svg {
  &.svg-main {
    right: 0;
    top: 85px;
  }

  &.svg-1 {
    right: 490px;
    top: 83px;
  }

  &.svg-2 {
    right: 0;
    bottom: 63px;
  }
}

.br-hero-header {
  max-width: 45%;
}

.br-about-header__subtitle {
  margin-top: 20px;
}

.br__hero__content-svg {
  position: absolute;
}

html[dir='rtl'] .br-hero__all-pages svg {
  transform: scaleX(-1);
}

html[dir='rtl'] .br-hero__home-page-vector {
  transform: scaleX(-1);
}

@media only screen and (max-width: 991px) {
  .br-hero-header {
    max-width: 100%;
    width: 100%;
  }
  .r-hex {
    position: relative;
    left: 14%;
    margin-top: 70px;
  }
  .br-hero-main {
    padding: 0px 30px;
    text-align: center;
    margin-top: 0;
    & .br-header__title {
      font-size: 45px;
    }
  }

  .br__hero__content-svg {
    &.svg-main {
      position: relative;
      & svg {
        width: calc(100% - 20px);
        margin-right: -80px;
        height: 100%;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .r-hex {
    width: 40em;
    height: 34em;
    // margin-right: -170px;
  }
  .r-hex-inner-2:before {
    height: 50em;
  }
}

@media only screen and (max-width: 576px) {
  .r-hex {
    width: 20em;
    height: 17.32em;
    margin-right: -170px;
  }
  .r-hex-inner-2:before {
    height: 30em;
  }
}
