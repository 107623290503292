@import 'styles/variables.less';

.br-egypt-ecommerce-state-report__container,
.br-egypt-ecommerce-state-report__text-section {
  display: flex;
}

.br-egypt-ecommerce-state-report__container {
  justify-content: space-between;
  margin-top: 53px;
  gap: 175px;
}

.br-egypt-ecommerce-state-report__text-section,
.br-egypt-ecommerce-state-report__form-section {
  flex: 1;
}

.br-egypt-ecommerce-state-report__text-section {
  flex-direction: column;

  .header-title {
    margin-bottom: 20px;
  }

  .br-report__card__image {
    box-shadow: @shadow-lg;
    width: 462px;
    height: fit-content;
    border-radius: 4px;
    margin-bottom: 28px;

    img {
      width: 100%;
      border-radius: 4px;
    }
  }
}

.br-egypt-ecommerce-state-report__form-section {
  //   width: 520px;
  //   max-width: 100%;
  background: @white;
  box-shadow: @shadow-md;
  padding: 32px 40px;
  border-radius: 12px;
  height: fit-content;

  .table-title {
    margin-bottom: 32px;
  }

  .br-form-item-label__title .br-form-optional-label {
    color: @gray-400;
    .font({.caption()});
  }
}

.text-gray {
  color: @gray-600;
}

.br-report-back-button.ant-btn:not(.ant-btn-circle) {
  border-radius: 100px;
  box-shadow: @shadow-sm;

  > span {
    height: 20px;
  }
}

html[dir='rtl'] .br-report-back-button.ant-btn:not(.ant-btn-circle) svg {
  transform: scaleX(-1);
}

.br-egypt-ecommerce-state-report__report-conetnt-section {
  margin-top: 40px;
  margin-bottom: 10px;
}

@media only screen and (max-width: @md) {
  .br-egypt-ecommerce-state-report__container {
    flex-direction: column;
    gap: 50px;

    .br-egypt-ecommerce-state-report__text-section {
      max-width: 100%;
    }
  }

  .br-egypt-ecommerce-state-report__container.br-egypt-ecommerce-state-report__container__success {
    flex-direction: column-reverse;
  }

  .br-egypt-ecommerce-state-report__text-section .br-report__card__image {
    width: 100%;
  }
}
