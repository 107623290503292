@import 'styles/variables';

.br-start-section__title {
  font-size: 48px;
  font-weight: 700;
  padding-left: 14px;
  line-height: 96px;
  max-width: 50%;
  > img {
    width: 50px;
    margin-left: 8px;
  }
}

html[dir='rtl'] .br-start-section__title {
  max-width: 72%;
}

.br-start-section__subtitle {
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  padding-left: 14px;
  line-height: 96px;
}

.br-start-section {
  height: fit-contents;
  position: relative;
  background: @red-25;
  margin-left: 33px;
  border-radius: 24px;
  overflow: hidden;
  .br-start-section-content {
    display: flex;
    flex-direction: column;
    padding: 60px 68px;

    .br-navbar__link-sign-up {
      width: fit-content;
    }
  }
}

.br-start-section__pros {
  font-size: 24px;
  margin: 30px 0px;
  width: 68%;
  max-width: 730px;
  list-style-type: square;
  li {
    line-height: 47px;
    font-weight: 400px;
  }
}

.br-start-section__manImage {
  width: 567px;
  height: 669px;
  z-index: 1;
  position: absolute;
  right: -30px;
  bottom: -4px;
}

.br-start-section__bosta-logo {
  position: absolute;
  right: 73px;
  bottom: 53px;
  width: 600px;
  height: 600px;
  z-index: 0;
  opacity: 0.5;
}

@media only screen and (max-width: @sm) {
  .br-start-section__title {
    font-size: 14px;
    font-family: @font-bold-1;
    line-height: 26px;
    > img {
      width: 20px;
    }
  }

  .br-start-section__subtitle {
    font-size: 14px;
    .caption-medium();
  }

  .br-start-section__pros {
    margin: 10px 0px;
    font-size: 9px;
    width: 60%;
    li {
      line-height: 16px;
    }
  }

  .br-start-section {
    margin-left: 11px;
    height: 308px;

    .br-start-section-content {
      padding: 20px 12px;

      .br-navbar__link-sign-up {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        padding: 0px;
        width: 77px;
      }
    }
  }

  .br-start-section__manImage {
    width: 190px;
    height: 225px;
    bottom: 30px;
  }

  .br-start-section__bosta-logo {
    width: 211px;
    height: 211px;
    right: -34px;
    bottom: 5px;
  }
}
