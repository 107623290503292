@import 'styles/variables.less';

.br-faq__questions-section {
  margin: 100px auto;

  pre {
    white-space: pre-wrap;
    overflow: hidden;
  }

  .ant-collapse {
    background: transparent;

    & > .ant-collapse-item {
      & > .ant-collapse-header {
        padding: 25px 5px;
        color: @text-gray;
      }
    }
    & > .ant-collapse-item-active > .ant-collapse-header {
      color: @deep-teal;
    }
  }
}

.br-faq__questions-section__not-found {
  text-align: center;
  color: @text-gray;
}

@media only screen and (max-width: 991px) {
  .br-faq__content {
    padding: 0 30px;
  }
}
