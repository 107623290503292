.br-mobile-hero {
  & .br__hero__content-svg {
    &.svg-main {
      right: 0;
      top: 0;
    }

    &.svg-1 {
      bottom: 83px;
      top: unset;
      right: 50%;
    }

    &.svg-2 {
      right: 550px;
      top: 90px;
      left: unset;
    }
  }

  .r-hex-inner-2:before {
    background: url(../../../../assets/images/Mobile-hexagon.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 45% 100%;
  }
}

.br-mobile-download-buttons {
  margin-top: 20px;
  & > .app-button {
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 0;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .br-mobile-hero {
    & .br__hero__content-svg {
      &.svg-main {
        right: -20%;
        & > img {
          max-width: 100%;
        }
      }

      &.svg-1 {
        left: 40px;
        bottom: -35px;
      }

      &.svg-2 {
        right: unset;
        top: unset;
        bottom: 160px;
        left: 0;

        & > svg {
          max-width: 160px;
        }
      }
    }
  }

  .br-mobile-download-buttons {
    flex-wrap: wrap;
    justify-content: center;

    & > .app-button {
      margin-bottom: 20px;
      margin-right: 0;
    }
  }
}
