@import 'styles/variables.less';

.br-capital__stats-section {
  display: flex;
  padding: 120px 55px;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  align-self: stretch;
  background: @teal-25;

  & .br-capital__stats-section-header {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.72px;
  }

  & .br-capital__stats-cards {
    display: flex;
    width: 1170px;
    align-items: flex-start;
    gap: 30px;
  }

  & .br-capital__stats-card {
    display: flex;
    padding: 48px 16px;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    flex: 1 0 0;
    border-radius: 20px 20px 0px 0px;
    border-bottom: 4px solid @red-600;
    background: @white;
    box-shadow: @shadow-lg;

    & p {
      margin-bottom: 0;
    }

    & p.stats-value {
      font-size: 38px;
      font-weight: 700;
      line-height: 130%;
      letter-spacing: -0.76px;
    }

    & p.stats-name {
      font-size: 16px;
      font-weight: 400;
      line-height: 130%;
    }
  }
}

.br-capital__why-erada-section {
  display: flex;
  padding: 96px 0px;
  flex-direction: column;
  align-items: center;
  gap: 64px;
  align-self: stretch;

  .br-capital__erada-stats-section-header {
    text-align: center;
    font-size: 36px;
    font-weight: 600;
    line-height: 54px;
    letter-spacing: -0.72px;
  }

  & .br-capital__erada-stats-cards {
    display: flex;
    width: 1170px;
    align-items: flex-start;
    gap: 30px;
  }

  & .br-capital__erada-stats-card {
    display: flex;
    padding: 48px 16px;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    flex: 1 0 0;
    border-radius: 20px 20px 0px 0px;
    border-bottom: 4px solid @teal-500;
    background: @white;
    box-shadow: @shadow-lg;

    & p {
      margin-bottom: 0;
    }

    & p.stats-value {
      font-size: 38px;
      font-weight: 700;
      line-height: 130%;
      letter-spacing: -0.76px;
    }

    & p.stats-name {
      font-size: 16px;
      font-weight: 400;
      line-height: 130%;
    }
  }
}

.br-capital__video-iframe {
  margin: auto;
  width: 820px;
  height: 515px;
  border-radius: 25px;
}

.br-capital__faqs-section {
  margin: 20px auto;
  width: 700px;
  padding: 60px 48px;

  .ant-collapse.ant-collapse-item,
  .ant-collapse-ghost {
    border-bottom: 1px solid @gray-200;
    margin-bottom: 18px;
  }

  > h3 {
    font-size: 38px;
    text-align: center;
    margin: 40px auto;
  }

  .br-capital__faqs-title {
    font-size: 18px;
    font-weight: 600;
  }

  p.br-capital__faqs-subtitle {
    font-size: 16px;
    font-weight: 200;
  }
  span.br-capital__span-highlight {
    font-weight: 600;
  }
}

@media only screen and (max-width: @sm) {
  .br-capital__faqs-section {
    margin: 20px auto;
    width: 100%;
  }

  .br-capital__video-iframe {
    padding: 10px;
    width: 100%;
    height: 515px;
  }

  .br-capital__stats-section {
    padding: 96px 16px;

    .br-capital__stats-cards {
      flex-direction: column;
      width: 100%;

      & .br-capital__stats-card {
        width: 100%;
      }
    }

    .br-capital__stats-section-header {
      font-size: 24px;
      font-weight: 600;
      line-height: 36px;
      letter-spacing: -0.48px;
    }
  }

  .br-capital__why-erada-section {
    padding: 96px 16px;

    .br-capital__erada-stats-cards {
      flex-direction: column;
      width: 100%;

      & .br-capital__erada-stats-card {
        width: 100%;
      }
    }
  }
}
