@import 'styles/variables.less';

.br-pay-modal__amount-section,
.br-pay-modal__amount-section span {
  display: flex;
  align-items: center;
}

.br-pay-modal__wrapper.ant-modal-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999999;
}

.br-pay-modal__wrapper .ant-modal.br-pay-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-modal-close {
    display: block;
  }

  .ant-modal-content {
    min-width: 436px;
    max-height: calc(100vh-160px);
  }

  .ant-btn-primary {
    background-color: @teal-500;
    &:hover,
    &:focus {
      border-color: @teal-600;
      background-color: @teal-600;
      border-color: @teal-600;
      span {
        color: @white;
      }
    }
  }

  .ant-modal-title {
    .font({.display-xs()});
    .body {
      color: @text-gray;
    }
  }

  .ant-modal-body {
    padding: 0;
  }

  .br-pay-modal__amount-section {
    padding: 16px;
  }

  .ant-radio-group,
  .ant-radio-group > *,
  .br-pay-modal-select-method-radio {
    width: 100%;
  }

  .br-pay-modal-select-method-radio {
    min-height: 44px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid @gray-200;
    padding: 12px 8px;

    &.ant-radio-wrapper-checked {
      background-color: @teal-200;
      border: 1px solid @teal-200;
    }
  }

  .br-pay-modal-select-method-radio > span:not(.ant-radio) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .br-coming-soon-tag {
    color: @text-gray;
    background-color: @gray-100;
    border-radius: 8px;
    padding: 4px 8px;
  }

  .br-tracking__payment-modal-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    iframe {
      height: 100%;
      min-height: 50vh;
    }

    .br-tracking__payment-modal-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      padding: 32px 0;

      p {
        margin-bottom: 0;
      }

      > svg {
        width: 40px;
        height: 40px;

        path {
          fill: transparent;
          stroke: @icon-teal;
        }
      }
    }

    .br-tracking__payment-modal-iframe {
      height: 100%;
      width: 100%;
    }
  }
}

.br-pay-modal__amount-section {
  justify-content: space-between;
  height: 56px;
  border-bottom: 1px solid @gray-200;
  .display-xs {
    color: @icon-teal;
  }
  svg {
    margin-right: 8px;
    path {
      fill: @icon-teal;
    }
  }
}

.br-pay-modal__methods-section {
  padding: 16px;
  > .body-medium {
    margin-bottom: 12px;
  }

  .br-payment__cards-icons-option {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.br-online-payment__card-option-subtitle {
  color: @text-gray;
}

@media only screen and(max-width:@sm) {
  .ant-modal-centered .ant-modal.br-pay-modal {
    display: flex;
    .ant-modal-content {
      min-width: unset;
    }
  }
}
