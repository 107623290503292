.br-integrations__smart-apis {
  display: flex;
  padding-right: 110px;
  margin-top: 141px;
}

.br-integrations__smart-apis__image {
  flex: 55%;
  display: flex;
  justify-content: center;
}

.br-integrations__smart-apis__content {
  padding-top: 50px;
  flex: 45%;
}

@media only screen and (max-width: 991px) {
  .br-integrations__smart-apis {
    padding: 0 30px;
    flex-direction: column-reverse;
    text-align: center;
  }

  .br-integrations__smart-apis__image > img {
    max-width: 350px;
  }
}
