@import 'styles/variables.less';

.br-fulfillment__features-section {
  background-color: @teal-50;
  display: flex;
  flex-direction: column;
  padding: 96px 89px;
  align-items: center;
  justify-content: center;
  position: relative;

  p {
    margin: 0;
  }

  .br-fulfillment__features-cloudUp {
    position: absolute;
    left: 135px;
    top: 52px;
  }

  .br-fulfillment__features-title {
    margin-bottom: 48px;
    text-align: center;
    .display-xl();

    span {
      color: @red-600;
    }
  }

  .br-fulfillment__features-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .br-fulfillment__features-grid {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 1300px;
      flex-shrink: 1;
      gap: 32px;

      .br-fulfillment__features-card {
        position: relative;
        display: flex;
        max-width: 410px;
        width: 100%;
        height: 100%;
        flex-direction: column;
        padding: 24px;
        align-items: center;
        justify-content: center;

        &::before {
          content: '';
          position: absolute;
          right: -16px;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: 150px;
          background-color: @gray-300;
        }

        &:nth-child(3n)::before {
          content: none;
        }

        &:last-child::before {
          content: none;
        }

        .br-fulfillment__features-card-icon {
          width: 48px;
          height: 48px;
        }

        .br-fulfillment__features-card-title {
          margin-top: 24px;

          text-align: center;
          .display-md();
        }

        .br-fulfillment__features-card-description {
          margin-top: 16px;
          padding: 0 22px;
          text-align: center;
          color: @gray-500;
          .display-xs();
        }
      }
    }
  }

  .br-fulfillment__features-button {
    padding: 12px 24px;
    margin-top: 40px;
    border-radius: 8px;

    .button-lg();
  }

  .br-fulfillment__features-cloudDown {
    position: absolute;
    right: 92.166px;
    bottom: 156.877px;
  }
}

html[dir='rtl'] .br-fulfillment__features-cloudUp {
  left: auto;
  right: 125px;
}
html[dir='rtl'] .br-fulfillment__features-cloudDown {
  right: auto;
  left: 92.166px;
}

@media only screen and (max-width: @lg) {
  .br-fulfillment__features-section {
    .br-fulfillment__features-content {
      .br-fulfillment__features-grid {
        max-width: 1280;
        width: 100%;
        .br-fulfillment__features-card {
          &:nth-child(2n)::before {
            content: none;
          }

          &:nth-child(2n + 1)::before {
            content: '';
          }

          &:last-child::before {
            content: none;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: @md) {
  .br-fulfillment__features-section {
    padding: 40px 0;

    .br-fulfillment__features-cloudUp {
      left: -67px;
      top: 116px;
    }

    .br-fulfillment__features-cloudDown {
      right: -78.243px;
      bottom: 378.454px;
    }

    .br-fulfillment__features-title {
      padding: 0 72px;
      margin-bottom: 16px;
      .display-xs();
    }

    .br-fulfillment__features-content {
      .br-fulfillment__features-grid {
        flex-direction: column;
        gap: 8px;

        .br-fulfillment__features-card {
          padding: 16px;

          &::before {
            content: none;
          }

          &:nth-child(2n + 1)::before {
            content: none;
          }

          .br-fulfillment__features-card-title {
            margin-top: 16px;
            text-align: center;
            .heading();
          }

          .br-fulfillment__features-card-description {
            margin-top: 8px;
            .body();
          }
        }
      }
    }
    button {
      margin-top: 24px;
      .button-lg();
    }
  }
  html[dir='rtl'] .br-fulfillment__features-cloudUp {
    right: -67px;
    top: 116px;
  }
  html[dir='rtl'] .br-fulfillment__features-cloudDown {
    left: -78.243px;
    bottom: 378.454px;
  }
}
