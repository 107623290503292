@import 'styles/variables.less';
@import 'styles/fonts-variables.less';

.gradient-border() {
  border: 0.5px solid transparent;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(230.55deg, @red-600 0%, @teal-400 100%) border-box;
}

.br-contact-sales-hero {
  position: relative;
  border-top-left-radius: 250px;
  overflow: hidden;
  background: linear-gradient(
    153deg,
    #dcf4f6 16.71%,
    rgba(255, 251, 251, 0) 83.29%
  );
  height: 971px;
}

.br-contact-sales-hero__manImage {
  position: absolute;
  z-index: 1;
  top: 114px;
  width: 840px;
  height: 663px;
}

html[dir='ltr'] .br-contact-sales-hero__manImage,
.br-contact-sales-hero__bosta-logo,
.br-contact-sales-hero__vector {
  transform: scaleX(-1);
}

.br-contact-sales-hero__bosta-logo {
  position: absolute;
  left: 268px;
  bottom: 146px;
  width: 594px;
  height: 594px;
  opacity: 0.4;
}

.br-contact-sales-hero__vector {
  position: absolute;
  top: 0;
}

.br-contact-sales-hero__content_container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 54px 172px 0px 0px;

  > span {
    width: 541px;
    margin-bottom: 45px;
    text-align: center;
    .display-xl();
  }

  .br-contact-sales-hero__form {
    width: 565px;
    height: 531px;
    border-radius: 30px;
    .gradient-border();
    background: #fff;
    box-shadow: 6px 6px 25px 1px rgba(0, 0, 0, 0.07);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    z-index: 1;

    .br-forms-new-input {
      width: 462px;
    }
  }

  .br-international-phone
    .br-forms-new-input
    .ant-form-item-control
    .ant-form-item-explain-error {
    margin-bottom: 20px;
    height: 25px;
  }

  .br-international-phone
    .ant-select.ant-select-single.ant-select-show-arrow
    .ant-select-selector {
    .gradient-border();
    height: 54px;
  }

  .ant-form {
    .label {
      font-size: 18px;
    }

    .ant-form-item-label {
      height: 28px;
      label {
        font-size: 18px;
      }
    }

    .ant-input-affix-wrapper,
    .ant-select-selector {
      height: 54px;
    }

    .br-forms-new-input .ant-form-item-explain {
      height: 20px;
    }

    .ant-input-affix-wrapper,
    .ant-select-selector {
      .gradient-border();
    }

    .br-international-phone {
      width: 462px;

      .ant-input-affix-wrapper {
        border-inline-end-color: @gray-200;
      }

      .br-forms-new-input {
        width: 200;
      }
    }

    .ant-btn {
      .font({.grid-title()});
      height: 59px;
      width: 248px;
      border-radius: 20px;
      padding: 8px 0px 11px 0px;
      margin-top: 32px;
      border: 1px solid transparent;
      background: linear-gradient(@teal-500, @teal-500) padding-box,
        linear-gradient(150deg, #e30613 25.79%, #1dbdcd 74.43%) border-box;
    }
  }
}

@media only screen and (max-width: @md) {
  .br-contact-sales-hero {
    border-top-left-radius: 100px;
    height: 402px;
  }

  html[dir='rtl'] {
    .br-contact-sales-hero .br-international-phone {
      .ant-select.ant-select-single.ant-select-show-arrow .ant-select-selector {
        border-radius: 0 30px 30px 0;
      }
      .br-forms-new-input .ant-input-affix-wrapper {
        border-radius: 30px 0 0 30px;
      }
      .ant-select-arrow {
        left: 2px;
      }
    }
  }

  .br-contact-sales-hero .br-international-phone {
    .ant-select.ant-select-single.ant-select-show-arrow .ant-select-selector {
      border-radius: 30px 0 0 30px;
    }
    .br-forms-new-input .ant-input-affix-wrapper {
      border-radius: 0 30px 30px 0;
    }
  }

  .br-contact-sales-hero__manImage {
    width: 65%;
    height: 190px;
    top: 60px;
    left: -8%;
  }

  .br-contact-sales-hero__vector {
    width: 400px;
    height: 200px;
    left: 36px;
  }

  .br-contact-sales-hero__bosta-logo {
    position: absolute;
    left: 68px;
    top: 30px;
    width: 197px;
    height: 197px;
    opacity: 0.4;
  }

  .br-contact-sales-hero__content_container {
    padding: 20px 16px 0px 0px;

    > span {
      width: 180px;
      margin-bottom: 20px;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
    }

    .ant-form {
      .ant-form-item {
        min-height: 50px;
      }
      .ant-form-item-control {
        :nth-child(2) {
          height: 15px;
        }
      }

      .ant-input-affix-wrapper > {
        .ant-input {
          font-family: @English-body !important;
          font-size: 10px;
          &::placeholder {
            font-size: 10px;
          }
        }
        .ant-input-prefix {
          font-size: 10px;
        }
      }

      .ant-form-item-control-input-content {
        min-height: 24px;
      }

      .ant-form-item-control-input {
        min-height: 25px;
      }

      .label {
        font-size: 10px;
      }

      .ant-input-affix-wrapper,
      .ant-select-selector {
        border-radius: 30px;
      }

      .ant-form-item-label {
        height: 24px;
        label {
          font-size: 8px;
        }
      }

      .ant-form-item-explain-error {
        font-size: 9px;
        height: 15px;
        line-height: 8px;
      }

      .ant-input-affix-wrapper,
      .ant-select-selector {
        height: 24px;
        min-height: 24px;
      }

      .ant-btn {
        .font({.grid-title()});
        font-size: 10px;
        height: 23px;
        width: 84px;
        margin-top: 16px;
      }
    }

    .br-contact-sales-hero__form {
      width: 197px;
      min-height: 240px;
      height: fit-content;
      padding: 12px 0;
      border-radius: 20px;
      gap: 2px;

      .br-forms-new-input {
        width: 154px;

        .br-form-item-label {
          height: 15px;
        }
      }

      .ant-row.ant-form-item-row {
        flex-direction: row;
      }

      .br-international-phone {
        width: 154px;
        height: 58px;

        .ant-select.ant-select-single.ant-select-show-arrow
          .ant-select-selector {
          height: 20px;
        }

        .br-international-phone__country-code-name {
          display: none;
        }

        .ant-select-selector {
          height: 20px;
          width: 30px;
          padding: 0px 3px;
          .br-international-phone__country-flag {
            height: 10px !important;
            width: 10px !important;
          }
        }

        .br-forms-new-input {
          width: 124px;
        }
        .ant-select-arrow {
          left: 15px;
        }
      }
    }
  }
}
