@import 'styles/variables.less';

.br-capital__testimonials-section {
  display: flex;
  padding: 96px 0px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 64px;
  align-self: stretch;

  > div {
    .br-capital__testimonials-section-header {
      text-align: center;
      font-size: 36px;
      font-weight: 600;
      line-height: 54px;
      letter-spacing: -0.72px;
    }

    .br-capital__testimonials-section-subtitle {
      text-align: center;
      font-size: 21px;
      font-weight: 700;
      line-height: 32px;
      letter-spacing: -0.5px;
    }
  }

  & .br-capital__testimonials-carousel {
    display: flex;
    width: 100%;
    gap: 30px;
    overflow-x: scroll;
    text-align: start;
    padding: 0 16px;
  }

  @media only screen and (max-width: @sm) {
    > div {
      .br-capital__testimonials-section-header {
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        line-height: 54px;
        letter-spacing: -0.72px;
      }

      .br-capital__testimonials-section-subtitle {
        text-align: center;
        font-size: 19px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.5px;
      }
    }

    & .br-capital__testimonials-carousel {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 16px;
      padding: 16px;
      text-align: start;
    }
  }
}
