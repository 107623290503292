@import 'styles/variables.less';

.ant-layout.br-container {
  &:not(:has(.br-shipment-tracking)) {
    padding-top: 46px;
  }
  min-height: 100%;
  background: #ffffff;

  &:not(.br-fulfillment__landing-page) {
    padding-top: 0;
  }
}

@media only screen and (max-width: @sm) {
  .ant-layout.br-container {
    padding-top: 0;
  }
}
