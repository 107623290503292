@import 'styles/variables.less';

.br-pricing-calculator__delivery-types__container {
  margin-bottom: 24px;
}

.br-pricing-calculator__delivery-types-box-container {
  display: flex;
  gap: 8px;
  & > div {
    background: white;
    border: 1px solid @gray-200;
    border-radius: 4px;
    padding: 8px 12px;
    transition: all 0.3s;
    cursor: pointer;
    & span {
      white-space: nowrap;
    }
  }
  & > div.selected_type {
    background: @teal-50;
    border: 1px solid @teal-500;
  }
}

.br-pricing-calculator__delivery-type-info-container {
  margin-top: 12px;
  background: @gray-50;
  border-radius: 8px;
  padding: 12px 16px;
  display: flex;
  flex-direction: column;
  & span.caption {
    color: @gray-500;
  }
}

.br-pricing-calculator_delivery-types__mobile-select__container{
  display: flex;
  flex-direction: column;
  gap: 4px;

}