@import 'styles/variables.less';

.br-solutions-testimonials-section {
  margin: 150px 0;
}

.br-testimonial-carousel-container {
  & h3 {
    margin-bottom: 50px;
  }
}

.br-testimonial-carousel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 110px;
  position: relative;
  & .ant-carousel {
    width: 100vw;
    & .slick-list {
      height: 390px;
    }
  }
}

.br-testimonial-carousel-card-container {
  width: 100%;
  max-width: 266px;
  box-shadow: 0px 20px 20px @box-shadow;
  border-radius: 20px;
  background-color: white;
  padding: 23px;
  margin: 0 51px;
  height: 290px;
  max-height: 290px;
  & p {
    margin-bottom: 0;
    color: @gray-600;
  }
  & .br-testimonial-carousel-card-header {
    margin-bottom: 23px;
    & h4 {
      color: @gray-600;
      margin-bottom: 0;
    }
    & h4:first-child {
      margin-bottom: 2px;
    }
  }
}

.br-testimonial-carousel-container {
  & .ant-carousel {
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 80px;
      height: 70%;
      top: 15%;
      background: linear-gradient(
        270deg,
        #ffffff 10.68%,
        rgba(255, 255, 255, 0) 48.05%
      );
      z-index: 100;
    }

    &::before {
      left: 0;
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }

    &::after {
      right: 0;
    }
  }
}

@media only screen and (max-width: 991px) {
  .br-solutions-testimonials-section {
    padding: 0;
    margin: 0;
  }

  .br-testimonial-carousel-container {
    & > h3 {
      text-align: center;
      width: 70%;
    }
  }

  .slick-dots.slick-dots-bottom {
    margin-top: 40px;
    padding-top: 40px;
  }
}
