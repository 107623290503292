@import 'styles/variables.less';

.br-sme-hero {
  & .svg-main {
    right: 0;
    top: 0;
  }

  & .svg-1 {
    bottom: -10px;
    right: 50%;
    top: unset;
  }

  & .svg-2 {
    left: unset;
    right: 440px;
    top: 47px;
  }
}

.br-sme-experience-container {
  margin: 100px 0;
  display: flex;
  padding: 0 111px;
  align-items: center;
  justify-content: space-between;
  & img {
    height: 100%;
  }
}

.br-sme-experience-content {
  width: 100%;
  max-width: 583px;
  & h3 {
    color: black;
  }
}

.br-sme-experience-features-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
  gap: 75px 50px;
}

.br-sme-experience-feature {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 266px;
  & h5 {
    margin-bottom: 22px;
    margin-top: 12px;
    color: @gray-600;
  }
  & .br-sme-cash-title {
    margin-top: 27px;
  }
  & p {
    margin-bottom: 0;
    color: @gray-600;
  }
  & span {
    color: @teal-500;
    cursor: pointer;
    margin-top: 12px;
  }
}

.br-sme-bosta-box-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 158px;
}

.br-sme-bosta-box-content {
  margin-left: 100px;
  width: 100%;
  max-width: 583px;
  & h3 {
    margin-bottom: 50px;
  }

  & p {
    margin-bottom: 50px;
    color: @gray-600;
  }
}

.br-sme-mobile-content {
  width: 100%;
  max-width: 583px;
  margin-right: 163px;
  & h3 {
    margin-bottom: 50px;
  }

  & p {
    margin-bottom: 50px;
    color: @gray-600;
  }
  & svg {
    margin-right: 24px;
    cursor: pointer;
  }
}

.br-sme-trusted-by-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 102px;
  & h1 {
    margin-bottom: 60px;
  }
}

.br-shipment-tracking__ads-container.br-sme-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url(/src/assets/images/sme-main-vector.svg);
  background-repeat: no-repeat;
  background-position: top;
}

.br-sme .r-hex-inner-2:before {
  background: url(../../assets/images/SMES1.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover;
}

@media only screen and (max-width: 991px) {
  .br-sme-hero {
    & .svg-main {
      margin-top: 40px;
      right: -20%;

      & > img {
        max-width: 100%;
      }
    }

    & .svg-1 {
      left: 0;

      & > svg {
        max-width: 160px;
      }
    }

    & .svg-2 {
      top: unset;
      right: 442px;
      bottom: 25%;
    }
  }

  .br-sme-experience-container {
    padding: 0 30px;
    text-align: center;
    flex-direction: column;

    & > img {
      max-width: 250px;
      margin-top: 40px;
    }
  }

  .br-sme-experience-feature {
    align-items: center;
    max-width: 100%;
  }

  .br-sme-bosta-box-container {
    flex-direction: column;
    padding: 0 30px;
    margin: 0;
    text-align: center;

    & > svg {
      max-width: 300px;
    }

    &.right {
      flex-direction: column-reverse;
    }

    & .br-sme-bosta-box-content {
      max-width: 100%;
      margin: 0;
    }

    & .br-sme-mobile-content {
      max-width: 100%;
      margin: 0;
    }
  }

  .br-shipment-tracking__ads-container.br-sme-page {
    margin: 0;
    padding: 0;
  }
}
