@import 'styles/variables.less';
.br-CarouselBar_section {
  display: flex;
  width: 100%;
  align-items: center;
  align-self: stretch;

  .br-CarouselBar_logos {
    display: flex;
    padding: 60px 48px;

    align-items: center;
    border-top: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    width: 100vw;
    overflow: hidden;

    div {
      display: inline-flex;
      gap: 96px;
      animation: scroll 30s linear infinite;

      &::after {
        content: '';
        display: flex;
      }

      img {
        min-width: 75px;
        max-width: 100px;
        object-fit: contain;
        flex-shrink: 0;
      }
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media only screen and (max-width: @sm) {
  .br-CarouselBar_section {
    .br-CarouselBar_logos {
      padding: 40px 16px;
    }
  }
}
