@import 'styles/variables.less';

.br-phone-number-modal__title {
  .font({
    .display-xs()
  });
}

.br-phone-number-modal__subtitle {
  color: @text-gray;
}

.br-phone-number-field.ant-form-item .ant-row.ant-form-item-row {
  .ant-input-group.ant-input-group-compact {
    [dir] & {
      direction: ltr;
    }

    > *:not(:last-child) {
      margin-inline-end: -1px;
    }
  }

  .br-phone-number-field__input {
    .ant-form-item-control-input-content > .ant-input-affix-wrapper {
      height: 40px;

      text-align: start;
      border-start-start-radius: 0;
      border-end-start-radius: 0;

      .ant-input-prefix {
        direction: ltr;
      }
    }
  }

  .br-countries-dropdown.ant-select.ant-select-single.ant-select-show-arrow {
    height: 40px;

    .br-countries-dropdown__country-flag {
      width: 21px;
      margin-inline-end: 10px;
    }

    > .ant-select-arrow {
      position: absolute;
      inset-inline-end: 11px;

      [dir] & {
        left: unset;
      }
    }

    > .ant-select-selector {
      height: 100%;

      [dir] & {
        direction: ltr;

        border-radius: unset;
        border-top-left-radius: unset;
        border-bottom-left-radius: unset;

        border-start-start-radius: 4px;
        border-end-start-radius: 4px;

        .ant-select-selection-item {
          padding: 0;
          padding-inline-end: 18px;
        }
      }

      .br-countries-dropdown__country-name,
      .br-countries-dropdown__country-code-number {
        display: none;
      }
    }

    .br-countries-dropdown__option {
      .ant-select-item-option-content {
        display: flex;
        align-items: center;

        .br-countries-dropdown__country-code-name {
          display: none;
        }

        .br-countries-dropdown__country-code-number,
        .br-countries-dropdown__country-flag {
          margin: 0;
          color: @text-gray;
        }

        .br-countries-dropdown__country-name {
          margin-inline: 10px;
        }

        [dir='rtl'] & {
          flex-direction: row-reverse;
        }
      }
    }
  }
}
