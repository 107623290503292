@import 'styles/variables.less';

.br-success-modal {
  .ant-modal {
    width: 372px;

    .ant-modal-content {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }

  .br-button-component {
    width: 100%;
  }
}

.br-success-modal__icon {
  margin-bottom: 20px;

  svg {
    width: 56px;
    height: 56px;
  }
}

.br-success-modal__title {
  .font({.display-sm()});
  margin-bottom: 8px;
}

.br-success-modal__subtitle {
  color: @text-gray;
  margin-bottom: 36px;
}
