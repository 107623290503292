@import 'styles/variables';

.br-deliveries__pos-modal-wrapper {
  padding: 16px 20px;
}

.br-deliveries__pos-requested-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
  width: 100%;
  max-width: 372px;

  & img {
    margin-bottom: 24px;
  }

  & p {
    line-height: 20px;
    margin-bottom: 10px;
  }

  & .br-deliveries__pos-modal-subtitle {
    color: @text-gray;
  }

  & .br-button-component {
    width: 100%;
    height: 36px;
    border-radius: 4px;
  }
}
