@import 'styles/variables.less';

.br-capital__signup-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 64px 16px;

  & .br-capital__signup-text {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 100%;
    padding: 64px 16px;
    gap: 24px;

    .br-capital__signup-header {
      font-size: 40px;
      font-weight: 600;
      line-height: 60px;
      letter-spacing: -0.8px;
      margin-bottom: 0;
    }

    .br-capital__signup-subtitle {
      color: @gray-500;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 0;
    }
  }

  & .br-capital__signup-form {
    margin-bottom: 96px;

    > form {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .ant-form-item {
        width: 448px;
        min-height: 60px;
        border-radius: 4px;
      }
    }
  }

  & .br-capital__form-submit-btn {
    border-radius: 4px;
    min-width: 448px;
    height: 48px;
  }

  @media only screen and (max-width: @sm) {
    .br-capital__signup-form {
      padding: 16px;
      width: 100%;

      & .br-capital__form-submit-btn {
        border-radius: 4px;
        min-width: 100%;
        height: 48px;
      }
    }
  }
}
