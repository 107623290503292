@import 'styles/variables.less';

.br-merged-integrations__header {
  height: 757px;
  padding: 200px 0 83px 110px;
  background: @header-gradient;
  position: relative;
}

.br-merged-integrations__header {
  .ant-tabs {
    .ant-tabs-nav-wrap .ant-tabs-nav-list {
      width: 98%;
    }
    .ant-tabs-nav {
      width: 100%;
      &:before {
        border-width: 2px;
      }
      .ant-tabs-nav-wrap .ant-tabs-ink-bar {
        height: 4px;
        background: @teal-500;
      }
    }

    .ant-tabs-tab {
      flex: 1;
      justify-content: center;

      .ant-tabs-tab-btn {
        color: @gray-400;
        svg path {
          fill: @gray-400;
        }
      }
      &.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: @black;
        svg path {
          fill: @black;
        }
      }
    }

    .ant-tabs-content-holder {
      color: @text-gray;
    }
  }

  .r-hex-inner-2:before {
    background: url(../../../../assets/images/Merged-Integration.png);
    background-repeat: no-repeat;
    background-size: 100em 764px;
  }
}

.br-merged-integrations__header .br__hero__content-svg.svg-2 {
  right: 700px;
}

.br-merged-integrations__header__text__tab {
  display: flex;
  align-items: center;
  gap: 10px;
}

.br__hero__content-svg {
  position: absolute;
}

.br-merged-integrations__header__main-image {
  top: 0;
  right: 0;
  svg {
    width: 896px;
    height: 907px;
  }
}

.br-merged-integrations__header__integrate-icon {
  top: 50px;
  right: 416px;
}

.br-merged-integrations__header__bag-icon {
  top: 600px;
  right: 758px;
  svg {
    width: 105px;
    height: 105px;
  }
}

@media only screen and (max-width: 991px) {
  .br-merged-integrations__header {
    padding: 0 30px;
    height: 100%;
  }

  .br-merged-integrations__header__text__tab {
    & > .tab-text {
      display: none;
    }
  }

  .br-merged-integrations__header .ant-tabs .ant-tabs-nav {
    max-width: 200px;
  }

  .br-merged-integrations__header .ant-tabs {
    align-items: center;
  }
}
